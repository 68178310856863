export const localeHr = {
  general: {
    _administration: 'Administracija',
    _username: 'Korisničko ime',
    _password: 'Lozinka',
    _date: 'Datum',
    _time: 'Vrijeme',
    _orderOverview: 'Pregled narudžbi',
    _reports: 'Izvješća',
    _kg: 'kg',
    _ton: 'tona',
    _callOrder: 'Naručivanje poziva',
    _serviceList: 'Popis poslova',
    _impersonationManager: 'Postavi klijenta/korisnika',
    _userAdmin: 'Upravljanje korisnicima',
    _routeAdmin: 'Rute',
    _backendError: 'Došlo je do pogreške prilikom zahtjeva',
    _accessError: 'Nemate pristup ovoj stranici',
    _vehicles: 'Vozila',
    _vehicleSettings: 'Administrativne postavke',
    _zoomIn: 'Uvećaj',
    _zoomOut: 'Umanji',
    _warning: 'Upozorenje!',
    _customerRegister: 'Pretraži registar kupaca',
    _commune: 'Općina',
    _logOut: 'Odjava',
    _selectLanguage: 'Odaberite jezik',
    _unitDesrciption: 'Vrsta jedinice',
    _fullDateFormat: 'dd.MM.yyyy',
    _fullDateWithTime: 'dd.MM.yyyy HH:mm:ss',
    _fullMomentFormat: 'DD.MM.YYYY',
    _fullMomentWithTime: 'DD.MM.YYYY HH:mm:ss',
    _noRecords: 'Nema dostupnih zapisa.',
    _dashboard: 'Dashboard',
    _decimalSeparator: ',',
    validation: {
      _isRequired: 'je obavezno',
      _stringMaxCharA: 'mora biti najviše',
      _stringMaxCharB: 'znakova',
      _emailNotValid: 'nije važeća e-mail adresa!',
      _lessOrEqual: 'mora biti manje ili jednako',
      _greaterOrEqual: 'mora biti veće ili jednako',
      _laterThan: 'mora biti kasnije od',
      _earlierThan: 'mora biti ranije od',
    },
  },
  login: {
    _usernameEmail: 'E-pošta',
    _usernameRequired: 'Korisničko ime je obavezno!',
    _passwordRequired: 'Lozinka je obavezna!',
    _usernamePasswordInvalid: 'Neispravno korisničko ime ili lozinka',
    _invalidEmail: 'Korisničko ime nije važeća e-mail adresa!',
    _submit: 'Prijava',
    _welcome: 'Dobrodošli u GPS Realtime WMS',
    _pressLogin: 'Pritisnite Prijava za pristup portalu',
    _ifAssistance: 'Ako trebate pomoć,',
    _pleaseContact: 'obratite se našoj podršci',
  },
  tooltip: {
    _deviceId: 'ID uređaja',
    _altitude: 'Visina',
    _speed: 'Brzina',
    _temperature: 'Temperatura',
    _orderId: 'Broj narudžbe',
    _address: 'Adresa',
    _routeName: 'Ruta',
  },
  orders: {
    _update: 'Ažurirano',
    _start: 'Start',
    _route: 'Ruta',
    _vehicleId: 'Vozilo',
    _orderId: 'Narudžba',
    _status: 'Status',
    _notStarted: 'Nije pokrenuto',
    _now: 'Sada',
    _clearFilter: 'Očisti filter',
    _fraction: 'Frakcija',
    _showFilters: 'Prikaži filtere',
    _chooseContractor: 'Odaberite prijevoznika',
    _chooseFraction: 'Odaberite frakciju',
    _chooseStatus: 'Odaberi status',
    _expires: 'Ističe',
    _date: 'Datum',
    _ordinaryDate: 'Uobičajeni datum',
    _earlierDateValidation:
      'Datum isteka ne može biti raniji od datuma početka',
    _fractions: 'Frakcije',
    _selectFractions: 'Odaberite frakcije',
    _noFractions: 'Nema frakcija',
    _updateOrder: 'Ažuriraj narudžbu',
    _updateSuccess: 'Narudžba je ažurirana',
    _fractionsLoading: 'Frakcije se učitavaju',
    _notEditableWarning:
      'Kada je ruta započela, može se promijeniti samo datum isteka.',
    _contractor: 'Izvođač',
    _customerGroup: 'Grupa Kupaca',
    _newOrder: 'Nova Narudžba',
    _selectContractor: 'Odaberi Izvođača',
    _selectRoute: 'Odaberi Rutu',
    _selectFractions: 'Odaberi Frakciju/Tip Otpada',
    _selectCustomerGroup: 'Odaberi Grupu Kupaca',
    _selectFrequency: 'Odaberi Učestalost',
    _selectDays: 'Odaberi Dan(e)',
    _days: 'Dan(i)',
    _startDate: 'Datum Početka',
    _ordinaryDate: 'Unesi Redoviti Datum',
    _expireDate: 'Unesi Datum Isteka',
    _repetationEndDate: 'Datum Završetka Ponavljanja',
    _frequency: 'Učestalost',
    status: {
      _noDone: 'Nije dovršeno',
      _okDone: 'Ok, dovršeno',
      _downloaded: 'Preuzeto',
      _started: 'Pokrenuto',
      _backOrder: 'Nedovršena narudžba',
      _localBackOrder: 'Lokalna nedovršena narudžba',
      _partiallyStarted: 'Djelomično pokrenuto',
      _partiallyCompleted: 'Djelomično dovršeno',
      _rejected: 'Odbijeno',
      _planned: 'Planirano',
      _deleted: 'Izbrisano',
    },
  },
  orderDetails: {
    _address: 'Adresa',
    _unit: 'Jedinica',
    _executed: 'Izvršeno',
    _vehicleId: 'Vozilo',
    _driver: 'Vozač',
    _time: 'Vrijeme',
    _deviation: 'Odstupanje',
    _orderDoesNotExist: 'Narudžba ne postoji',
    _companyName: 'Kupac',
    _pictureTakenText: 'slike',
    _sequence: 'Sekvenca',
  },
  orderStatuses: {
    _started: 'Započeto',
    _done: 'OK, voženo',
    _partiallyDone: 'Djelomično dovršeno',
  },
  orderDetailsDiagram: {
    _executionStatusHeader: 'Izvršeno (Da / Ne)',
    _deviationsHeader: 'Odstupanja / Napomena',
    _noDeviations: 'Nema odstupanja / napomena',
  },
  routeStopDetails: {
    _routeStopDoesNotExist: 'Ruta ne postoji',
    _departureTime: 'Vrijeme odlaska',
    _arrivalTime: 'Vrijeme dolaska',
    _address: 'Adresa',
    _agreementId: 'Sporazum br.',
    _unit: 'Jedinica',
    _agreementLineId: 'Br. zahtjeva',
    _executed: 'Izvršeno',
    _externalOrderId: 'Br. narudžbe',
    _vehicleId: 'Vozilo',
    _driver: 'Vozač',
    _orderData: 'Podaci o narudžbi',
    _pictures: 'Slike',
    _deviation: 'Odstupanje / Napomena',
    _comment: 'Komentar',
  },
  routeAdminPage: {
    _selectRoute: 'Odaberite rutu(e)',
    _refresh: 'Osvježi',
    _withoutPosition: 'Bez pozicije',
    _sequence: 'Redoslijed',
    _description: 'Opis',
    _routeLineId: 'Zaustavno mjesto',
    _agreementId: 'ID ugovora',
    _name: 'Ime',
    _estate: 'Nekretnina',
    _unitName: 'Proizvod',
    _agreementLineId: 'ID stavke ugovora',
    _physicalAmount: 'Količina',
    _route: 'Ruta',
    _degLat: 'Širina',
    _degLong: 'Dužina',
    _invalidSequence: 'Nevažeći broj slijeda',
    _changeSequence: 'Promijeni redoslijed',
    _cancelButton: 'Odustani',
    _submitButton: 'Spremi',
    _validateButton: 'Potvrdi',
    _restoreButton: 'Poništi',
    _locationChangeTitle: 'Jeste li sigurni da želite spremiti?',
    _locationChangeContent: 'Vaše promjene će sada biti spremljene',
    _splitStoppoint: 'Podijeli zaustavnu točku',
    _splitModalPlaceholder:
      'Povucite kupca ovdje kako biste stvorili novu zaustavnu točku',
    _splitModalErrorMessage: 'Morate stvoriti najmanje 2 točke',
    _mergeModalTitle: 'Spoji zaustavne točke',
    _mergeModalContent: 'Odabrane zaustavne točke',
    _selectOnlyOne: 'Odaberite samo 1 točku',
    _selectAtLeastOne: 'Odaberite barem 1 točku',
    _selectAtLeastTwo: 'Odaberite najmanje 2 točke',
    _mergeStoppoints: 'Spoji mjesta za zaustavljanje',
    _sequenceEdit: 'Uredi redoslijed',
    _savePosition: 'Spremi poziciju',
    _changePosition: 'Promijeni poziciju',
    _changePositionTooltip: 'Promijeni poziciju odabrane točke',
    _agreementGid: 'GnrBnrFnrSnr',
    _placeId: 'ID mjesta',
    _address: 'Adresa',
    _customerName: 'Ime kupca',
    _propertyLinesWarning: 'Neke točke mogu biti prikazane izvan karte.',
    _propertyLinesCustom: 'Prikaži samo točke s koordinatama.',
    _propertyLinesAll: 'Prikaži sve točke.',
    _changesHeader: 'Sljedeći brojevi slijeda bit će pogođeni',
    _changedFrom: 'promijenjen iz',
    _changedTo: 'u',
    _notInNorwayErrorMessage: 'Pozicija je izvan Norveške',
    _alreadyMerged: 'Odabrani ugovori su već spojeni',
    _mergeModalTargetText: 'Odaberite prikaz/adresu točke',
    _mergeModalTargetDropdownText: 'Odaberite prikaz/adresu',
    _setPosition: 'Postavi poziciju',
    _setPositionTooltip: 'Postavi poziciju za odabranu točku',
    _noRecords: 'Nema dostupnih zapisa.',
    _isLoadingMessage: 'Zapisi se učitavaju.',
    _sequenceSuccessfullyChanged: 'Redoslijed je ažuriran',
    _remove: 'Ukloni',
    _selection: 'izbor',
    _hideWithoutAgreement: 'Sakriti bez dogovora',
    _chooseRoutes: 'Odaberite rute',
  },
  reports: {
    _exportTitle: 'Izvezi u Excel datoteku',
    _nothingToExportTitle: 'Nema podataka za izvoz',
    _showReport: 'Prikaži izvještaj',
    _fromDate: 'Od datuma',
    _toDate: 'Do datuma',
    _selectReport: 'Odaberite izvještaj',
    _reportEmpty: 'Nema dostupnih zapisa',
    _header: 'Moji izvještaji',
    _summary:
      'Ovdje smo sakupili sve vaše izvještaje. Izvještaji se mogu naručiti kod naših konzultanata. Kontaktirajte nas putem e-pošte {mailto}.',
    _export: 'Izvezi',
  },
  serviceLists: {
    _noOrders: 'Nema planiranih narudžbi.',
    _delete: 'Izbriši',
    _add: 'Dodaj',
    _deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu radnu listu: ',
  },
  routeOptimalizationWizard: {
    _title: 'Izradi liste vožnji',
    _uploadFileStep: 'Učitaj datoteku',
    _uploadFileButton: 'Odaberi datoteku',
    _loadInButton: 'Učitaj',
    _analyseStep: 'Analiziraj',
    _analyseButton: 'Pokreni analizu',
    _saveStep: 'Liste vožnji',
    _saveButton: 'Kreiraj liste vožnji',
    _downloadTemplateButton: 'Preuzmi predložak za unos',
    _importedRouteTabTitle: 'Osnova narudžbi',
    _worklistsTabTitle: 'Liste vožnji',
    _unassignedStopsTabTitle: 'Narudžbe nisu uključene',
    _noRoutesImported: 'Nema narudžbi, učitaj datoteku',
    _worklistEmpty: 'Lista vožnji je prazna',
    _worklistOptionText: 'Ruta vožnje',
    _addCustomer: 'Dodaj kupca',
    _duplicateRoutesWarning: 'Postoje duplikati narudžbi u osnovi narudžbi',
    _noFileSelectedTitle: 'Nijedna datoteka nije odabrana!',
    _noFileSelectedDescription:
      'Učitajte osnovnu datoteku narudžbi koristeći sljedeći predložak za unos:',
    _avaibleOrders: 'Dostupne narudžbe',
    _loadIn: 'Učitaj u',
    _loadOrders: 'Učitaj narudžbe',
    importManager: {
      _unknownParseError: 'Nepoznata greška',
      _fileLoadResultsModalTitle: 'Rezultati učitavanja datoteke',
      _fileLoadSuccess: 'Datoteka uspješno učitana!',
      _fileLoadModalCloseButton: 'Zatvori',
      _agreementNotFound: 'Kupac nije pronađen za broj kupca',
      _wrongFileFormat: 'Pogrešan format datoteke. Preuzmite predložak za unos',
      _fileEmpty: 'Datoteka ne sadrži podatke',
    },
    addCustomerModal: {
      _title: 'Dodaj kupca',
      _closeButton: 'Zatvori',
      _addButton: 'Dodaj',
      _dateLabel: 'Datum',
      _agreementLabel: 'Kupac',
      _descriptionLabel: 'Opis',
      _serialLabel: 'Serijski broj',
      _noOptionsMessage: 'Kupci nisu pronađeni',
      _loadingMessage: 'Kupci se učitavaju',
      _agreementSelector: 'Odaberite kupca',
      _agreementSelectorPlaceholder: 'Odaberite kupca...',
    },
    routeAnalysisModal: {
      _title: 'Analiziraj osnovu narudžbi',
      _closeButton: 'Zatvori',
      _cancelButton: 'Otkaži',
      _analyseButton: 'Analiziraj',
      _selectStartAndStop: 'Odaberite početnu i krajnju točku',
      _workTime: 'Efektivno radno vrijeme',
      _timePerStop: 'Vrijeme po stanici (minute)',
      _maximumCars: 'Maksimalan broj vozila u analizi',
      _startStopLabel: 'Početna i krajnja točka',
      _workHoursLabel: 'Sati',
      _workMinutesLabel: 'Minute',
      _timePerStopLabel: 'Vrijeme po stanici',
      _maximumCarsLabel: 'Maksimalan broj vozila',
      _noDepotOptionsMessage: 'Nema pronađenih skladišta',
      _depotsLoadingMessage: 'Skladišta se učitavaju',
      _depotSelectorPlaceholder: 'Odaberite skladište...',
      _analysationError: 'Greška pri analizi osnove narudžbi',
    },
    routesGrid: {
      _date: 'Datum',
      _gln: 'Broj ugovora',
      _customerName: 'Naziv kupca',
      _description: 'Opis',
      _serial: 'Serijski broj',
      _orderNumber: 'Broj narudžbe',
    },
    worklistResults: {
      _sequence: 'Redoslijed',
      _arrivalTime: 'Vrijeme dolaska',
      _gln: 'Br. kupca',
      _customerName: 'Naziv kupca',
      _description: 'Opis',
      _serial: 'Serijski broj',
      _unassignedWarning: 'Narudžbe nisu uključene!',
      worklistSummary: {
        _totalTime: 'Ukupno vrijeme',
      },
      moveWorklistItemModal: {
        _title: 'Premjesti u',
        _targetListLabel: 'Lista vožnji',
        _closeButton: 'Zatvori',
        _moveButton: 'Premjesti u',
      },
    },
    createWorklistsModal: {
      _title: 'Izradi liste vožnji',
      _closeButton: 'Zatvori',
      _saveButton: 'Kreiraj',
      _itemCount: 'Broj stavki',
      _listName: 'Naziv liste',
      _saveError: 'Greška prilikom kreiranja liste vožnji',
    },
  },
  dateRangePicker: {
    _thisWeek: 'Ovaj tjedan',
    _lastWeek: 'Prošli tjedan',
    _thisMonth: 'Ovaj mjesec',
  },
  serviceListsGrid: {
    _listName: 'Naziv liste',
    _vehicleId: 'Vozilo',
    _updated: 'Ažurirano',
    _started: 'Početak',
    _listDate: 'Datum',
  },
  serviceOrders: {
    _noOrders: 'Nema planiranih narudžbi.',
    _excelExportButton: 'Excel',
    _pdfExportButton: 'PDF',
    _deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu narudžbu: ',
  },
  serviceOrdersGrid: {
    _updated: 'Vrijeme',
    _address: 'Adresa',
    _executed: 'Izvršeno',
    _unit: 'Jedinica',
    _orderItemType: 'Vrsta',
    _deviation: 'Odstupanje',
    _externalOrderId: 'GLN',
    _sequence: 'Sekvenca',
    _agreementCompanyName: 'Kupac',
    _estimatedArrivalTime: 'Planirano',
  },
  serviceOrderDetails: {
    _serviceOrders: 'Radni nalozi',
  },
  serviceOrderDetailsGrid: {
    _agreementCompanyName: 'Kupac',
    _address: 'Adresa',
    _externalOrderId: 'GLN',
    _sequence: 'Sekvenca',
    _estimatedArrivalTime: 'Planirano',
    _description: 'Opis',
    _listDescription: 'Opis liste',
    _executed: 'Izvršeno',
    _vehicleId: 'Vozilo',
    _updated: 'Vrijeme izvršenja',
    _unitName: 'Jedinica',
    _orderItemType: 'Vrsta',
    _time: 'Vrijeme',
  },
  containerOrders: {
    _noOrders: 'Nema planiranih narudžbi.',
    _addVehicle: 'Dodaj vozilo',
    _cancel: 'Odustani',
    _save: 'Dodijeli vozilo',
    _addVehicleSuccess: 'Vozilo je dodano',
    _addVehicleFailure: 'Vozilo nije postavljeno',
    _dateRange: 'Raspon datuma',
    _routes: 'Rute',
    _selectRoutes: 'Odaberite rute',
    _vehicles: 'Vozila',
    _selectVehicles: 'Odaberite vozila',
  },
  containerOrdersGrid: {
    _orderId: 'ID narudžbe',
    _expires: 'Ističe',
    _date: 'Datum',
    _routeName: 'Ruta',
    _address: 'Adresa',
    _unitName: 'Jedinica',
    _orderTypeName: 'Vrsta',
    _fraction: 'Frakcija',
    _vehicleId: 'Vozilo',
    _route: 'Ruta narudžbe',
  },
  customerSearchGrid: {
    _id: 'Id',
    _system: 'Sustav',
    _municipality: 'Općina',
    _name: 'Naziv',
    _address: 'Adresa',
    _zipCode: 'Poštanski broj',
    _type: 'Tip profila',
    _unitDesrciption: 'Tip jedinice',
    _sendMessage: 'Pošalji izravnu poruku',
  },
  containerOrderDetails: {
    _unitName: 'Jedinica',
    DetailData_Name: 'Naziv',
    DetailData_Address1: 'Adresa 1',
    DetailData_Address2: 'Adresa 2',
    DetailData_PostalCode: 'Poštanski broj',
    DetailData_Other: 'Ostalo',
    DetailData_Message: 'Komentar',
    DetailData_OrderId: 'ID narudžbe',
    DetailData_Expires: 'Ističe',
    DetailData_LastDate: 'Posljednji datum',
    DetailData_LastVolume: 'Volumen',
    DetailData_MessageSeptic: 'Napomena',
    DetailData_Material: 'Vrsta materijala',
    DetailData_NumberOfChambers: 'Broj komora',
    DetailData_TubeLength: 'Duljina crijeva',
    DetailData_ExtOrderId: 'ID narudžbe',
    DetailData_ExtOrderNr: 'Vanjski OrderNr',
    DetailData_Route: 'Ruta',
    DetailData_RouteName: 'Naziv rute',
    DetailData_OrderDate: 'Datum narudžbe',
    DetailData_OrderType: 'Vrsta narudžbe',
    DetailData_OrderTypeText: 'Vrsta narudžbe',
    DetailData_PASystem: 'PASustav',
    DetailData_AgreementLines: 'Linije ugovora',
    DetailData_RoutelineId: 'ID linije rute',
    AgreementLineDetailData_PASystem: 'PASustav',
    AgreementLineDetailData_AgreementId: 'ID ugovora',
    AgreementLineDetailData_AgreementLineId: 'ID linije ugovora',
    AgreementLineDetailData_UnitId: 'ID jedinice',
    AgreementLineDetailData_Amount: 'Količina',
    AgreementLineDetailData_PhysicalAmount: 'Fizička količina',
    AgreementLineDetailData_Message: 'Lokacija',
    AgreementLineDetailData_ExtOrderId: 'ID narudžbe',
    AgreementLineDetailData_LastChanged: 'Posljednja promjena',
    AgreementLineDetailData_DegLat: 'Širina',
    AgreementLineDetailData_DegLon: 'Duljina',
    AgreementLineDetailData_ContainerId: 'ID kontejnera',
    AgreementLineDetailData_Fraction: 'Frakcija',
    AgreementDetailData_PASystem: 'PASustav',
    AgreementDetailData_AgreementId: 'ID ugovora',
    AgreementDetailData_Route: 'Ruta',
    AgreementDetailData_CustomerId: 'ID kupca',
    AgreementDetailData_Name: 'Naziv',
    AgreementDetailData_ContactName: 'Ime kontakta',
    AgreementDetailData_Address1: 'Adresa 1',
    AgreementDetailData_Address2: 'Adresa 2',
    AgreementDetailData_PostalCode: 'Poštanski broj',
    AgreementDetailData_City: 'Grad',
    AgreementDetailData_Other: 'Ostalo',
    AgreementDetailData_Message: 'Poruka',
    AgreementDetailData_Type: 'Vrsta',
    AgreementDetailData_LastChanged: 'Posljednja promjena',
  },
  impersonationManager: {
    _selectUser: 'Odaberi kupca/korisnika',
    _noOptionsMessage: 'Nema pronađenih korisnika',
    _impersonate: 'Koristi',
    _stopImpersonation: 'Prekini korištenje',
    _selectUserPlaceholder: 'Odaberi kupca/korisnika...',
  },
  userAdmin: {
    _noUsers: 'Nema dostupnih korisnika',
    _userName: 'E-pošta',
    _system: 'Sustav',
    _contractor: 'Izvođač',
    _contractorId: 'Transporter',
    _localAdmin: 'Lokalni administrator',
    _admin: 'Administrator',
    _modules: 'Moduli',
    _add: 'Dodaj',
    _update: 'Ažuriraj',
    _cancel: 'Odustani',
    _newUser: 'Dodaj novog korisnika',
    _userEditDialogTitle: 'Uredi korisnika',
    _userAddDialogTitle: 'Dodaj novog korisnika',
    _password: 'Lozinka',
    _passwordConfirmation: 'Potvrdi lozinku',
    _confirmPasswordError: 'Lozinke moraju biti iste',
    _noCustomerOptionsMessage: 'Nema pronađenih kupaca',
    _noContractorOptionsMessage: 'Nema pronađenih izvođača',
    _contractorsLoadingMessage: 'Izvođači se učitavaju',
    _noModuleOptionsMessage: 'Nema pronađenih modula',
    _modulesLoadingMessage: 'Moduli se učitavaju',
    _customerSelectorPlaceholder: 'Odaberi kupca...',
    _impersonationWarningPart1: 'Prijavljeni ste kao ',
    _impersonationWarningPart2:
      ', i nemate prava za korištenje ove stranice. Kliknite gumb ispod za povratak na administratorsku funkciju.',
  },
  vehicles: {
    _noVehicles: 'Nema vozila dostupnih',
    _vehicles: 'Vozila',
    _system: 'Sustav',
    _DTAShort: 'DTA',
    _DTALong: 'Digitalni Truck Assistant',
    _ajourShort: 'Ajour',
    _ajourLong: 'GPS Ajour',
    _vehicleId: 'Vozilo',
    _externalId: 'Vanjski Id',
    _description: 'Opis',
    _phoneNr: 'SIM Nr',
    _driver: 'Vozač',
    _password: 'Lozinka',
    _repeatPassword: 'Ponovi lozinku',
    _edit: 'Uredi',
    _cancel: 'Odustani',
    _update: 'Ažuriraj',
    _submit: 'Spremi',
    _addNewVehicle: 'Dodaj novo vozilo',
    _editMessageSuccessful: 'Vozilo ažurirano',
    _addMessageSuccessful: 'Vozilo dodano',
    _editMessageFailed: 'Neuspješno ažuriranje vozila',
    _addMessageFailed: 'Greška pri dodavanju vozila',
    _vehicleAlreadyExistingError: 'Vozilo s istim imenom već postoji',
    _validationError: 'Samo brojevi i slova su dopušteni',
    _contractorSelectorPlaceholder: 'Odaberi izvođača...',
    _noContractorFound: 'Nema dostupnih izvođača',
    _vehicleLoadingMessage: 'Vozila se učitavaju',
  },
  vehicleSettings: {
    _copyFromVehicleButton: 'Kopiraj iz...',
    _copyFromVehicleDialogTitle: 'Kopiraj postavke od korisnika',
    _copyButton: 'Kopiraj',
    _copyFromVehicleError: 'Greška pri kopiranju postavki korisnika',
    _copyFromVehicleSuccess: 'Postavke su kopirane od odabranog korisnika',
    _noSettings: 'Nema dostupnih postavki',
    _parameter: 'Parametar',
    _value: 'Vrijednost',
    _addToMultiple: 'Dodaj više vozila?',
    _description: 'Opis',
    _vehicle: 'Vozilo',
    _edit: 'Uredi',
    _cancel: 'Odustani',
    _multiselectError: 'Odaberite najmanje jedno vozilo',
    _update: 'Ažuriraj',
    _submit: 'Spremi',
    _addNewSetting: 'Dodaj novu postavku',
    _editMessageSuccessful: 'Postavka korisnika ažurirana',
    _addMessageSuccessful: 'Postavka korisnika dodana',
    _deleteMessageSuccessful: 'Postavka korisnika izbrisana',
    _editMessageFailed: 'Greška pri izmjeni postavke korisnika',
    _addMessageFailed: 'Greška pri dodavanju postavke korisnika',
    _deleteMessageFailed: 'Greška pri brisanju postavke korisnika',
    _deleteConfirm:
      'Jeste li sigurni da želite izbrisati ovu postavku korisnika',
    _vehicleSelectorPlaceholder: 'Odaberite vozilo...',
    _selectVehicle: 'Vozilo',
    _selectCustomer: 'Kupac',
    _selectContractor: 'Izvođač',
    _settingTemplateSelectorPlaceholder: 'Odaberite vrstu postavke...',
    _noSettingTemplateOptionsMessage: 'Nema pronađenih postavki',
    _cannotAddNewSettingMessage:
      'Ne možete dodati nove postavke. Sve postavke su već prisutne.',
    _failedToLoadSettingTempletes:
      'Ne mogu promijeniti ili dodati postavke. Nedostaju zadane postavke.',
  },
  map: {
    _trackingLineLayer: 'Praćenje linije',
    _trackInfo: 'GPS informacije',
    _trackInfoLine: 'GPS Praćenje',
    _trackInfoPointsAndLines: 'Praćenje',
    _streetLayer: 'Osnovna karta',
    _satelliteLayer: 'Zračni prikaz',
    _propertyLayer: 'Katastarska prikaz',
    _wmsLayer: 'WMS',
    _nWMSLayersSelected: '{0} Područje(a) odabrano',
    _lastUpdated: 'Ažurirano',
    _trackingLineLegend: {
      _belowSix: '< 6 sati',
      _sixToTwelve: '6-12 sati',
      _twelveToTwentyFour: '12-24 sati',
      _overTwentyFour: '> 24 sati',
      _gray: 'Nepoznato',
    },
  },
  contractors: {
    _contractors: 'Izvođači',
    _name: 'Ime izvođača',
    _externalId: 'Vanjski izvođač-id',
    _addContractor: 'Dodaj novog izvođača',
    _deleteConfirm: 'Jeste li sigurni da želite izbrisati ovog izvođača: ',
    _deleteSuccess: 'Izvođač izbrisan',
    _addSuccess: 'Izvođač dodan',
    _editSuccess: 'Izvođač ažuriran',
  },
  deviations: {
    _deviations: 'Odstupanja',
    _deviation: 'Odstupanje',
    _deviatonExtId: 'Odstupanje vanjski Id',
    _category: 'Kategorija',
    _deleteConfirm: 'Jeste li sigurni da želite izbrisati ovo odstupanje: ',
    _addNew: 'Dodaj odstupanje',
    _addSuccess: 'Odstupanje dodano',
    _editSuccess: 'Odstupanje ažurirano',
    _deleteSuccess: 'Odstupanje izbrisano',
    _selectCategory: 'Odaberite kategoriju odstupanja',
    _noCategory: 'Nema pronađene kategorije odstupanja',
    _deleteCategoryConfirm:
      'Jeste li sigurni da želite izbrisati ovu kategoriju odstupanja: ',
    _categoryDeleteSuccess: 'Kategorija odstupanja izbrisana',
    _deviationCategory: 'Kategorije odstupanja',
    _categoryName: 'Naziv kategorije odstupanja',
    _addCategorySuccess: 'Kategorija odstupanja dodana',
    _editCategorySuccess: 'Kategorija odstupanja ažurirana',
    _addNewCategory: 'Dodaj novu kategoriju odstupanja',
  },
  customerRegister: {
    message: {
      _directMessageToCustomer: 'Izravna poruka kupcu',
      _address: 'Adresa',
      _sender: 'Pošiljatelj',
      _expires: 'Ističe',
      _vehicle: 'Vozilo',
      _selectVehicle: 'Odaberi vozilo',
      _title: 'Naslov',
      _message: 'Poruka',
      _send: 'Pošalji',
      _messageSent: 'Poruka poslana',
      _noVehicle: 'Nema vozila',
    },
    grid: {
      _infoText:
        'Ovdje možete pretraživati kupca i poslati izravnu poruku o kupcu vozilu',
    },
  },
  newOrder: {
    days: {
      _monday: 'Ponedjeljak',
      _tuesday: 'Utorak',
      _wednesday: 'Srijeda',
      _thursday: 'Četvrtak',
      _friday: 'Petak',
      _saturday: 'Subota',
      _sunday: 'Nedjelja',
    },
    frequency: {
      _oneTime: 'Jedna pojava',
      _weekly: 'Tjedno',
      _weeks: 'Svaki # tjedan',
    },
  },
  directMessages: {
    _messages: 'Poruke',
    _address: 'Adresa',
    _sender: 'Pošiljatelj',
    _expires: 'Ističe',
    _vehicle: 'Vozilo',
    _title: 'Naslov',
    _message: 'Poruka',
    _date: 'Datum',
    _closeButton: 'Zatvori',
    _sent: 'Poslano',
    _received: 'Obrađeno',
    _read: 'Primljeno',
    _status: 'Status',
    _newMessage: 'Nova poruka',
    _fromDate: 'Od',
    _toDate: 'Do',
    _vehicles: 'Vozila',
    _chooseVehicles: 'Odaberite vozilo/a',
    _statuses: 'Statusi',
    _chooseStatuses: 'Odaberite status/e',
  },
  depots: {
    _deleteConfirm: 'Jeste li sigurni da želite izbrisati ovo skladište?',
    _deleteSuccess: 'Skladište izbrisano',
    _depots: 'Skladišta',
    _newDepot: 'Novo skladište',
    _id: 'Id',
    _customerId: 'Id kupca',
    _description: 'Opis',
    _latitude: 'Širina',
    _longitude: 'Dužina',
    _depotCreated: 'Skladište stvoreno',
    _depotUpdated: 'Skladište ažurirano',
    _createNew: 'Kreiraj novo skladište',
    _update: 'Ažuriraj skladište',
    _submit: 'Spremi',
    _cancelButton: 'Odustani',
  },
  orderOverview: {
    _hideFilter: 'Sakrij filter',
    _showFilter: 'Prikaži filter',
    _filter: 'Filter',
    _order: 'Narudžba',
    _date: 'Datum',
    _ordinaryDate: 'Uobičajeni datum',
    _expires: 'Istječe',
    _route: 'Ruta',
    _fractions: 'Frakcije',
    _contractor: 'Izvođač',
    _status: 'Status',
    _dateFilterInvalid: 'Filtar za datum je nevažeći',
    _from: 'Od',
    _to: 'Do',
    _contractors: 'Izvođači',
    _orderNumber: 'Broj narudžbe',
    _routes: 'Rute',
    _chooseRoutes: 'Odaberite rutu/e',
  },
  fractions: {
    _deleteConfirm: 'Jeste li sigurni da želite izbrisati ovu frakciju?',
    _deleteSuccess: 'Frakcija izbrisana',
    _newFraction: 'Nova frakcija',
    _fraction: 'Frakcija',
    _fractions: 'Frakcije',
    _picture: 'Slika',
    _fractionCreated: 'Frakcija stvorena',
    _fractionUpdated: 'Frakcija ažurirana',
    _fractionName: 'Naziv frakcije',
    _updateFraction: 'Ažuriraj frakciju',
    _submit: 'Spremi',
    _cancelButton: 'Odustani',
    _selectImage: 'Odaberite sliku',
  },
  units: {
    _units: 'Jedinice',
    _unitUpadated: 'Jedinica ažurirana',
    _unitName: 'Naziv jedinice',
    _commune: 'Općina',
    _fractions: 'Frakcije',
    _selectFractions: 'Odaberi frakcije',
    _cancel: 'Odustani',
    _save: 'Spremi',
    _manageFractions: 'Upravljaj frakcijama',
    _shortName: 'Litra',
    _freeBasedProduct: 'Proizvod temeljen na naknadi',
    _distance: 'Udaljenost',
    _container: 'Kontejner',
  },
};
