import React, { Component, ReactNode } from 'react';
import { IDepot } from '../../models/Depot.model';
import CommandCell from '../userAdmin/CommandCell';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization/localizationService';

export interface IDepotsGridProps {
  data: Array<IDepot>;
  className?: string;
  editDepot: (dataItem: IDepot) => void;
  deleteDepot: (dataItem: IDepot) => void;
}

export interface IDepotGridState {
  dataState: Record<string, any>;
}

export class DepotGrid extends Component<IDepotsGridProps, IDepotGridState> {
  public readonly state: IDepotGridState = {
    dataState: {
      sort: [
        {
          dir: 'asc',
          field: 'depotId',
        },
      ],
      take: 26,
      skip: 0,
    },
  };

  public dataStateChange(event: Record<string, any>): void {
    this.setState({ ...this.state, dataState: event.data });
  }

  private EditCell(props: any) {
    return <CommandCell {...props} edit={this.props.editDepot} icon={faEdit} />;
  }

  private DeleteCell(props: any) {
    return (
      <CommandCell {...props} edit={this.props.deleteDepot} icon={faTrash} />
    );
  }

  private getLocaleDecimal(columnProps: any) {
    const value = columnProps.dataItem[columnProps.field]
      .toString()
      .replace('.', locale.general._decimalSeparator);
    return <td>{value}</td>;
  }

  public render(): ReactNode {
    const { className, data } = this.props;
    const result = process(data, this.state.dataState);

    return (
      <>
        <Grid
          {...this.state.dataState}
          className={className + ' depots-grid'}
          data={result}
          selectedField="selected"
          pageable
          resizable
          pageSize={26}
          onDataStateChange={this.dataStateChange.bind(this)}
        >
          <GridNoRecords>{locale.general._noRecords}</GridNoRecords>

          <GridColumn field="description" title={locale.depots._description} />
          <GridColumn
            field="latitude"
            title={locale.depots._latitude}
            width={100}
            cell={this.getLocaleDecimal.bind(this)}
          />
          <GridColumn
            field="longitude"
            title={locale.depots._longitude}
            width={100}
            cell={this.getLocaleDecimal.bind(this)}
          />
          <GridColumn
            cell={this.EditCell.bind(this)}
            width={26}
            sortable={false}
          />
          <GridColumn
            cell={this.DeleteCell.bind(this)}
            width={26}
            sortable={false}
          />
        </Grid>
      </>
    );
  }
}
