import React, { Component, ReactNode } from 'react';
import { IUser } from '../../models';
import { IDeviationCategory } from '../../models/Deviation';
import { isNil } from 'lodash';
import PaneHeadline from '../shared/paneHeadline';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import '../deviations/deviations.scss';
import { DeviationCategoriesGrid } from './deviationCategoriesGrid';
import DeviationCategoryDialog from './deviationCategoryDialog';
import DeviationsService from '../../services/deviationsService';
import { Toastr } from '../../utils/Toastr';
import { locale } from '../../common/localization/localizationService';

export interface IDeviationCategoriesProps {
  getDeviationCategories: (
    customerId: number,
    ignoreCache?: boolean
  ) => Promise<Record<string, any>>;
  authentication: IUser;
  deviationCategoryList: Array<IDeviationCategory>;
}

export interface IDeviationCategoriesState {
  selectedCustomerId: number;
  deviationCategoryDialog: {
    show: boolean;
    deviationCategory: IDeviationCategory;
    deviationCategories: Array<IDeviationCategory>;
    customerId: number;
  };
}

export class DeviationCategories extends Component<
  IDeviationCategoriesProps,
  IDeviationCategoriesState
> {
  public readonly state: IDeviationCategoriesState = {
    selectedCustomerId: this.props.authentication.impersonatedCustomerId ?? this.props.authentication.customerId,
    deviationCategoryDialog: {
      show: false,
      deviationCategory: null,
      deviationCategories: null,
      customerId: null,
    },
  } as IDeviationCategoriesState;

  public componentDidMount(): void {
    this.initComponent();
  }

  private initComponent = (): void => {
    this.loadDeviationCategories(this.state.selectedCustomerId);
  };

  private loadDeviationCategories = async (
    customerId: number,
    ignoreCache = false
  ): Promise<void> => {
    if (
      !isNil(this.props.authentication.contractorId) &&
      !this.props.authentication.isAdmin &&
      !this.props.authentication.isLocalAdmin
    )
      return new Promise((resolve) => resolve(null));
    await this.props.getDeviationCategories(customerId, ignoreCache);
  };

  private addNewDeviationCategory(): void {
    this.setState({
      ...this.state,
      deviationCategoryDialog: {
        customerId: this.state.selectedCustomerId,
        deviationCategory: null,
        deviationCategories: this.props.deviationCategoryList,
        show: true,
      },
    });
  }

  private editDeviationCategory(devaitionCategory: IDeviationCategory): void {
    this.setState({
      ...this.state,
      deviationCategoryDialog: {
        customerId: this.state.selectedCustomerId,
        deviationCategory: devaitionCategory,
        deviationCategories: this.props.deviationCategoryList,
        show: true,
      },
    });
  }

  private hideDeviationCategoryDialog(rerender = false): void {
    this.setState({
      ...this.state,
      deviationCategoryDialog: {
        customerId: null,
        deviationCategory: null,
        deviationCategories: null,
        show: false,
      },
    });
    if (rerender) {
      this.loadDeviationCategories(this.state.selectedCustomerId, true);
    }
  }

  private async deleteDeviationCategory(devaitionCategory: IDeviationCategory) {
    if (
      confirm(
        locale.deviations._deleteCategoryConfirm +
          devaitionCategory.categoryText
      )
    ) {
      try {
        await DeviationsService.deleteCategory(
          this.state.selectedCustomerId,
          devaitionCategory.categoryId
        );
        Toastr.success(locale.deviations._deleteSuccess);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.loadDeviationCategories(this.state.selectedCustomerId, true);
      }
    }
  }

  public render(): ReactNode {
    const { deviationCategoryList } = this.props;
    return (
      <div className="deviation-categories">
        <>
        <div className="row">
          <PaneHeadline
            titleText={locale.deviations._deviationCategory}
            titleIcon={faExclamationCircle}
            className="col-sm-6"
          />
          <div className="col-sm-6 text-right">
          <button
            className="btn btn-outline-dark"
            onClick={() => this.addNewDeviationCategory()}
          >
            {locale.deviations._addNewCategory}
          </button>
        </div>

        </div>
          <div className="d-flex">
            {deviationCategoryList && (
              <DeviationCategoriesGrid
                data={deviationCategoryList}
                editDeviationCategory={this.editDeviationCategory.bind(this)}
                deleteDeviationCategory={this.deleteDeviationCategory.bind(
                  this
                )}
                className="mb-3"
              />
            )}
          </div>
        </>
        {this.state.deviationCategoryDialog.show && (
          <DeviationCategoryDialog
            deviationCategory={
              this.state.deviationCategoryDialog.deviationCategory
            }
            deviationCategories={
              this.state.deviationCategoryDialog.deviationCategories
            }
            customerId={this.state.deviationCategoryDialog.customerId}
            show={this.state.deviationCategoryDialog.show}
            onHide={this.hideDeviationCategoryDialog.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default DeviationCategories;
