import React, { useEffect, useState } from 'react';
import { IContractor, IRoute, IUser } from '../../models';
import PaneHeadline from '../shared/paneHeadline';
import { faBusinessTime, faFilter } from '@fortawesome/free-solid-svg-icons';
import OrderOverviewGrid from './OrderOverviewGrid';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import { locale } from '../../common/localization/localizationService';
import { Spinner2 } from '../shared/spinner2';
import './orderOverview.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import UpdateOrderDialog from '../dashboard/UpdateOrderDialog';
import { IFraction } from '../../models/Fraction.model';
import moment from 'moment';
import NewOrderDialog from '../dashboard/newOrderModal';
import ContractorsService from '../../services/ContractorsService';
import { getOrderStatus } from '../../utils/GeneralUtils';
import EventBus from '../../common/EventBus';
import Select from 'react-select';
import { OrderFilterService } from '../../services/filterService';

export interface IOrderOverviewProps {
  orderActions: any;
  impersonatedUser: any;
  authentication: IUser;
  orderOverview: Array<any>;
  isLoading: boolean;
  contractors: any;
  getContractors: any;
  allFractions: Array<IFraction>;
  orderOverviewFilter: IOrderOverviewFilter;
  statuses: Array<any>;
  routes: Array<IRoute>;
  loadRoutes: any;
}

export interface IOrderUpdateDialogState {
  show: boolean;
  order: any;
}

export interface INewOrderDialogState {
  show: boolean;
}

export interface IOrderOverviewFilter {
  fromDate: Date;
  toDate: Date;
  fractionIds: Array<number>;
  statusIds: Array<number>;
  contractorIds: Array<number>;
  routeIds: Array<string>;
}

export interface FilterOption {
  value: number | string;
  label: string;
}

export default function OrderOverview(props: IOrderOverviewProps): JSX.Element {
  const editDialog: IOrderUpdateDialogState = {
    show: false,
    order: null,
  };

  const [orderOverview, setOveroverview] = useState<Array<any>>(
    props.orderOverview
  );

  const [isFilterVisible, setFilterVisibility] = useState<boolean>(true);

  const [isFilterInvalid, setFilterValidity] = useState<boolean>(false);

  const [updateDialog, setUpdateDialog] =
    useState<IOrderUpdateDialogState>(editDialog);

  const [isNewOrderDialogVisible, setNewOrderDialogVisibility] =
    useState<boolean>(false);

  const [fractionOptions, setFractionOptions] = useState<Array<FilterOption>>(
    []
  );

  const [contractors, setContractors] = useState<Array<FilterOption>>([]);

  const [isContractorLoading, setIsContractorLoading] =
    useState<boolean>(false);

  const [statuses, setStatuses] = useState<Array<FilterOption>>([]);

  const [routes, setRoutes] = useState<Array<FilterOption>>([]);

  const defaultFractions = fractionOptions.filter((fraction) =>
    props.orderOverviewFilter.fractionIds.includes(fraction.value as number)
  );

  const defaultContractors = contractors.filter((contractor: any) =>
    props.orderOverviewFilter.contractorIds.includes(contractor.value)
  );

  const defaultStatuses = statuses.filter((status: any) => {
    return props.orderOverviewFilter.statusIds.includes(status.value);
  });

  const defaultRoutes = routes.filter((route) => {
    return props.orderOverviewFilter.routeIds.includes(route.label);
  });

  useEffect(() => {
    const formattedFromDate = props.orderOverviewFilter.fromDate
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '');
    const formattedToDate = props.orderOverviewFilter.toDate
      .toISOString()
      .split('T')[0]
      .replace(/-/g, '');
    props.orderActions.getOverView(formattedFromDate, formattedToDate);
    props.orderActions.getAllFractions();
    props.getContractors(props.authentication.customerId);
    props.orderActions.getOrderStatuses();
    props.loadRoutes();
    loadContractors();
  }, [props.orderActions, props.getContractors]);

  useEffect(() => {
    if (props.orderOverview) {
      setOveroverview(props.orderOverview);
      filterByDropdowns();
    }
  }, [props.orderOverview]);

  useEffect(() => {
    setStatuses(getStatuses());
  }, [props.statuses]);

  useEffect(() => {
    EventBus.emit('fullScreenChange', true);

    return () => {
      EventBus.emit('fullScreenChange', false);
    };
  }, []);

  // Server side rendering on date interval change
  useEffect(() => {
    if (!isFilterInvalid) {
      filterOrders();
    }
  }, [props.orderOverviewFilter.fromDate, props.orderOverviewFilter.toDate]);

  // Frontend side filtering on status, fraction and contractor change
  useEffect(() => {
    filterByDropdowns();
  }, [
    props.orderOverviewFilter.contractorIds,
    props.orderOverviewFilter.fractionIds,
    props.orderOverviewFilter.statusIds,
    props.orderOverviewFilter.routeIds,
  ]);

  useEffect(() => {
    const options = props.allFractions.map((fraction) => ({
      value: fraction.fractionId,
      label: fraction.name,
    }));
    setFractionOptions(options);
  }, [props.allFractions]);

  useEffect(() => {
    const options = props.routes.map((route) => ({
      value: route.name,
      label: route.name,
    }));
    setRoutes(options);
  }, [props.routes]);

  const loadContractors = () => {
    setIsContractorLoading(true);
    ContractorsService.getSelfContractors().then((resp: Array<IContractor>) => {
      const contractors = resp.map((contractor) => ({
        value: contractor.contractorId,
        label: contractor.contractorName,
      }));
      setContractors(contractors);
      setIsContractorLoading(false);
    });
  };

  const handleFromDateChange = (event: any) => {
    checkDateValidity(event.value, props.orderOverviewFilter.toDate);
    props.orderActions.setOrderoverviewFilter({
      ...props.orderOverviewFilter,
      fromDate: event.value,
    });
  };

  const handleToDateChange = (event: any) => {
    checkDateValidity(props.orderOverviewFilter.fromDate, event.value);
    props.orderActions.setOrderoverviewFilter({
      ...props.orderOverviewFilter,
      toDate: event.value,
    });
  };

  const checkDateValidity = (from: Date, to: Date): void => {
    if (moment(from).unix() > moment(to).unix()) {
      setFilterValidity(true);
    } else {
      setFilterValidity(false);
    }
  };

  const filterOrders = () => {
    if (
      props.orderOverviewFilter.fromDate &&
      props.orderOverviewFilter.toDate
    ) {
      const formattedFromDate = props.orderOverviewFilter.fromDate
        .toISOString()
        .split('T')[0]
        .replace(/-/g, '');
      const formattedToDate = props.orderOverviewFilter.toDate
        .toISOString()
        .split('T')[0]
        .replace(/-/g, '');
      props.orderActions.getOverView(
        formattedFromDate,
        formattedToDate,
        true,
        false
      );
    }
  };

  const showHideFilters = (): void => {
    setFilterVisibility(!isFilterVisible);
  };

  const editOrder = (order: any) => {
    props.orderActions.getAllFractions();
    const dialogProps = {
      order: order,
      show: true,
    };
    setUpdateDialog(dialogProps);
  };

  const hideUpdateOrderDialog = (rerender = false) => {
    const dialogProps = {
      order: {},
      show: false,
    };
    setUpdateDialog(dialogProps);
    if (rerender) {
      const formattedFromDate = props.orderOverviewFilter.fromDate
        .toISOString()
        .split('T')[0]
        .replace(/-/g, '');
      const formattedToDate = props.orderOverviewFilter.toDate
        .toISOString()
        .split('T')[0]
        .replace(/-/g, '');
      props.orderActions.getOverView(formattedFromDate, formattedToDate, true);
    }
  };

  const openNewOrderModal = () => {
    setNewOrderDialogVisibility(true);
  };

  const hideNewOrderModal = (rerender: boolean) => {
    setNewOrderDialogVisibility(false);

    if (rerender) {
      const formattedFromDate = props.orderOverviewFilter.fromDate
        .toISOString()
        .split('T')[0]
        .replace(/-/g, '');
      const formattedToDate = props.orderOverviewFilter.toDate
        .toISOString()
        .split('T')[0]
        .replace(/-/g, '');
      props.orderActions.getOverView(formattedFromDate, formattedToDate, true);
    }
  };

  const filterByFraction = (selectedOptions: Array<FilterOption>) => {
    if (selectedOptions) {
      const selectedIds = selectedOptions
        .map((option: FilterOption) => option.value)
        .sort((a: number, b: number) => {
          return a - b;
        });
      props.orderActions.setOrderoverviewFilter({
        ...props.orderOverviewFilter,
        fractionIds: selectedIds,
      });
    }
  };

  const filterByContractor = (selectedOptions: Array<FilterOption>) => {
    if (selectedOptions) {
      const selectedIds = selectedOptions.map(
        (option: FilterOption) => option.value
      );
      props.orderActions.setOrderoverviewFilter({
        ...props.orderOverviewFilter,
        contractorIds: selectedIds,
      });
    }
  };

  const filterByStatus = (selectedOptions: Array<FilterOption>) => {
    if (selectedOptions) {
      const selectedIds = selectedOptions.map(
        (option: FilterOption) => option.value
      );
      props.orderActions.setOrderoverviewFilter({
        ...props.orderOverviewFilter,
        statusIds: selectedIds,
      });
    }
  };

  const filterByRoute = (selectedOptions: Array<FilterOption>) => {
    if (selectedOptions) {
      const selectedRoutes = selectedOptions.map(
        (option: FilterOption) => option.value
      );
      props.orderActions.setOrderoverviewFilter({
        ...props.orderOverviewFilter,
        routeIds: selectedRoutes,
      });
    }
  };

  const getStatuses = () => {
    const statuses: Array<any> = [];
    props.statuses.forEach((status) => {
      status.status = getOrderStatus(status.orderStatusId);
      statuses.push(status);
    });
    return statuses.map((status) => ({
      value: status.orderStatusId,
      label: status.status,
    }));
  };

  const filterByDropdowns = () => {
    let allOrder = props.orderOverview;
    if (props.orderOverviewFilter.fractionIds.length > 0) {
      const filteredOrdes: Array<any> = [];
      props.orderOverviewFilter.fractionIds.forEach((fractionId) => {
        allOrder.forEach((order) => {
          if (
            order.fractions.some(
              (fraction: IFraction) => fraction.fractionId === fractionId
            ) &&
            !filteredOrdes.some(
              (filteredOrder) => filteredOrder.orderId === order.orderId
            )
          ) {
            filteredOrdes.push(order);
          }
        });
        allOrder = OrderFilterService.filter(filteredOrdes);
      });
    }
    if (props.orderOverviewFilter.contractorIds.length > 0) {
      const orders = allOrder.filter((order) =>
        props.orderOverviewFilter.contractorIds.includes(order.contractorId)
      );
      allOrder = OrderFilterService.filter(orders);
    }
    if (props.orderOverviewFilter.statusIds.length > 0) {
      const orders = allOrder.filter((order) => {
        return props.orderOverviewFilter.statusIds.includes(
          order.orderStatus.orderStatusId
        );
      });
      allOrder = OrderFilterService.filter(orders);
    }
    if (props.orderOverviewFilter.routeIds.length > 0) {
      const orders = allOrder.filter((order) => {
        return props.orderOverviewFilter.routeIds.includes(
          order.routeData.name
        );
      });
      allOrder = OrderFilterService.filter(orders);
    }
    setOveroverview(allOrder);
  };

  return (
    <>
      <div className="order-overview">
        <div className="order-overview-header-row">
          <PaneHeadline
            titleText={locale.general._orderOverview}
            titleIcon={faBusinessTime}
          />
          <div className="order-overview-action-wrapper">
            <button
              className={`show-filters-button ${
                isFilterVisible ? 'filter-active' : ''
              }`}
              onClick={() => showHideFilters()}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
            <button
              className="btn btn-outline-dark mr-2 open-modal-button"
              onClick={() => openNewOrderModal()}
            >
              {locale.orders._newOrder}
            </button>
          </div>
        </div>
        <Spinner2 show={props.isLoading || isContractorLoading} />
        <div className="order-overview-content">
          <OrderOverviewGrid
            data={orderOverview}
            editOrder={editOrder}
            contractors={contractors}
            clasName={isFilterVisible ? 'filter-open' : ''}
          />
          <div
            className={`order-overview-filter ${isFilterVisible ? 'open' : ''}`}
          >
            <div className="filter-column">
              <label>{locale.orderOverview._from}</label>
              <DatePicker
                value={props.orderOverviewFilter.fromDate}
                onChange={handleFromDateChange}
                format={locale.general._fullDateFormat}
                className={`date-picker ${
                  isFilterInvalid ? 'input-invalid' : ''
                }`}
                popupSettings={{ popupClass: 'date-picker-popup' }}
              />
              <label>{locale.orderOverview._to}</label>
              <DatePicker
                value={props.orderOverviewFilter.toDate}
                onChange={handleToDateChange}
                format={locale.general._fullDateFormat}
                className={`date-picker ${
                  isFilterInvalid ? 'input-invalid' : ''
                }`}
                popupSettings={{ popupClass: 'date-picker-popup' }}
              />
              <label>{locale.orderOverview._status}</label>
              <Select
                options={statuses}
                value={defaultStatuses}
                onChange={(e: Array<FilterOption>) => filterByStatus(e)}
                isMulti
                isDisabled={statuses.length < 2 && defaultStatuses.length < 1}
                placeholder={locale.orders._chooseStatus}
                className="input-field react-select"
              />
              <label>{locale.orderOverview._routes}</label>
              <Select
                options={routes}
                value={defaultRoutes}
                onChange={(e: Array<FilterOption>) => filterByRoute(e)}
                isMulti
                isDisabled={routes.length < 2 && defaultRoutes.length < 1}
                placeholder={locale.orderOverview._chooseRoutes}
                className="input-field react-select"
              />
            </div>
            <div className="filter-column">
              <label>{locale.orderOverview._fractions}</label>
              <Select
                options={fractionOptions}
                value={defaultFractions}
                onChange={(e: Array<FilterOption>) => filterByFraction(e)}
                isMulti
                isDisabled={props.allFractions.length < 2}
                placeholder={locale.orders._chooseFraction}
                className="input-field react-select"
              />
              <label>{locale.orderOverview._contractors}</label>
              <Select
                options={contractors}
                value={defaultContractors}
                onChange={(e: Array<FilterOption>) => filterByContractor(e)}
                isMulti
                isDisabled={contractors.length < 2}
                placeholder={locale.orders._chooseContractor}
                className="input-field react-select"
                menuPlacement="auto"
              />
              <div className="filter-button-wrapper">
                {isFilterInvalid && (
                  <div className="filter-invalid">
                    {locale.orderOverview._dateFilterInvalid}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {updateDialog.show && (
          <UpdateOrderDialog
            order={updateDialog.order}
            fractions={props.allFractions}
            show={updateDialog.show}
            onHide={hideUpdateOrderDialog}
          />
        )}
        {isNewOrderDialogVisible && (
          <NewOrderDialog
            show={isNewOrderDialogVisible}
            onHide={hideNewOrderModal}
          />
        )}
      </div>
    </>
  );
}
