import React, { Component, ReactNode } from 'react';

import { IRoute } from '../../models';
import { locale } from '../../common/localization/localizationService';
import { filterBy } from '@progress/kendo-data-query';
import { FilterDescriptor } from '@progress/kendo-react-dropdowns/dist/npm/common/filterDescriptor';
import RefreshButton from '../shared/refreshButton/RefreshButton';
import { FilterOption } from '../orderOverview/OrderOverview';
import Select from 'react-select';

export interface IRouteAdminSelectProps {
  disabled: boolean;
  buttonClass: string;
  hideButtonClass: string;
  data: Array<IRoute>;
  defaultValue: Array<IRoute>;
  refreshItems: () => void;
  hideRouteStopsWithoutAgreement: () => void;
  changeRegionFilteringState: () => void;
  onChange: (routes: Array<FilterOption>) => void;
}
export interface IRouteAdminSelectState {
  data: Array<IRoute>;
  options: Array<IRoute>;
  routeOptions: Array<FilterOption>;
  filter?: FilterDescriptor;
}
export class RouteAdminSelect extends Component<
  IRouteAdminSelectProps,
  IRouteAdminSelectState
> {
  public readonly state: IRouteAdminSelectState = {
    data: [],
    options: [],
    routeOptions: [],
  };

  public componentDidUpdate(): void {
    const prev = this.state.data.map((data) => data.routeNumbder);
    const next = this.props.data.map((data) => data.routeNumbder);

    const routeOptions = this.props.data.map((route) => {
      return { value: route.routeNumbder, label: route.name };
    });
    if (prev.join(',') === next.join(',')) return;
    this.setState({
      ...this.state,
      data: this.props.data.slice(),
      options: filterBy(this.props.data.slice(), this.state.filter),
      routeOptions: routeOptions,
    });
  }

  public refreshItems(): void {
    this.props.refreshItems();
  }

  public hideRouteStopsWithourAgreement(): void {
    this.props.hideRouteStopsWithoutAgreement();
  }

  public changeRegionFilteringState(): void {
    this.props.changeRegionFilteringState();
  }

  public render(): ReactNode {
    const { disabled, defaultValue, buttonClass, hideButtonClass } = this.props;
    const defaultRoutes = this.state.routeOptions.filter((route) => {
      return defaultValue
        .map((value) => value.routeNumbder)
        .includes(route.value as string);
    });
    return (
      <div className="route-admin-select">
        <div className="row">
          <div className="col-3 select-label">
            <h6>{locale.routeAdminPage._selectRoute}</h6>
          </div>
          <div className="col-9 text-right mb-3 select-labels">
            <button
              className={`btn btn-outline-dark mr-1 ${hideButtonClass}`}
              onClick={() => this.hideRouteStopsWithourAgreement()}
            >
              {locale.routeAdminPage._hideWithoutAgreement}
            </button>
            <button
              className={`btn btn-outline-dark mr-1 ${buttonClass}`}
              onClick={() => this.changeRegionFilteringState()}
            >
              {locale.routeAdminPage._withoutPosition}
            </button>
            <RefreshButton onClick={() => this.refreshItems()}>
              <span className={'pl-1'}>{locale.routeAdminPage._refresh}</span>
            </RefreshButton>
          </div>
        </div>

        <Select
          options={this.state.routeOptions}
          value={defaultRoutes}
          isMulti
          filterable={true}
          disabled={disabled}
          onChange={(e: Array<FilterOption>) => this.props.onChange(e)}
          placeholder={locale.routeAdminPage._chooseRoutes}
        />
      </div>
    );
  }
}
