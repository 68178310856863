import React, { Component, ReactNode } from 'react';
import { groupBy } from '../../services/listService';
import { MapUtility } from '../map/MapUtility';
import { RouteStopMarker } from './RouteStopMarker';
import routes from '../../common/routes';
import { ClusteredMapLayer } from '../shared/customMapLayer';
import RouteZoomManager from './RouteZoomManager';
import { IRouteStop, ISelectedOrder } from '../../models';
import { selectedRegion } from '../../appConfig';

export interface ISelectedRouteLayerProps {
  selectedOrder: ISelectedOrder;
  clusteringActive: boolean;
  zoomLevel: number;
  location: Location;
}

export class SelectedRouteLayer extends Component<ISelectedRouteLayerProps> {
  public filterRoutes(generatedRoutes: Array<IRouteStop>): Array<IRouteStop> {
    const groupedValues = groupBy(
      generatedRoutes,
      (group: any) => group.routeLineId
    ).values();
    return Array.from(
      groupedValues,
      (routes) => routes.filter((route: any) => route.routeLine)[0]
    );
  }

  public mapRouteStopToMarker(
    routeStop: IRouteStop | Array<IRouteStop>,
    zoomLevel: number
  ): ReactNode {
    if (routeStop) {
      const routeLine = Array.isArray(routeStop)
        ? routeStop[0].routeLine
        : routeStop.routeLine;
      if (
        !routeLine ||
        !MapUtility.isInRegion(
          routeLine.degLat,
          routeLine.degLong,
          selectedRegion
        )
      ) {
        return null;
      }
      return (
        <RouteStopMarker
          key={routeLine.routeLineId}
          routeStop={routeStop}
          zoomLevel={zoomLevel}
        />
      );
    }
  }

  /** @todo: this check should not be here */
  public ordersViewActive(location: Location): boolean {
    return location.pathname.startsWith(routes.dashboard.base);
  }

  public getMarkers(
    selectedOrder: ISelectedOrder,
    zoomLevel: number
  ): Array<ReactNode> {
    if (!selectedOrder || !selectedOrder.generatedRoutes) {
      return null;
    }

    const duplicates: { [key: string]: IRouteStop[] } = {};
    const filteredArray = this.filterRoutes(
      selectedOrder.generatedRoutes
    ).filter((obj) => {
      if (obj && obj.routeLine) {
        const key = obj.routeLine.degLat + ' - ' + obj.routeLine.degLong;
        if (duplicates[key]) {
          duplicates[key].push(obj);
          return false;
        } else {
          duplicates[key] = [obj];
          return true;
        }
      } else {
        return false;
      }
    });

    const duplicateArrays = Object.values(duplicates).filter(
      (arr) => arr.length > 1 && arr.every((obj) => obj.routeLine)
    );

    // Step 1: Flatten the array of arrays to get all routeLineIds
    const allRouteLineIds = duplicateArrays.reduce((acc: number[], group) => {
      return acc.concat(group.map((item) => item.routeLineId));
    }, []);

    // Step 2: Filter the second array to remove objects with routeLineId in the flattened list
    const filteredRegularArray = filteredArray.filter(
      (item) => !allRouteLineIds.includes(item.routeLineId)
    );

    let singleMarkers = filteredRegularArray.map((route) =>
      this.mapRouteStopToMarker(route, zoomLevel)
    );
    duplicateArrays.forEach((routes) => {
      singleMarkers = [
        ...singleMarkers,
        this.mapRouteStopToMarker(routes, zoomLevel),
      ];
    });

    return singleMarkers;
  }

  public render(): ReactNode {
    const { clusteringActive, selectedOrder, zoomLevel, location } = this.props;
    const markers = this.ordersViewActive(location)
      ? this.getMarkers(selectedOrder, zoomLevel)
      : null;

    return (
      <ClusteredMapLayer isClusteringActive={clusteringActive}>
        <RouteZoomManager />
        {markers}
      </ClusteredMapLayer>
    );
  }
}

export default SelectedRouteLayer;
