import React from 'react';
import { Component, ReactNode } from 'react';
import { IUser } from '../../models';
import PaneHeadline from '../shared/paneHeadline';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons';
import './fractions.scss';
import { IFraction } from '../../models/Fraction.model';
import { Spinner2 } from '../shared/spinner2';
import { FractionGrid } from './fractionsGrid';
import FractionDialog from './FractionDialog';
import { locale } from '../../common/localization/localizationService';

export interface IFractionsProps {
  impersonatedUser: any;
  authentication: IUser;
  fractions: Array<IFraction>;
  getFractions: (ignoreCache?: boolean) => void;
  fetchFractionPictures: (ignoreCache?: boolean) => void;
  loading: boolean;
  fractionPictures: Array<string>;
}

export interface IFractionState {
  fractionDialog: {
    show: boolean;
    fraction?: IFraction;
    customerId: number;
  };
  deleteLoading: boolean;
}

export class Fractions extends Component<IFractionsProps, IFractionState> {
  public readonly state: IFractionState = {
    fractionDialog: {
      customerId: null,
      show: false,
      fraction: null,
    },
    deleteLoading: false,
  };

  public componentDidMount(): void {
    this.props.getFractions();
  }

  private addNewFraction(): void {
    this.props.fetchFractionPictures();
    this.setState({
      ...this.state,
      fractionDialog: {
        customerId: this.props.authentication.customerId,
        show: true,
        fraction: null,
      },
    });
  }

  private editFraction(fraction: IFraction): void {
    this.props.fetchFractionPictures();
    this.setState({
      ...this.state,
      fractionDialog: {
        customerId: this.props.authentication.customerId,
        show: true,
        fraction: fraction,
      },
    });
  }

  private hideFractionDialog(renderer = false): void {
    this.setState({
      ...this.state,
      fractionDialog: {
        customerId: null,
        show: false,
        fraction: null,
      },
    });
    if (renderer) {
      this.props.getFractions(true);
    }
  }

  public render(): ReactNode {
    const { loading, fractions, fractionPictures } = this.props;
    const { deleteLoading } = this.state;
    return (
      <>
        <div className="fractions">
          <div className="row">
            <PaneHeadline
              titleText={locale.fractions._fractions}
              titleIcon={faLayerGroup}
              className="col-sm-6"
            />
            <div className="col-sm-6 text-right mb-3">
              <button
                className="btn btn-outline-dark"
                onClick={() => this.addNewFraction()}
              >
                {locale.fractions._newFraction}
              </button>
            </div>
          </div>
          <div className="d-flex">
            <Spinner2 show={loading || deleteLoading} />
            <FractionGrid
              data={fractions}
              editFraction={this.editFraction.bind(this)}
              className="mb-3"
            />
          </div>
          {this.state.fractionDialog.show && (
            <FractionDialog
              fraction={this.state.fractionDialog.fraction}
              customerId={this.state.fractionDialog.customerId}
              show={this.state.fractionDialog.show}
              onHide={this.hideFractionDialog.bind(this)}
              fractionPictures={fractionPictures}
            />
          )}
        </div>
      </>
    );
  }
}
