import UserAdmin from './UserAdmin';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import userActions from '../../redux/modules/users/userActions';
import customerActions from '../../redux/modules/customer/customerActions';
import moduleActions from '../../redux/modules/module/moduleActions';
import contractorActions from '../../redux/modules/contractor/contractorActions';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import userConstants from '../../redux/modules/users/userConstants';
import moduleConstants from '../../redux/modules/module/moduleConstants';
import contractorConstants from '../../redux/modules/contractor/contractorConstants';
import * as authCreators from '../../redux/modules/auth/authCreators';
import * as settingsCreators from '../../redux/modules/settings/settingsCreators';
import * as vehicleTrackingActions from '../../redux/modules/vehicleTracking/vehicleTrackingActions';
import { vehicleTrackingConstants } from '../../redux/modules/vehicleTracking/vehicleTrackingConstants';

const isUsersLoading = createLoadingSelector(
  userConstants.GET_USERS,
  userConstants.UPDATE_USER,
  userConstants.CREATE_USER,
  moduleConstants.GET_MODULES,
  contractorConstants.GET_CONTRACTORS
);

const isVehiclesLoading = createLoadingSelector(
  vehicleTrackingConstants.GET_VEHICLES
);

function mapStateToProps(state) {
  return {
    users: state.users.users,
    modules: state.module.modules,
    contractors: state.contractor.contractors,
    isLoading: isUsersLoading(state),
    impersonatedUser: state.authentication.impersonatedUser,
    isVehiclesLoading: isVehiclesLoading(state),
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    moduleActions: bindActionCreators(moduleActions, dispatch),
    contractorActions: bindActionCreators(contractorActions, dispatch),
    settingsActions: bindActionCreators(settingsCreators, dispatch),
    authActions: bindActionCreators(authCreators, dispatch),
    vehicleTrackingActions: bindActionCreators(
      vehicleTrackingActions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdmin);
