import React, { useEffect, useState } from 'react';
import { IUnit, IUser } from '../../models';
import PaneHeadline from '../shared/paneHeadline';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import './units.scss';
import { IFraction } from '../../models/Fraction.model';
import { Spinner2 } from '../shared/spinner2';
import { UnitGrid } from './UnitGrid';
import EditUnitDialog from './EditUnitDialog';
import { locale } from '../../common/localization/localizationService';

export interface IUnitsProps {
  impersonatedUser: any;
  authentication: IUser;
  units: Array<IUnit>;
  fractions: Array<IFraction>;
  isLoading: boolean;
  getUnits: (ignoreCache?: boolean) => void;
  getFractions: (ignoreCache?: boolean) => void;
}

export interface IEditUnitDialogState {
  show: boolean;
  unit: IUnit;
}

export default function Units(props: IUnitsProps): JSX.Element {
  const editDialogState: IEditUnitDialogState = {
    show: false,
    unit: null,
  };

  const [editDialog, setEditDialog] =
    useState<IEditUnitDialogState>(editDialogState);

  useEffect(() => {
    const ignoreCache = false;
    props.getUnits(ignoreCache);
  }, []);

  const openEditModal = (dataItem: IUnit) => {
    props.getFractions();
    const editDialogState: IEditUnitDialogState = {
      show: true,
      unit: dataItem,
    };
    setEditDialog(editDialogState);
  };

  const closeEditModal = (rerender = false) => {
    const editDialogState: IEditUnitDialogState = {
      show: false,
      unit: null,
    };
    setEditDialog(editDialogState);
    if (rerender) {
      props.getUnits(rerender);
    }
  };

  return (
    <>
      <div className="units">
        <div className="order-overview-header-row">
          <PaneHeadline titleText={locale.units._units} titleIcon={faTrash} />
          <Spinner2 show={props.isLoading} />
          <UnitGrid data={props.units} editUnit={openEditModal} />
          {editDialog.show && (
            <EditUnitDialog
              unit={editDialog.unit}
              show={editDialog.show}
              fractions={props.fractions}
              onHide={closeEditModal}
            />
          )}
        </div>
      </div>
    </>
  );
}
