import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as orderActions from '../../redux/modules/order/orderActions';
import * as vehicleTrackingActions from '../../redux/modules/vehicleTracking/vehicleTrackingActions';
import cacheRefresherActions from '../../redux/modules/cacheRefresher/cacheRefresherActions';
import Dashboard from './Dashboard';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import { orderConstants } from '../../redux/modules/order/orderConstants';

const isOrdersLoading = createLoadingSelector(orderConstants.GET_ORDERS);

function mapStateToProps(state) {
  return {
    isLoading: isOrdersLoading(state),
    orders: state.order.data,
    selected: state.order.selected,
    countdownEnd: state.cacheRefresher.countdownEnd,
    customerId: state.authentication.customerId,
    fractions: state.order.fractions,
    allFractions: state.order.allFractions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    vehicleTrackingActions: bindActionCreators(
      vehicleTrackingActions,
      dispatch
    ),
    cacheRefresherActions: bindActionCreators(cacheRefresherActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
