import directMessagesConstants from './directMessagesConstants';

const today = new Date();
const oneWeekEarlier = new Date();
oneWeekEarlier.setDate(today.getDate() - 7);

const initialState = {
  messages: [],
  isLoading: false,
  directMessagesFilter: {
    fromDate: oneWeekEarlier,
    toDate: today,
    vehicleIds: [],
    statusIds: [],
  },
};

export default function directMessagesReducer(state = initialState, action) {
  switch (action.type) {
    case directMessagesConstants.GET_MESSAGES:
      return {
        ...state,
        isLoading: true,
      };
    case directMessagesConstants.GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.messages,
        isLoading: false,
      };
    case directMessagesConstants.GET_MESSAGES_FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case directMessagesConstants.SET_DIRECT_MESSAGES_FILTER:
      return {
        ...state,
        directMessagesFilter: action.filter,
      };
    default:
      return state;
  }
}
