import { bindActionCreators, Dispatch } from 'redux';
import directMessagesActions from '../../redux/modules/directMessages/directMessagesActions';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import { connect } from 'react-redux';
import Messages from './DirectMessages';
import { fetchVehicles } from '../../redux/modules/vehicles/vehiclesCreators';
import { IVehiclesState } from '../vehicles/Vehicles';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getMessages: directMessagesActions.getMessages,
      setFilter: directMessagesActions.setDirectMessagesFilter,
      fetchVehicles,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    authentication: state.authentication as unknown as IUser,
    messageList: state.message.messages,
    isLoading: state.message.isLoading,
    directMessagesFilter: state.message.directMessagesFilter,
    vehicles: state.vehicles as IVehiclesState,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Messages);
