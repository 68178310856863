import { IFractionState } from './fractionsState';
import { All as Actions } from './fractionsActions';
import { FractionsConstants } from './fractionsConstants';

const initialState: IFractionState = {
  fractions: [],
  pictures: [],
  errorMessage: null,
  isLoading: false,
};

export function fraction(
  state = initialState,
  action: Actions
): IFractionState {
  switch (action.type) {
    case FractionsConstants.GET_ALL_FRACTIONS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FractionsConstants.GET_ALL_FRACTIONS_SUCCESS: {
      return {
        ...state,
        fractions: action.fractions,
        isLoading: false,
      };
    }
    case FractionsConstants.GET_ALL_FRACTIONS_FAILED: {
      return {
        ...state,
        errorMessage: action.error.message,
        isLoading: false,
      };
    }
    case FractionsConstants.GET_FRACTION_PICTURES_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FractionsConstants.GET_FRACTION_PICTURES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        pictures: action.pictures,
      };
    }
    case FractionsConstants.GET_FRACTION_PICTURES_FAILED: {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error.message,
      };
    }
    default:
      return state;
  }
}
