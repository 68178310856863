import React, { Component, ReactNode } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { locale } from '../../../common/localization/localizationService';
import moment from 'moment';
import './newOrderModal.scss';
import { IContractor, IRoute } from '../../../models';
import { IAuthState } from '../../../redux/modules/auth';
import { isNil, uniqueId } from 'lodash';
import { AsyncAction } from '../../../utils';
import { Repetition, daylist, getFrequency } from './newOrderConstants';
import { ICustomerGroup } from '../../../services/groupService';
import { IFraction } from '../../../models/Fraction.model';
import { History } from 'history';
import { Formik } from 'formik';
import { DatePicker, Select } from '../../shared/formikFields';
import { debounceEventHandler } from '../../shared/submitFormUtility';
import OrderService from '../ordersService';

import yup from '../../../common/validation';

export interface INewOrderDialogProps {
  show: boolean;
  contractors?: any;
  routes?: IRoute[];
  authentication?: IAuthState;
  customerGroups?: ICustomerGroup[];
  fractions?: IFraction[];
  match?: any;
  history?: History;
  onHide: (rerender: boolean) => void;
  getContractors?: (
    customerId: number,
    ignoreCache?: boolean
  ) => Promise<Record<string, any>>;
  loadRoutes?: (ignoreCache?: boolean) => AsyncAction;
  loadCustomerGroups?: (ignoreCache?: boolean) => AsyncAction;
  loadFractions?: (ignoraCache?: boolean) => AsyncAction;
}

export interface INewOrderDialogState {
  isOrdinaryDateVisible: boolean;
  isExpireDateVisible: boolean;
  isRecurrentOrderSelected: boolean;
  routes: IRoute;
  contractors: IContractor[];
  fractions: IFraction[];
  customerGroups: ICustomerGroup[];
  emptyDays: Repetition[];
  frequency: Repetition;
}

interface SelectOption {
  label: string;
  value: any;
}

export default class NewOrderDialog extends Component<INewOrderDialogProps> {
  public readonly state: INewOrderDialogState = {
    isOrdinaryDateVisible: true,
    isExpireDateVisible: true,
    isRecurrentOrderSelected: false,
    routes: null,
    contractors: [],
    customerGroups: [],
    emptyDays: [],
    fractions: [],
    frequency: null,
  };

  public componentDidMount(): void {
    this.loadContractors(this.props.authentication.customerId);
    this.props.loadRoutes();
    this.props.loadCustomerGroups();
    this.props.loadFractions();
  }

  public getSchema(): any {
    return yup.object().shape({
      date: yup
        .date()
        .required()
        .min(moment().startOf('day').toDate())
        .label(locale.orders._startDate),
      ordinarydate: yup
        .date()
        .required()
        .min(yup.ref('date'))
        .label(locale.orders._ordinaryDate),
      expireDate: yup
        .date()
        .required()
        .min(yup.ref('date'))
        .label(locale.orders._expireDate),
      repetationEndDate: yup
        .date()
        .required()
        .min(yup.ref('date'))
        .label(locale.orders._repetationEndDate),
      contractors: yup.object().required().label(locale.orders._contractor),
      routes: yup.array().required().label(locale.orders._route),
      fractions: yup.array().required().label(locale.orders._fractions),
      customerGroups: yup
        .array()
        .required()
        .label(locale.orders._customerGroup),
    });
  }

  private createDtoFromFormData(form: any) {
    const contractor = {
      name: form.contractors.value.contractorName,
      contractorId: form.contractors.value.contractorId,
    };

    const routes =
      !form.routes || !Array.isArray(form.routes)
        ? []
        : form.routes
            .filter((x: any) => x.value)
            .map((x: any) => ({
              name: x.value.name,
              paSystem: x.value.paSystem,
              routeNumber: x.value.routeNumbder,
            }));

    const fractions =
      !form.fractions || !Array.isArray(form.fractions)
        ? []
        : form.fractions
            .filter((x: any) => x.value)
            .map((x: any) => ({
              name: x.value.name,
              fractionId: x.value.fractionId,
            }));

    const customerTypes =
      !form.customerGroups || !Array.isArray(form.customerGroups)
        ? []
        : form.customerGroups
            .filter((x: any) => x.value)
            .map((x: any) => x.value.customerTypeId);

    const frequency = form.frequency?.value ?? 1;
    const dayList =
      !form.dayList || !Array.isArray(form.dayList)
        ? []
        : form.dayList.filter((x: any) => x.value).map((x: any) => x.value);

    const ordinaryDate = this.state.isOrdinaryDateVisible
      ? form.ordinarydate
      : form.date;
    const expireDate = this.state.isExpireDateVisible
      ? form.expireDate
      : form.date;

    const dto = {
      date: form.date,
      ordinaryDate: ordinaryDate,
      expireDate: expireDate,
      repetitionEndDate: form.repetationEndDate,
      contractor: contractor,
      routes: routes,
      fractions: fractions,
      frequency: frequency,
      dayList: dayList,
      customerTypes: customerTypes,
    };

    return dto;
  }

  private frequencyChange = (name: any, option: any) => {
    if (option.value > 1) {
      this.setState({
        ...this.state,
        isOrdinaryDateVisible: false,
        isExpireDateVisible: false,
        isRecurrentOrderSelected: true,
      });
    } else {
      this.setState({
        ...this.state,
        isOrdinaryDateVisible: true,
        isExpireDateVisible: true,
        isRecurrentOrderSelected: false,
      });
    }
  };

  private loadContractors = async (
    customerId: number,
    ignoreCache = false
  ): Promise<void> => {
    if (!isNil(this.props.authentication.contractorId))
      return new Promise((resolve) => resolve(null));
    const contractorsReq = await this.props.getContractors(
      customerId,
      ignoreCache
    );
    const contractors: Array<IContractor> = contractorsReq.contractors
      ? contractorsReq.contractors.sort((a: IContractor, b: IContractor) =>
          a.contractorName.localeCompare(b.contractorName)
        )
      : [];
    const selectedContractorId =
      (Array.isArray(contractors) && contractors[0]?.contractorId) || -1;

    await new Promise((resolve) => {
      this.setState(
        {
          ...this.state,
          contractorsList: contractors,
          selectedContractorId,
        },
        () => resolve(null)
      );
    });
  };

  public getFilterDate(): moment.Moment {
    const parsedDate = moment(new Date(), 'YYYY-MM-DD', true);

    return parsedDate.isValid() ? parsedDate : moment();
  }

  public getOptions<T>(items: T[], labelProperty: keyof T): SelectOption[] {
    if (items) {
      const options = items.map((item) => {
        return {
          label: item[labelProperty].toString(),
          value: item,
          key: uniqueId(),
        };
      });
      return options;
    }
    return [];
  }

  public render(): ReactNode {
    const initialValues = {};
    const { show, authentication } = this.props;
    const onHide = this.props.onHide;
    const routeOptions = this.getOptions(this.props.routes, 'name');
    const contractorOptions = this.getOptions(
      this.props.contractors[authentication.customerId],
      'contractorName'
    );
    const date = new Date();
    const ordinarydate = new Date();
    const expireDate = new Date();
    const repetationEndDate = new Date();
    const customerGroupOptions = this.getOptions(
      this.props.customerGroups,
      'description'
    );
    const fractionOptions = this.getOptions(this.props.fractions, 'name');
    const frequency = getFrequency();
    const handleSubmit = async (values: any, formikHelpers: any) => {
      try {
        formikHelpers.setSubmitting(true);
        const dto = this.createDtoFromFormData(values);
        await OrderService.createOrder(dto);
        onHide(true);
      } catch (error) {
        console.error('Error submitting form', error);
      } finally {
        formikHelpers.setSubmitting(false);
      }
    };
    return (
      <Formik
        validationSchema={this.getSchema}
        onSubmit={handleSubmit}
        initialValues={{
          ...initialValues,
          date,
          ordinarydate,
          expireDate,
          repetationEndDate,
          frequency: frequency[0],
        }}
      >
        {({ handleSubmit }) => (
          <Modal
            onHide={() => onHide(false)}
            show={show}
            centered
            dialogClassName="add-order-modal"
          >
            <Modal.Header closeButton>
              <Modal.Title>{locale.orders._newOrder}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate onSubmit={handleSubmit}>
                <div className="selectors-wrapper">
                  <Select
                    name="contractors"
                    options={contractorOptions}
                    label={locale.orders._contractor}
                    noOptionsMessage={''}
                    loadingMessage={''}
                    placeholder={locale.orders._selectContractor}
                    isLabelTop={true}
                    labelCol={0}
                  />
                  <Select
                    options={routeOptions}
                    placeholder={locale.orders._route}
                    label={locale.orders._selectRoute}
                    name="routes"
                    noOptionsMessage={''}
                    loadingMessage={''}
                    isLabelTop={true}
                    labelCol={0}
                    isMulti={true}
                  />
                  <Select
                    options={fractionOptions}
                    placeholder={locale.orders._selectFractions}
                    label={locale.orders._fractions}
                    name="fractions"
                    noOptionsMessage={''}
                    loadingMessage={''}
                    isLabelTop={true}
                    labelCol={0}
                    isMulti={true}
                  />
                  <Select
                    options={customerGroupOptions}
                    placeholder={locale.orders._selectCustomerGroup}
                    label={locale.orders._customerGroup}
                    name="customerGroups"
                    noOptionsMessage={''}
                    loadingMessage={''}
                    isLabelTop={true}
                    labelCol={0}
                    isMulti={true}
                  />
                </div>

                <div className="repetition-input-wrapper">
                  <Col sm={6}>
                    <Select
                      options={frequency}
                      placeholder={locale.orders._selectFrequency}
                      label={locale.orders._frequency}
                      name="frequency"
                      noOptionsMessage={''}
                      loadingMessage={''}
                      isLabelTop={true}
                      labelCol={0}
                      onChange={(name, option) =>
                        this.frequencyChange(name, option)
                      }
                    />
                  </Col>
                  <Col sm={6}>
                    {this.state.isRecurrentOrderSelected && (
                      <Select
                        options={daylist}
                        placeholder={locale.orders._selectDays}
                        label={locale.orders._days}
                        name="dayList"
                        noOptionsMessage={''}
                        loadingMessage={''}
                        isLabelTop={true}
                        labelCol={0}
                        isMulti={true}
                      />
                    )}
                  </Col>
                </div>

                <div className="date-input-wrapper">
                  <DatePicker
                    label={locale.orders._startDate}
                    name="date"
                    popupClass="date-picker-popup-above-modal"
                    isLabelTop={true}
                    labelCol={0}
                    col={true}
                    className="order-date-picker"
                  />
                  {this.state.isOrdinaryDateVisible && (
                    <DatePicker
                      label={locale.orders._ordinaryDate}
                      name="ordinarydate"
                      popupClass="date-picker-popup-above-modal"
                      isLabelTop={true}
                      labelCol={0}
                      col={true}
                      className="order-date-picker"
                    />
                  )}
                  {this.state.isExpireDateVisible && (
                    <DatePicker
                      label={locale.orders._expireDate}
                      name="expireDate"
                      popupClass="date-picker-popup-above-modal"
                      isLabelTop={true}
                      labelCol={0}
                      col={true}
                      className="order-date-picker"
                    />
                  )}
                  {this.state.isRecurrentOrderSelected && (
                    <DatePicker
                      label={locale.orders._expireDate}
                      name="repetationEndDate"
                      popupClass="date-picker-popup-above-modal"
                      isLabelTop={true}
                      labelCol={0}
                      col={true}
                    />
                  )}
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="light" onClick={() => onHide(false)}>
                {locale.routeOptimalizationWizard.addCustomerModal._closeButton}
              </Button>
              <Button
                variant="primary"
                type="submit"
                onClick={debounceEventHandler(handleSubmit, 250)}
              >
                {locale.routeOptimalizationWizard.addCustomerModal._addButton}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    );
  }
}
