import containerOrderConstants from './containerOrderConstants';
import ContainerOrderService from '../../../components/containerOrders/ContainerOrderService';
import cacheRefresherActions from '../cacheRefresher/cacheRefresherActions';
import cacheRefresherConstants from '../cacheRefresher/cacheRefresherConstants';
import loadingActions from '../loading/loadingActions';

function getContainerOrders(
  fromDate,
  toDate,
  ignoreCache = false,
  silent = false
) {
  return (dispatch) => {
    dispatch(
      cacheRefresherActions.addRequestInProgress(
        containerOrderConstants.GET_CONTAINERORDER
      )
    );
    if (!silent) {
      dispatch(
        loadingActions.addLoadingInProgress(
          containerOrderConstants.GET_CONTAINERORDER
        )
      );
    }

    dispatch(
      cacheRefresherActions.setCacheRefreshFilter({
        fromDate,
        toDate,
        requestType: cacheRefresherConstants.REQUEST_TYPE_CONTAINER_ORDERS,
      })
    );
    return ContainerOrderService.getContainerOrders(
      fromDate,
      toDate,
      ignoreCache
    )
      .then(
        (containerOrders) => dispatch(success(containerOrders)),
        (error) => dispatch(failure(JSON.stringify(error)))
      )
      .finally(() => {
        dispatch(
          cacheRefresherActions.removeRequestInProgress(
            containerOrderConstants.GET_CONTAINERORDER
          )
        );
        dispatch(
          loadingActions.removeLoadingInProgress(
            containerOrderConstants.GET_CONTAINERORDER
          )
        );
      });
  };

  function success(containerOrders) {
    return {
      type: containerOrderConstants.GET_CONTAINERORDER_SUCCESS,
      containerOrders,
    };
  }

  function failure(error) {
    return { type: containerOrderConstants.GET_CONTAINERORDER_FAILURE, error };
  }
}

function setFilter(filter) {
  return { type: containerOrderConstants.SET_CONTAINER_ORDER_FILTER, filter };
}

function setSelectedContainerOrderId(containerOrderId) {
  return {
    type: containerOrderConstants.SET_SELECTED_CONTAINERORDER,
    containerOrderId,
  };
}

export default {
  getContainerOrders,
  setFilter,
  setSelectedContainerOrderId,
};
