import { process } from '@progress/kendo-data-query';
import {
  Grid,
  GridColumn,
  GridColumnMenuFilter,
} from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { locale } from '../../common/localization/localizationService';
import CommandCell from '../userAdmin/CommandCell';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import ColumnMenu from '../shared/ColumnMenu';
import { getOrderStatus } from '../../utils/GeneralUtils';

export interface IOrderOverviewGridProps {
  data: Array<any>;
  clasName?: string;
  editOrder: (dataItem: any) => void;
  contractors: Array<any>;
}

export interface IOrderOverviewGridState {
  dataState: Record<string, any>;
}

const OrderOverviewGrid: React.FC<IOrderOverviewGridProps> = ({
  data,
  clasName,
  editOrder,
  contractors,
}) => {
  const [dataState, setDataState] = useState({
    sort: [{ dir: 'asc', field: 'orderId' }],
    take: 26,
    skip: 0,
  });

  const dataStateChange = (event: any) => {
    setDataState(event.data);
  };

  const getContractorField = (columnProps: any) => {
    const contractor = contractors?.find(
      (c) => c.value === columnProps.dataItem.contractorId
    );
    if (contractor) {
      return <td>{contractor.label}</td>;
    } else {
      return <td></td>;
    }
  };

  const UpdateOrderCell = (columnProps: any) => {
    return (
      <>
        <CommandCell {...columnProps} edit={editOrder} icon={faEdit} />
      </>
    );
  };

  const columnProps = (field: string) => {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: isColumnActive(field, dataState) ? 'active' : '',
    };
  };

  const isColumnActive = (field: string, dataState: any) => {
    return GridColumnMenuFilter.active(field, dataState.filter);
  };

  const formatStatus = (props: any) => {
    let statusString = '';
    if (props.dataItem.orderStatus) {
      statusString = getOrderStatus(props.dataItem.orderStatus.orderStatusId);
    }
    return (
      <>
        <td className="auto-resize-column ">{statusString}</td>
      </>
    );
  };

  const gridData = data.map((order) => {
    order.date = new Date(order.date);
    order.ordinaryDate = new Date(order.ordinaryDate);
    order.expires = new Date(order.expires);
    return order;
  });
  const result = process(gridData, dataState);
  return (
    <>
      <Grid
        {...dataState}
        className={`order-overview-grid ${clasName}`}
        data={result}
        selectedField="selected"
        pageable
        resizable
        pageSize={26}
        onDataStateChange={dataStateChange}
        sortable
      >
        <GridColumn
          {...columnProps('orderId')}
          title={locale.orderOverview._orderNumber}
          width={75}
          sortable
        />
        <GridColumn
          {...columnProps('date')}
          title={locale.orderOverview._date}
          format={'{0:' + locale.general._fullDateFormat + '}'}
          width={95}
          sortable
          filter="date"
        />
        <GridColumn
          {...columnProps('ordinaryDate')}
          title={locale.orderOverview._ordinaryDate}
          format={'{0:' + locale.general._fullDateFormat + '}'}
          width={100}
          sortable
          filter="date"
        />
        <GridColumn
          {...columnProps('expires')}
          title={locale.orderOverview._expires}
          format={'{0:' + locale.general._fullDateFormat + '}'}
          width={95}
          sortable
          filter="date"
        />
        <GridColumn
          {...columnProps('routeData.name')}
          title={locale.orderOverview._route}
          sortable
          width={800}
        />
        <GridColumn
          title={locale.orderOverview._fractions}
          cell={(gridProps: any) => (
            <td>
              {gridProps.dataItem.fractions &&
                gridProps.dataItem.fractions
                  .filter(
                    (frac: any, index: any, self: any) =>
                      index === self.findIndex((t: any) => t.name === frac.name)
                  ) // This ensures each name appears only once
                  .map((frac: any) => <div key={frac.name}>{frac.name}</div>)}
            </td>
          )}
        />
        <GridColumn
          {...columnProps('contractorId')}
          title={locale.orderOverview._contractor}
          cell={getContractorField}
        />
        <GridColumn
          {...columnProps('orderStatus.status')}
          title={locale.orderOverview._status}
          sortable
          cell={formatStatus}
        />
        <GridColumn width="40px" cell={UpdateOrderCell} />
      </Grid>
    </>
  );
};

export default OrderOverviewGrid;
