import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faFilter,
  faPhone,
} from '@fortawesome/free-solid-svg-icons';
import Spinner from '../shared/spinner/Spinner';
import { locale } from '../../common/localization/localizationService';
import ContainerOrdersGrid from './ContainerOrdersGrid';
import moment from 'moment';
import './container-orders.scss';
import AddVehicleDialog from './addVehicleDialog';
import RefreshButton from '../shared/refreshButton';
import EventBus from '../../common/EventBus';
import Select from 'react-select';

const intervalSelectOptions = [
  {
    value: 'thisWeek',
    label: locale.dateRangePicker._thisWeek,
  },
  {
    value: 'lastWeek',
    label: locale.dateRangePicker._lastWeek,
  },
  {
    value: 'thisMonth',
    label: locale.dateRangePicker._thisMonth,
  },
];

class ContainerOrders extends Component {
  constructor(props) {
    super(props);

    this.handleDateFilterChange = this.handleDateFilterChange.bind(this);
    this.setSelectedContainerOrder = this.setSelectedContainerOrder.bind(this);
  }

  state = {
    addVehicleDialog: {
      vehicles: null,
      containerOrderId: null,
      show: false,
      selectedCustomerId: null,
      selectedContractorId: null,
      selectedVehicleId: null,
    },
    isFilterVisible: true,
    selectedRouteOptions: [],
  };

  componentDidMount() {
    EventBus.emit('fullScreenChange', true);
    this.getContainerOrders();
    this.loadVehicles();
    this.props.loadRoutes();
  }

  componentWillUnmount() {
    EventBus.emit('fullScreenChange', false);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.containerOrderFilter.interval !==
      this.props.containerOrderFilter.interval
    ) {
      this.getContainerOrders();
    }
  }

  async loadVehicles() {
    await this.props.fetchVehicles(false);
  }

  handleDateFilterChange(selectedOption) {
    this.props.containerOrderActions.setFilter({
      ...this.props.containerOrderFilter,
      interval: selectedOption.value,
    });
  }

  getContainerOrders(ignoreCache = false) {
    const { containerOrderFilter, containerOrderActions } = this.props;

    let fromDate;
    let toDate;
    switch (containerOrderFilter.interval) {
      default:
      case 'thisWeek':
        fromDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
        break;
      case 'lastWeek':
        fromDate = moment().subtract(2, 'week').format('YYYY-MM-DD');
        toDate = moment().subtract(1, 'week').format('YYYY-MM-DD');
        break;
      case 'thisMonth':
        fromDate = moment().subtract(1, 'month').format('YYYY-MM-DD');
        break;
    }

    containerOrderActions.getContainerOrders(fromDate, toDate, ignoreCache);
  }

  setSelectedContainerOrder(row) {
    if (row.dataItem.orderId === this.props.selectedOrderId) {
      this.props.containerOrderActions.setSelectedContainerOrderId(null);
    } else {
      this.props.containerOrderActions.setSelectedContainerOrderId(
        row.dataItem.orderId
      );
    }
  }

  openAddVehicle(containerOrder) {
    this.setState({
      ...this.state,
      addVehicleDialog: {
        vehicles: this.props.vehicles,
        containerOrderId: containerOrder.orderId,
        show: true,
        selectedCustomerId: this.props.authentication.customerId,
        selectedContractorId: this.props.authentication.contractorId
          ? this.props.authentication.contractorId
          : -1,
        selectedVehicleId: containerOrder.vehicleId,
      },
    });
  }

  hideAddVehicleDialog(rerender = false) {
    this.setState({
      ...this.state,
      addVehicleDialog: {
        vehicles: null,
        containerOrderId: null,
        show: false,
        selectedCustomerId: null,
        selectedContractorId: null,
        selectedVehicleId: null,
      },
    });
    if (rerender) {
      this.getContainerOrders(true);
    }
  }

  refreshItems() {
    this.getContainerOrders(true);
  }

  showHideFilters() {
    this.setState({
      ...this.state,
      isFilterVisible: !this.state.isFilterVisible,
    });
  }

  filterByRoute(selectedOptions) {
    if (selectedOptions) {
      const selectedRoutes = selectedOptions.map((option) => option.value);
      this.props.containerOrderActions.setFilter({
        ...this.props.containerOrderFilter,
        routeIds: selectedRoutes,
      });
    }
  }

  filterByVehicle(selectedOptions) {
    if (selectedOptions) {
      const selectedVehicles = selectedOptions.map((option) => option.value);
      this.props.containerOrderActions.setFilter({
        ...this.props.containerOrderFilter,
        vehicles: selectedVehicles,
      });
    }
  }

  filterByDropdowns(containerOrders, containerOrderFilter) {
    let allOrder = containerOrders;
    if (containerOrderFilter.routeIds.length > 0) {
      const orders = allOrder.filter((order) => {
        return containerOrderFilter.routeIds.includes(order.name);
      });
      allOrder = orders;
    }
    if (containerOrderFilter.vehicles.length > 0) {
      const orders = allOrder.filter((order) => {
        return containerOrderFilter.vehicles.includes(order.vehicleId);
      });
      allOrder = orders;
    }
    return allOrder;
  }

  render() {
    const {
      containerOrders,
      isLoading,
      selectedOrderId,
      vehicles,
      routes,
      containerOrderFilter,
    } = this.props;

    const selectedDateRange =
      intervalSelectOptions.find((options) => {
        return options.value === this.props.containerOrderFilter.interval;
      }) || intervalSelectOptions[0];
    const routeOptions = routes.map((route) => {
      return { value: route.name, label: route.name };
    });

    const defaultRoutes = routeOptions.filter((route) => {
      return containerOrderFilter.routeIds.includes(route.label);
    });

    const vehicleOptions = vehicles.vehiclesList.map((vehicle) => {
      return { value: vehicle.vehicleId, label: vehicle.vehicleId };
    });

    const defaultVehicles = vehicleOptions.filter((vehicle) => {
      return containerOrderFilter.vehicles.includes(vehicle.value);
    });

    const filteredConteinerOrders = this.filterByDropdowns(
      containerOrders,
      containerOrderFilter
    );

    return (
      <div className="container-orders-view">
        <div className="row header-row">
          <div className="col">
            <h2>
              <FontAwesomeIcon icon={faPhone} />
              {locale.general._callOrder}
            </h2>
          </div>
          <div className="refresh-button-wrapper">
            <RefreshButton onClick={this.refreshItems.bind(this)} />
            <button
              className={`show-filters-button ${
                this.state.isFilterVisible ? 'filter-active' : ''
              }`}
              onClick={this.showHideFilters.bind(this)}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
          </div>
        </div>
        <div className="container-orders-grid-wrapper">
          {isLoading && <Spinner />}
          {containerOrders && (
            <ContainerOrdersGrid
              containerOrders={filteredConteinerOrders}
              onRowClick={this.setSelectedContainerOrder}
              addVehicle={this.openAddVehicle.bind(this)}
              selectedOrderId={selectedOrderId}
              className={this.state.isFilterVisible ? 'filter-open' : ''}
            />
          )}
          {!containerOrders && !isLoading && (
            <div className="d-flex flex-row justify-content-center align-items-center h-75">
              <div className="alert alert-warning" role="alert">
                {locale.containerOrders._noOrders}{' '}
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </div>
            </div>
          )}
          <div
            className={`call-order-filter ${
              this.state.isFilterVisible ? 'open' : ''
            }`}
          >
            <div className="filter-column">
              <label>{locale.containerOrders._dateRange}</label>
              <Select
                options={intervalSelectOptions}
                value={selectedDateRange}
                onChange={(e) => this.handleDateFilterChange(e)}
                className="input-field react-select"
              />
              <label>{locale.containerOrders._routes}</label>
              <Select
                options={routeOptions}
                value={defaultRoutes}
                onChange={(e) => this.filterByRoute(e)}
                isMulti
                placeholder={locale.containerOrders._selectRoutes}
                className="input-field react-select"
              />
              <label>{locale.containerOrders._vehicles}</label>
              <Select
                options={vehicleOptions}
                value={defaultVehicles}
                onChange={(e) => this.filterByVehicle(e)}
                isMulti
                placeholder={locale.containerOrders._selectVehicles}
                className="input-field react-select"
              />
            </div>
          </div>
        </div>
        {this.state.addVehicleDialog.show && (
          <AddVehicleDialog
            user={this.props.authentication}
            vehicles={vehicles.vehiclesList}
            containerOrderId={this.state.addVehicleDialog.containerOrderId}
            selectedVehicleId={this.state.addVehicleDialog.selectedVehicleId}
            show={this.state.addVehicleDialog.show}
            onHide={this.hideAddVehicleDialog.bind(this)}
          />
        )}
      </div>
    );
  }
}

ContainerOrders.propTypes = {
  containerOrderActions: PropTypes.object.isRequired,
  containerOrders: PropTypes.array,
  selectedOrderId: PropTypes.number,
  isLoading: PropTypes.bool,
  vehicles: PropTypes.object,
  fetchVehicles: PropTypes.func,
  authentication: PropTypes.object,
  loadRoutes: PropTypes.func,
  routes: PropTypes.array,
  containerOrderFilter: PropTypes.object,
};

export default ContainerOrders;
