import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import './ProfileMenu.scss';
import { Dispatch } from 'redux';
import { IUser } from '../../models';
import React, { Component, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMask,
  faUserCog,
  faSignOutAlt,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import EventBus from '../../common/EventBus';
import { Link } from 'react-router-dom';
import routes from '../../common/routes';
import LocalizationService, {
  locale,
} from '../../common/localization/localizationService';

export interface IProfileMenuProps extends WithAuth0Props {
  authentication: IUser;
  logout: (auth0: any) => (dispatch: Dispatch) => Promise<void>;
}

export interface IProfileMenuState {
  isMenuOpen: boolean;
  isLanguageMenuOpen: boolean;
  languages: Array<ILocaleObject>;
}

export interface ILocaleObject {
  label: string;
  langCode: string;
}

class ProfileMenu extends Component<IProfileMenuProps, IProfileMenuState> {
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  constructor(props: any) {
    super(props);
    this.checksMenuElements = this.checksMenuElements.bind(this);
  }
  public readonly state: IProfileMenuState = {
    isMenuOpen: false,
    isLanguageMenuOpen: false,
    languages: [
      { label: 'Norsk', langCode: 'nb' },
      { label: 'English', langCode: 'en' },
      { label: 'Svenska', langCode: 'se' },
      { label: 'Deutsch', langCode: 'de' },
      { label: 'Nederlands', langCode: 'nl' },
      { label: 'Hrvatski', langCode: 'hr' },
      { label: 'Română', langCode: 'ro' },
    ],
  };
  public componentDidMount() {
    this.setState({ ...this.state, isMenuOpen: false });
    EventBus.subscribe('onProfileMenu', this.onProfileMenu);
    EventBus.subscribe('onLogout', this.logout);
  }

  public componentWillUnmount() {
    EventBus.unsubscribe('onProfileMenu');
  }

  private logout = (onUnauthorized: boolean) => {
    if (onUnauthorized) {
      this.props.logout(this.props.auth0);
    }
  };

  private onProfileMenu = (data: any) => {
    this.setState({ ...this.state, isMenuOpen: data.isOpen });
    if (data.isOpen) {
      setTimeout(() => {
        document
          .getElementById('profileMenuOverlay')
          .addEventListener('click', this.closeProfileMenu);
        const mainElements = document.querySelectorAll('.main-element');
        mainElements.forEach((element) => {
          element.addEventListener('mouseenter', this.checksMenuElements);
        });
      }, 50);
    }
  };

  private checksMenuElements(event: any) {
    const element = event.currentTarget;
    if (element.classList.contains('languages')) {
      this.toggleLangMenu(true);
    } else {
      this.toggleLangMenu(false);
    }
  }

  private toggleLangMenu(toggle: boolean) {
    this.setState({ ...this.state, isLanguageMenuOpen: toggle });
  }

  private closeProfileMenu = (event: any) => {
    if (event.target.className !== 'profile-menu-element selected') {
      setTimeout(() => {
        this.setState({
          ...this.state,
          isMenuOpen: false,
          isLanguageMenuOpen: false,
        });
      }, 50);
    }
  };
  private setLanguage(language: string, selectedLang: string): void {
    if (language !== selectedLang) {
      LocalizationService.setLocale(language, this.props.auth0.user.email);
      window.location.reload();
    }
  }
  public render(): ReactNode {
    const auth0 = this.props.auth0;
    const { isMenuOpen, languages } = this.state;
    const { isAdmin, isLocalAdmin } = this.props.authentication;

    const selectedLang = LocalizationService.getCountryCode(auth0.user.email);

    const className = isAdmin || isLocalAdmin ? 'admin-profile-menu ' : '';
    return (
      <>
        {isMenuOpen && (
          <div id="profileMenuOverlay">
            <div className={'profile-menu-wrapper'}>
              {this.state.isLanguageMenuOpen && (
                <div className="language-selector">
                  {languages.map(({ label, langCode }) => (
                    <span
                      key={langCode}
                      className={`profile-menu-element ${
                        selectedLang === langCode ? 'selected' : ''
                      }`}
                      onClick={() => this.setLanguage(langCode, selectedLang)}
                    >
                      {label}
                    </span>
                  ))}
                </div>
              )}
              <div className={className + 'profile-menu'}>
                <div className="profile-menu-element main-element languages">
                  <FontAwesomeIcon icon={faChevronLeft} />{' '}
                  {locale.general._selectLanguage}
                </div>
                {(isAdmin || isLocalAdmin) && (
                  <Link
                    to={routes.administration.base}
                    className="profile-menu-element main-element"
                  >
                    <FontAwesomeIcon icon={faUserCog} />
                    {locale.general._administration}
                  </Link>
                )}
                {isAdmin && (
                  <Link
                    to={routes.impersonationManagerPath}
                    className="profile-menu-element main-element"
                  >
                    <FontAwesomeIcon icon={faMask} />
                    <span>{locale.general._impersonationManager}</span>
                  </Link>
                )}
                <div
                  className="profile-menu-element main-element"
                  onClick={() => this.props.logout(auth0)}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  {locale.general._logOut}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withAuth0(ProfileMenu);
