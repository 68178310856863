import * as yup from 'yup';
import moment from 'moment';
import { locale } from './localization/localizationService';

const validationMessages = {
  mixed: {
    required: ({ path }) => `${path} ${locale.general.validation._isRequired}`,
  },
  string: {
    max: ({ max, path }) =>
      `${path} ${locale.general.validation._stringMaxCharA} ${max} ${locale.general.validation._stringMaxCharB}`,
    email: ({ path }) => `${path} ${locale.general.validation._emailNotValid}`,
  },
  number: {
    max: ({ max, path }) =>
      `${path} ${locale.general.validation._lessOrEqual} ${max}`,
    min: ({ min, path }) =>
      `${path} ${locale.general.validation._greaterOrEqual} ${min}`,
  },
  date: {
    max: ({ max, path }) =>
      `${path} ${locale.general.validation._laterThan} ${moment(max).format(
        'DD.MM.YYYY'
      )}`,
    min: ({ min, path }) =>
      `${path} ${locale.general.validation._earlierThan} ${moment(min).format(
        'DD.MM.YYYY'
      )}`,
  },
};

yup.setLocale(validationMessages);

export default yup;
