import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form } from 'react-bootstrap';
import { locale } from '../../common/localization/localizationService';
import { Formik } from 'formik';
import yup from '../../common/validation';
import UserTextField from './UserTextField';
import UserCheckBox from './UserCheckBox';
import UserContractorSelect from './UserContractorSelect';
import UserModuleSelect from './UserModuleSelect';

function getSchema(isNew) {
  return yup.object({
    userName: yup
      .string()
      .required()
      .email()
      .max(50)
      .label(locale.userAdmin._userName),
    system: yup.string().required().max(5).label(locale.userAdmin._system),
    localAdmin: yup.bool(),
    admin: yup.bool(),
    contractor: yup.bool(),
    selectedContractor: yup
      .object()
      .when('contractor', {
        is: true,
        then: yup.object().nullable().required(),
        otherwise: yup.object().nullable(),
      })
      .label(locale.userAdmin._contractor),
    selectedModules: yup.array().nullable(),
  });
}

const emptyUser = {
  userName: '',
  system: '',
  localAdmin: false,
  admin: false,
  contractor: false,
};

function getInitialValues(user) {
  const initial = { ...user };
  Object.keys(emptyUser).forEach((key) => {
    if (initial[key] == null) {
      initial[key] = emptyUser[key];
    }
  });

  return initial;
}

function getModuleOptions(modules) {
  return modules.map((module) => ({
    label: module.moduleName,
    value: module.moduleId,
  }));
}

function getContractorOptions(contractors) {
  return contractors.map((contractor) => ({
    label: contractor.contractorName,
    value: contractor.contractorId,
  }));
}

function onSubmit(modules, save, isNew, userToSave) {
  const { selectedContractor, selectedModules, ...user } = userToSave;

  user.contractorId = selectedContractor && selectedContractor.value;
  user.modules =
    selectedModules &&
    modules.filter((m) =>
      selectedModules.some((sm) => sm.value === m.moduleId)
    );

  save(user, isNew);
}

function handleIsContractorChanged(setFieldValue, setFieldTouched, event) {
  if (!event.target.checked) {
    setFieldValue('selectedContractor', null);
    setFieldTouched('selectedContractor', false, false);
  }
}

export default function UserEditDialog({
  show,
  isNew,
  user,
  onHide,
  modules,
  contractors,
  save,
}) {
  if (!show) {
    return null;
  }

  const title = isNew
    ? locale.userAdmin._userAddDialogTitle
    : locale.userAdmin._userEditDialogTitle;

  const moduleOptions = getModuleOptions(modules);
  const selectedModules =
    moduleOptions.filter(
      (option) =>
        user.modules && user.modules.some((m) => m.moduleId === option.value)
    ) || null;

  const contractorOptions = getContractorOptions(contractors);
  const selectedContractor =
    contractorOptions.find((option) => user.contractorId === option.value) ||
    null;

  return (
    <Formik
      validationSchema={getSchema(isNew)}
      onSubmit={onSubmit.bind(null, modules, save, isNew)}
      initialValues={{
        ...getInitialValues(user),
        selectedContractor,
        selectedModules,
      }}
    >
      {({ setFieldValue, handleSubmit, setFieldTouched }) => (
        <Modal show={show} onHide={onHide} className="user-edit-modal">
          <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate onSubmit={handleSubmit}>
              <UserTextField name="userName" disabled={!isNew} />
              <UserTextField name="system" />
              <UserCheckBox name="localAdmin" />
              <UserCheckBox
                name="contractor"
                onChange={handleIsContractorChanged.bind(
                  null,
                  setFieldValue,
                  setFieldTouched
                )}
              />
              <UserContractorSelect contractorOptions={contractorOptions} />
              <UserModuleSelect moduleOptions={moduleOptions} />
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              {locale.userAdmin._cancel}
            </Button>
            <Button type="submit" variant="primary" onClick={handleSubmit}>
              {isNew ? locale.userAdmin._add : locale.userAdmin._update}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}

UserEditDialog.propTypes = {
  user: PropTypes.object,
  isNew: PropTypes.bool,
  show: PropTypes.bool.isRequired,
  save: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  modules: PropTypes.array.isRequired,
  contractors: PropTypes.array.isRequired,
};
