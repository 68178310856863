import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

function HeaderStep({ title, buttonText, buttonClick, disabled, icon }) {
  const stepElementClasses = classNames('step-element', {
    active: !disabled,
  });

  return (
    <div className={stepElementClasses}>
      <div className="step-indicator">
        <div className="step-bubble">
          <FontAwesomeIcon icon={icon} />
        </div>
      </div>
      <p className="step-title">{title}</p>
      <div className="header-step-button-row">
        <button
          onClick={buttonClick}
          className="btn btn btn-outline-dark btn-slim step-action"
          disabled={disabled}
        >
          {buttonText}
        </button>
      </div>
    </div>
  );
}

HeaderStep.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonClick: PropTypes.func.isRequired,
  button2Text: PropTypes.string,
  button2Click: PropTypes.func,
  disabled: PropTypes.bool,
  icon: PropTypes.object.isRequired,
};

HeaderStep.defaultProps = {
  title: '',
  buttonText: '',
  button2Text: null,
  buttonClick: () => {},
  icon: faTimesCircle,
};

export default HeaderStep;
