import React from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../common/localization/localizationService';
import Select from 'react-select';

function ReportSelector({ selectedReport, handleChange, reports }) {
  const reportOptions = reports.map((report) => ({
    value: report.reportId,
    label: report.reportName,
  }));

  const selectedOption = reportOptions.find(
    (option) => option.value === selectedReport
  );

  const styles = {
    menu: (css) => ({
      ...css,
      width: '402px',
    }),
  };

  return (
    <div className="form-group row">
      <label className="col col-form-label first-label" htmlFor="report-select">
        {locale.reports._selectReport}:
      </label>
      <Select
        options={reportOptions}
        value={selectedOption}
        onChange={(selected) => handleChange(selected.value)}
        className={'col report-select'}
        styles={styles}
      />
    </div>
  );
}

ReportSelector.propTypes = {
  selectedReport: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  reports: PropTypes.array,
};

ReportSelector.defaultProps = {
  reports: [],
};

export default ReportSelector;
