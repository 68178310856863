import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import routes from '../../common/routes';
import ZoomManager from '../zoomManager';
import { IRootState } from '../../redux/state';
import { Location } from 'history';
import { IRouteStop, ISelectedOrder } from '../../models';

export interface IRouteZoomManagerProps {
  selectedOrder: ISelectedOrder;
  selectedRouteStop: IRouteStop;
  location: Location;
}

export class RouteZoomManager extends Component<IRouteZoomManagerProps> {
  public mapRouteStopToCoordinates(
    routeStop: IRouteStop
  ): Array<[number, number]> {
    return routeStop && routeStop.routeLine
      ? [[routeStop.routeLine.degLat, routeStop.routeLine.degLong]]
      : [];
  }

  public mapOrderToCoordinates(order: ISelectedOrder): Array<[number, number]> {
    return order && order.generatedRoutes
      ? order.generatedRoutes.reduce(
          (memo, stop) => [...memo, ...this.mapRouteStopToCoordinates(stop)],
          []
        )
      : [];
  }

  public ordersViewActive(location: Location): boolean {
    return location.pathname.startsWith(routes.dashboard.base);
  }

  public routeStopsViewActive(location: Location): boolean {
    return !!location.pathname.match(routes.orderDetails.pattern);
  }

  public orderToIdString(order: ISelectedOrder): string {
    return order ? [order.orderId, order.customerId].join('#') : '';
  }

  public routeStopToIdString(routeStop: IRouteStop): string {
    if (!routeStop) {
      return '';
    }

    return [
      routeStop.orderId,
      routeStop.customerId,
      routeStop.routeLineId,
      routeStop.paSystem,
      routeStop.agreementLineId,
    ].join('#');
  }

  public render(): ReactNode {
    const { location, selectedOrder, selectedRouteStop } = this.props;
    return (
      <>
        <ZoomManager
          name="SELECTED_ORDER"
          isActive={this.ordersViewActive(location)}
          objectId={this.orderToIdString(selectedOrder)}
          priority={10}
          positions={this.mapOrderToCoordinates(selectedOrder)}
        />
        <ZoomManager
          name="SELECTED_ROUTE_STOP"
          isActive={this.routeStopsViewActive(location)}
          objectId={this.routeStopToIdString(selectedRouteStop)}
          priority={20}
          positions={this.mapRouteStopToCoordinates(selectedRouteStop)}
        />
      </>
    );
  }
}

function mapStateToProps(state: IRootState) {
  return {
    selectedOrder: state.order.selected,
    selectedRouteStop: state.orderDetails.selectedRouteStop,
    location: state.router.location,
  };
}

export default connect(mapStateToProps)(RouteZoomManager);
