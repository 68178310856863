export const orderConstants = {
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_ORDERHEADS_LOADED: 'GET_ORDERS_ORDERHEADS_LOADED',
  GET_ORDERS_DETAILS_LOADED: 'GET_ORDERS_DETAILS_LOADED',
  GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',

  GET_ORDER_DETAILS: 'GET_ORDER_DETAILS',
  GET_ORDER_DETAILS_SUCCESS: 'GET_ORDER_DETAILS_SUCCESS',
  GET_ORDER_DETAILS_FAILURE: 'GET_ORDER_DETAILS_FAILURE',

  SET_SELECTED_ORDER: 'SET_SELECTED_ORDER',

  GET_ALL_FRACTIONS_REQUEST: 'GET_ALL_FRACTIONS_REQUEST',
  GET_ALL_FRACTIONS_SUCCESS: 'GET_ALL_FRACTIONS_SUCCESS',
  GET_ALL_FRACTIONS_FAILED: 'GET_ALL_FRACTIONS_FAILED',

  GET_ORDER_OVERVIEW: 'GET_ORDER_OVERVIEW',
  GET_ORDER_OVERVIEW_SUCCESS: 'GET_ORDER_OVERVIEW_SUCCESS',
  GET_ORDER_OVERVIEW_FAILURE: 'GET_ORDER_OVERVIEW_FAILURE',

  SET_ORDER_OVERVIEW_FILTER: 'SET_ORDER_OVERVIEW_FILTER',

  GET_ORDER_STATUSES: 'GET_ORDER_STATUSES',
  GET_ORDER_STATUSES_SUCCES: 'GET_ORDER_STATUSES_SUCCESS',
  GET_ORDER_STATUSES_FAILRE: 'GET_ORDER_STATUSES_FAILRE',
};
