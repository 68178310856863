import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import VehicleSettings from './VehicleSettings';
import { fetchVehicles } from '../../redux/modules/vehicles/vehiclesCreators';
import { IVehiclesState } from '../../redux/modules/vehicles/vehiclesState';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchVehicles: fetchVehicles,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    authentication: state.authentication as unknown as IUser,
    vehiclesList: state.vehicles.vehiclesList as IVehiclesState,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(VehicleSettings);
