import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import ContractorAdmin from './ContractorAdmin';
import contractorActions from '../../redux/modules/contractor/contractorActions';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getContractors: contractorActions.getContractors,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    authentication: state.authentication as unknown as IUser,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(ContractorAdmin);
