import React, { Component, ReactNode } from 'react';
import { IDepot } from '../../models/Depot.model';
import DepotsService from '../../services/DepotsService';
import { Toastr } from '../../utils/Toastr';
import { Formik } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import TextField from '../shared/formikFields/TextField';
import { debounceEventHandler } from '../shared/submitFormUtility';
import yup from '../../common/validation';
import { Spinner2 } from '../shared/spinner2';
import { locale } from '../../common/localization/localizationService';

export interface IDepotDialoglProps {
  customerId: number;
  show: boolean;
  depot?: IDepot;
  onHide: (renderer: boolean) => void;
}

export interface IDepotDialoglState {
  isLoading: boolean;
}

export default class DepotDialog extends Component<
  IDepotDialoglProps,
  IDepotDialoglState
> {
  public readonly state: IDepotDialoglState = {
    isLoading: false,
  };

  private async onSubmit(depot: IDepot, isNew: boolean): Promise<void> {
    depot.latitude = this.convertStringsToNumber(depot.latitude);
    depot.longitude = this.convertStringsToNumber(depot.longitude);
    if (isNew) {
      try {
        this.setState({ ...this.state, isLoading: true });
        await DepotsService.createNewDepot(depot);
        Toastr.success(locale.depots._depotCreated);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.setState({ ...this.state, isLoading: false });
        this.props.onHide(true);
      }
    } else {
      try {
        this.setState({ ...this.state, isLoading: true });
        await DepotsService.updateDepot(depot);
        Toastr.success(locale.depots._depotUpdated);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.setState({ ...this.state, isLoading: false });
        this.props.onHide(true);
      }
    }
  }

  private convertStringsToNumber(input: number | string) {
    if (typeof input === 'string' && input.includes(',')) {
      input = input.replace(',', '.');
    }
    return Number(input);
  }

  private newDepot(): IDepot {
    const newDepot: IDepot = {
      customerId: this.props.customerId,
      depotId: undefined,
      description: '',
      latitude: undefined,
      longitude: undefined,
    };
    return newDepot;
  }

  private getSchema() {
    return yup.object({
      description: yup.string().required().label(locale.depots._description),
      latitude: yup
        .number()
        .transform((value: any, originalValue: any) => {
          if (typeof originalValue === 'string') {
            return parseFloat(originalValue.replace(',', '.'));
          }
          return value;
        })
        .min(-90)
        .max(90)
        .required()
        .label(locale.depots._latitude),
      longitude: yup
        .number()
        .transform((value: any, originalValue: any) => {
          if (typeof originalValue === 'string') {
            return parseFloat(originalValue.replace(',', '.'));
          }
          return value;
        })
        .min(-180)
        .max(180)
        .required()
        .label(locale.depots._longitude),
    });
  }

  public render(): ReactNode {
    const isNew = !this.props.depot;
    const depot = this.props.depot || this.newDepot();
    const { show, onHide } = this.props;
    const { isLoading } = this.state;
    return (
      <>
        <Formik
          onSubmit={(depot: IDepot) => this.onSubmit(depot, isNew)}
          initialValues={{ ...depot }}
          validationSchema={this.getSchema()}
        >
          {({ handleSubmit }) => (
            <Modal
              show={show}
              onHide={() => onHide(false)}
              className="depot-dialog"
            >
              <Spinner2 show={isLoading} />
              <Modal.Header closeButton>
                <Modal.Title>
                  {isNew ? locale.depots._createNew : locale.depots._update}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                  <TextField
                    label={locale.depots._description}
                    name="description"
                    placeholder={locale.depots._description}
                  />
                  <TextField
                    label={locale.depots._latitude}
                    name="latitude"
                    placeholder={locale.depots._latitude}
                  />
                  <TextField
                    label={locale.depots._longitude}
                    name="longitude"
                    placeholder={locale.depots._longitude}
                  />
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide(false)}>
                  {locale.depots._cancelButton}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  onClick={debounceEventHandler(handleSubmit, 250)}
                >
                  {locale.depots._submit}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}
