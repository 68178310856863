import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { locale } from '../../../common/localization/localizationService';
import HeaderStep from './HeaderStep';
import { faFile, faFlask, faSave } from '@fortawesome/free-solid-svg-icons';
import './stepsHeader.scss';

function StepsHeader({
  onSelectFile,
  loadOrders,
  analyseEnabled,
  onAnalyse,
  saveEnabled,
  onSave,
}) {
  const url = new URL(window.location.href);
  const addNewParam = url.searchParams.get('addNew');
  const addNew = addNewParam === 'true';

  useEffect(() => {
    if (!addNew) {
      loadOrders();
    } else {
      onSelectFile();
    }
  }, [addNew]);

  return (
    <div className="steps-header">
      <HeaderStep
        title={
          addNew
            ? locale.routeOptimalizationWizard._uploadFileStep
            : locale.routeOptimalizationWizard._avaibleOrders
        }
        buttonText={
          addNew
            ? locale.routeOptimalizationWizard._uploadFileButton
            : locale.routeOptimalizationWizard._loadOrders
        }
        buttonClick={addNew ? onSelectFile : loadOrders}
        icon={faFile}
        button2Text={locale.routeOptimalizationWizard._loadInButton}
        button2Click={loadOrders}
      />
      <HeaderStep
        title={locale.routeOptimalizationWizard._analyseStep}
        buttonText={locale.routeOptimalizationWizard._analyseButton}
        buttonClick={onAnalyse}
        disabled={!analyseEnabled}
        icon={faFlask}
        isButtonVisible={true}
      />
      <HeaderStep
        title={locale.routeOptimalizationWizard._saveStep}
        buttonText={locale.routeOptimalizationWizard._saveButton}
        buttonClick={onSave}
        disabled={!saveEnabled}
        icon={faSave}
        isButtonVisible={true}
      />
    </div>
  );
}

StepsHeader.propTypes = {
  onSelectFile: PropTypes.func.isRequired,
  onAnalyse: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  analyseEnabled: PropTypes.bool,
  saveEnabled: PropTypes.bool,
};

StepsHeader.defaultProps = {
  onSelectFile: () => {},
  loadOrders: () => {},
  onAnalyse: () => {},
  onSave: () => {},
};

export default StepsHeader;
