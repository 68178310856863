import { Dispatch, bindActionCreators } from 'redux';
import NewOrderDialog from './NewOrderModal';
import contractorActions from '../../../redux/modules/contractor/contractorActions';
import { IRootState } from '../../../redux/state';
import { IUser } from '../../../models';
import { loadRoutes } from '../../../redux/modules/route/routeCreators';
import { connect } from 'react-redux';
import { getRoutes } from '../../../redux/modules/route/routeSelectors';
import { loadCustomerGroups } from '../../../redux/modules/newOrder/newOrderCreators';
import {
  getCustomerGroups,
  getFractions,
} from '../../../redux/modules/newOrder/newOrderSelector';
import { loadFractions } from '../../../redux/modules/fractions/fractionsCreators';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getContractors: contractorActions.getContractors,
      loadRoutes,
      loadCustomerGroups,
      loadFractions,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    authentication: state.authentication as unknown as IUser,
    contractors: state.contractor.contractors,
    routes: getRoutes(state),
    customerGroups: getCustomerGroups(state),
    fractions: getFractions(state),
  };
}

export type INewOrderDialogProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(NewOrderDialog);
