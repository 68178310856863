import React, { Component, ReactNode, RefObject } from 'react';
import { Map, MapLayer, ZoomControl } from 'react-leaflet';
import { CRS } from 'proj4leaflet';
import { Dropdown } from 'react-bootstrap';
import { Location } from 'history';
import {
  LatLngBoundsExpression,
  LatLngExpression,
  LeafletEvent,
  LatLngTuple,
  LatLngBounds,
} from 'leaflet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLayerGroup,
  faMap,
  faPlaneDeparture,
  faMapPin,
  faRoute,
} from '@fortawesome/free-solid-svg-icons';
import { MultiSelect } from 'primereact/multiselect';
import classNames from 'classnames';

import { LeafletMapSettings } from './../../sharedConfig';
import WMSService from '../../services/WMSService';
import { locale } from '../../common/localization/localizationService';
import RouteAdminLayer from '../routeAdminLayer';
import SelectedRouteLayer from '../selectedRouteLayer';
import TrackingLineLayer from '../trackingLineLayer';
import SelectedServiceListLayer from '../selectedServiceListLayer';
import VehicleTrackingLayer from '../vehicleTrackingLayer';
import ImportedRoutesLayer from '../routeOptimalizationWizard/importedRoutesLayer';
import WorklistsLayer from '../routeOptimalizationWizard/worklistsLayer';
import CacheRefreshToggle from '../cacheRefresher/CacheRefreshControls';
import RouteAdminMenu from '../routeAdminMenu';
import { ZoomableObjectTypes } from './MapWrapperConstants';
import TiledMapLayer from './TiledMapLayer';
import DynamicMapLayer from './DynamicMapLayer';
import WMSLayer from '../WMSLayer';
import { MapUtility } from './MapUtility';
import { MapControl } from './MapControl';
import { mapServiceUrls, selectedRegion, mapSettings } from '../../appConfig';
import { ICompanySettings, MapType } from '../../models';
import TrackingLineLegend from '../trackingLineLayer/TrackingLineLegend';
import TrackInfoLayer from '../trackInfoLayer';
import TrackInfoLineLayer from '../trackInfoLineLayer';

import 'leaflet/dist/leaflet.css';
import './map.scss';
import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';

export enum MapLayers {
  Street = 'Street',
  Satellite = 'Satellite',
  Property = 'Property',
  TrackingLine = 'TrackingLine',
  WMS = 'WMS',
  TrackInfo = 'TrackInfo',
  TrackInfoLine = 'TrackInfoLine',
}
export interface IMapWrapperProps {
  vehicles: Array<any>;
  location: Location;
  selectedOrder: any;
  selectedServiceList: Array<any>;
  selectedServiceOrder: any;
  activeObject: any;
  token: string;
  ref?: RefObject<MapWrapper>;
  companySettings: ICompanySettings;
}
export interface IMapWrapperState {
  clusteringActive: boolean;
  zoomLevel: number;
  bounds: LatLngBounds;
  availableWMSLayers: Array<{
    layerDisplayName: string;
    layerValue: string;
  }>;
  selectedWMSLayers: Array<{ layerDisplayName: string; layerValue: string }>;
  layerVisibility: {
    Street: boolean;
    Satellite: boolean;
    Property: boolean;
    TrackingLine: boolean;
    WMS: boolean;
    TrackInfo: boolean;
    TrackInfoLine: boolean;
  };
  isLayersLoaded: boolean;
}
export class MapWrapper extends Component<IMapWrapperProps, IMapWrapperState> {
  public static CRS =
    mapSettings.mapType === MapType.UTM33N
      ? new CRS(
          LeafletMapSettings.projection.code,
          LeafletMapSettings.projection.proj4def,
          LeafletMapSettings.projection.options
        )
      : null;

  public readonly mapLayerRefs: Record<MapLayers, RefObject<MapLayer>> = {
    [MapLayers.Street]: React.createRef(),
    [MapLayers.Satellite]: React.createRef(),
    [MapLayers.Property]: React.createRef(),
    [MapLayers.TrackingLine]: React.createRef(),
    [MapLayers.WMS]: React.createRef(),
    [MapLayers.TrackInfo]: React.createRef(),
    [MapLayers.TrackInfoLine]: React.createRef(),
  };

  public readonly mapRef: RefObject<Map> = React.createRef();

  private zoomTimeout: ReturnType<typeof setTimeout>;

  public static getClusteringIconClass(isClusteringActive: boolean): string {
    return classNames('k-icon', {
      'k-i-poi-group': isClusteringActive,
      'k-i-poi': !isClusteringActive,
    });
  }

  public readonly state: IMapWrapperState = {
    clusteringActive: true,
    zoomLevel: LeafletMapSettings.center.zoom,
    bounds: null,
    availableWMSLayers: [],
    selectedWMSLayers: [],
    layerVisibility: {
      Street: true,
      Satellite: false,
      Property: false,
      TrackingLine: false,
      WMS: false,
      TrackInfo: false,
      TrackInfoLine: false,
    },
    isLayersLoaded: false,
  };

  public async componentDidMount(): Promise<void> {
    const { activeObject } = this.props;

    if (this.mapRef.current?.leafletElement != null) {
      this.handleZoomToActiveObject(activeObject);
    }

    this.loadLayerSettings();
  }

  public componentDidUpdate(prevProps: IMapWrapperProps): void {
    const { activeObject } = this.props;
    if (
      !this.state.isLayersLoaded &&
      this.props.companySettings?.WMSLayer?.enabled
    ) {
      this.setState({ ...this.state, isLayersLoaded: true });
      this.loadLayerSettings();
    }
    if (
      activeObject !== prevProps.activeObject &&
      (activeObject == null ||
        prevProps.activeObject == null ||
        activeObject.name !== prevProps.activeObject.name ||
        !activeObject.ignoreUpdate)
    ) {
      this.handleZoomToActiveObject(activeObject);
    }
  }

  private async loadLayerSettings(): Promise<void> {
    const isWmsLayerEnabled =
      this.props.companySettings?.WMSLayer?.enabled ?? false;

    const availableWMSLayers = isWmsLayerEnabled
      ? await WMSService.getWMSLayers(
          mapServiceUrls.wms + '/?request=GetCapabilities'
        )
      : null;

    const filteredAvailableWMSLayers = isWmsLayerEnabled
      ? WMSService.layerDisplayNameFiltering(
          availableWMSLayers,
          this.props.companySettings?.WMSLayer?.nameStartsWithFilter
        ) ?? []
      : null;

    const selectedWMSLayers = isWmsLayerEnabled
      ? WMSService.layerDisplayNameFiltering(
          this.props.companySettings?.WMSLayer?.preSelectedWMSLayers?.split(
            ','
          ),
          this.props.companySettings?.WMSLayer?.nameStartsWithFilter
        ) ?? []
      : null;

    const preSelectedLayersExisting = isWmsLayerEnabled
      ? selectedWMSLayers.filter((layer) => {
          const found = filteredAvailableWMSLayers.find(
            (availableLayer) => availableLayer.layerValue === layer.layerValue
          );
          return found !== undefined;
        })
      : null;

    this.setState({
      ...this.state,
      availableWMSLayers: filteredAvailableWMSLayers,
      selectedWMSLayers: preSelectedLayersExisting,
      layerVisibility: {
        ...this.state.layerVisibility,
        WMS: this.props.companySettings?.WMSLayer?.visible ?? false,
        TrackingLine:
          this.props.companySettings?.trackingLineLayer?.visible ?? false,
        TrackInfo: this.props.companySettings?.trackInfoLayer?.visible ?? false,
        TrackInfoLine:
          this.props.companySettings?.trackInfoLineLayer?.visible ?? false,
      },
    });
  }

  public invalidateSize(): void {
    this.mapRef.current?.leafletElement.invalidateSize();
  }

  public toggleClustering(): void {
    this.setState((state) => ({ clusteringActive: !state.clusteringActive }));
  }

  public returnToInitialPosition(): void {
    const center: LatLngExpression = [
      LeafletMapSettings.center.lat,
      LeafletMapSettings.center.lng,
    ];
    this.mapRef.current?.leafletElement.setView(
      center,
      LeafletMapSettings.center.zoom
    );
  }

  public setZoomLevel(event: LeafletEvent): void {
    if (!event?.target?.getZoom) return;
    this.setState({ zoomLevel: event.target.getZoom() });
  }

  public zoomTo(lat: number, lng: number, zoomLevel: number): void {
    if (MapUtility.isInRegion(lat, lng, selectedRegion)) {
      this.mapRef.current?.leafletElement.setView([lat, lng], zoomLevel);
    }
  }

  public handleZoomToActiveObject(activeObject: {
    zoomLevel: number;
    type: string;
    point: LatLngTuple;
    bounds: LatLngBoundsExpression;
  }): void {
    if (this.zoomTimeout) {
      clearTimeout(this.zoomTimeout);
    }

    this.zoomTimeout = setTimeout(() => {
      this.zoomTimeout = null;
      if (!activeObject) {
        this.returnToInitialPosition();
      } else {
        switch (activeObject.type) {
          case ZoomableObjectTypes.BOUNDS: {
            return this.mapRef.current?.leafletElement.fitBounds(
              activeObject.bounds
            );
          }
          case ZoomableObjectTypes.POINT: {
            const { point, zoomLevel } = activeObject;
            const [lat, lng] = point as number[];
            return this.zoomTo(lat, lng, zoomLevel);
          }
        }
      }
    }, 500);
  }

  public getWMSSelectorRightPositionPx(): number {
    if (
      this.state.layerVisibility?.WMS &&
      this.props.companySettings?.WMSLayer?.enabled
    ) {
      /** @todo */
      // if (this.state.layerVisibility?.TrackInfo) {
      //   if (this.state.trackInfo?.isCustomDateSelectorEnabled) return 335;
      //   return 125;
      // }
    }
    return 70;
  }

  public toggleLayerVisibility(
    selectedMapLayer: MapLayers | Array<MapLayers>
  ): void {
    const layerVisibility = { ...this.state.layerVisibility };
    const layers = Array.isArray(selectedMapLayer)
      ? selectedMapLayer
      : [selectedMapLayer];

    this.tempDisableDoubleClickZoom(250);

    layers.forEach((layer) => {
      layerVisibility[layer] = layerVisibility[layer] ? false : true;
      this.toggleMapLayer(layer);
    });

    this.setState({
      ...this.state,
      layerVisibility: layerVisibility,
    });
  }

  public tempDisableDoubleClickZoom(intervalInMillisec: number): void {
    const map = this.mapRef.current?.leafletElement;
    map.doubleClickZoom.disable();
    setTimeout(() => {
      map.doubleClickZoom.enable();
    }, intervalInMillisec);
  }

  public toggleMapLayer(selectedMapLayer: MapLayers): void {
    if (this.mapRef) {
      const map = this.mapRef.current?.leafletElement;
      const layer =
        this.mapLayerRefs[selectedMapLayer]?.current?.leafletElement;
      if (!layer) return;
      if (this.state.layerVisibility[selectedMapLayer]) {
        map.removeLayer(layer);
      } else {
        map.addLayer(layer);
        //Overwrite default 0 opacity from settings
        (layer as any).setOpacity(1);
      }
    }
  }

  public render(): ReactNode {
    const center: LatLngExpression = [
      LeafletMapSettings.center.lat,
      LeafletMapSettings.center.lng,
    ];
    const clusteringIcon = MapWrapper.getClusteringIconClass(
      this.state.clusteringActive
    );

    const propSettings = { ...LeafletMapSettings.propertyLayerSetting };
    if (
      propSettings.token === '' &&
      this.props.token &&
      this.mapLayerRefs[MapLayers.Property].current &&
      this.mapLayerRefs[MapLayers.Property].current.leafletElement
    ) {
      const layer =
        this.mapLayerRefs[MapLayers.Property].current.leafletElement;
      (layer as any).authenticate(this.props.token);
    }

    return (
      <>
        <RouteAdminMenu leafletElement={this.mapRef.current?.leafletElement} />
        <Map
          zoom={LeafletMapSettings.center.zoom}
          ref={this.mapRef}
          center={center}
          style={{ height: '100%', width: '100%' }}
          {...(MapWrapper.CRS && { crs: MapWrapper.CRS })}
          onzoomend={(e: LeafletEvent) => this.setZoomLevel(e)}
          zoomControl={false}
          onViewportChanged={() => {
            this.setState({
              ...this.state,
              bounds: this.mapRef?.current?.leafletElement.getBounds(),
            });
          }}
        >
          <ZoomControl
            zoomInTitle={locale.general._zoomIn}
            zoomOutTitle={locale.general._zoomOut}
          />
          <TiledMapLayer
            {...LeafletMapSettings.streetLayerSetting}
            ref={this.mapLayerRefs[MapLayers.Street]}
          />
          <TiledMapLayer
            {...LeafletMapSettings.satelliteLayerSetting}
            ref={this.mapLayerRefs[MapLayers.Satellite]}
          />
          <DynamicMapLayer
            {...LeafletMapSettings.propertyLayerSetting}
            f="json"
            ref={this.mapLayerRefs[MapLayers.Property]}
          />
          <RouteAdminLayer
            isPropertyLayerVisible={this.state.layerVisibility.Property}
            clusteringActive={false}
            zoomLevel={this.state.zoomLevel}
          />
          <SelectedRouteLayer
            clusteringActive={this.state.clusteringActive}
            zoomLevel={this.state.zoomLevel}
          />
          <SelectedServiceListLayer
            clusteringActive={this.state.clusteringActive}
            zoomLevel={this.state.zoomLevel}
          />
          <VehicleTrackingLayer />
          <ImportedRoutesLayer
            clusteringActive={this.state.clusteringActive}
            zoomLevel={this.state.zoomLevel}
          />
          <WorklistsLayer
            clusteringActive={this.state.clusteringActive}
            zoomLevel={this.state.zoomLevel}
          />
          {this.state.layerVisibility.TrackInfoLine &&
            this.state.zoomLevel > 7 &&
            this.props.selectedOrder && (
              <TrackInfoLineLayer
                clusteringActive={this.state.clusteringActive}
                zoomLevel={this.state.zoomLevel}
                bounds={this.state.bounds}
              />
            )}
          {this.state.layerVisibility.TrackInfo &&
            this.state.zoomLevel > 7 &&
            this.props.selectedOrder && (
              <TrackInfoLayer
                clusteringActive={this.state.clusteringActive}
                zoomLevel={this.state.zoomLevel}
                bounds={this.state.bounds}
              />
            )}
          {this.state.layerVisibility.WMS &&
            this.state.zoomLevel > 7 && ( //Placeholder only, not even visible above zoom level 7 by default of leaflet
              <WMSLayer
                clusteringActive={this.state.clusteringActive}
                zoomLevel={this.state.zoomLevel}
                layers={this.state.selectedWMSLayers
                  .map((layer) => layer.layerValue)
                  .join(',')} //Requires comma separated string of layernames
              />
            )}
          {this.state.layerVisibility.TrackingLine &&
            this.state.zoomLevel > 7 && (
              <TrackingLineLayer
                clusteringActive={this.state.clusteringActive}
                zoomLevel={this.state.zoomLevel}
                bounds={this.state.bounds}
              />
            )}
          <TrackingLineLegend
            visible={
              (this.state.layerVisibility.TrackingLine ||
                this.state.layerVisibility.TrackInfoLine ||
                this.state.layerVisibility.TrackInfo) &&
              this.state.zoomLevel > 7
            }
          />
          <MapControl className="leaflet-bar" position="topleft">
            <a href="#" onClick={() => this.toggleClustering()}>
              <i className={clusteringIcon}></i>
            </a>
          </MapControl>
          {this.state.layerVisibility.WMS && (
            <MultiSelect
              className={'wms-layer-selector'}
              panelClassName="wms-layer-selector-panel"
              placeholder={locale.map._nWMSLayersSelected.replace(
                '{0}',
                this.state.selectedWMSLayers.length.toString()
              )}
              value={this.state.selectedWMSLayers}
              options={this.state.availableWMSLayers}
              optionLabel={'layerDisplayName'}
              onChange={(e: any) =>
                this.setState({ ...this.state, selectedWMSLayers: e.value })
              }
              filter
              fixedPlaceholder={true}
              style={{
                right: this.getWMSSelectorRightPositionPx() + 'px',
              }}
            />
          )}
          <Dropdown className="map-selection">
            <Dropdown.Toggle variant="light" id="toggle">
              <FontAwesomeIcon icon={faLayerGroup} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu">
              <div
                className={
                  this.state.layerVisibility[MapLayers.Street]
                    ? 'selected-item'
                    : 'not-selected-item'
                }
                onMouseDown={() => this.toggleLayerVisibility(MapLayers.Street)}
              >
                <FontAwesomeIcon icon={faMap} className="icon-place" />{' '}
                {locale.map._streetLayer}
              </div>
              <div
                className={
                  this.state.layerVisibility[MapLayers.Satellite]
                    ? 'selected-item'
                    : 'not-selected-item'
                }
                onMouseDown={() =>
                  this.toggleLayerVisibility(MapLayers.Satellite)
                }
              >
                <FontAwesomeIcon
                  icon={faPlaneDeparture}
                  className="icon-place"
                />{' '}
                {locale.map._satelliteLayer}
              </div>
              <div
                className={
                  this.state.layerVisibility[MapLayers.Property]
                    ? 'selected-item'
                    : 'not-selected-item'
                }
                onMouseDown={() =>
                  this.toggleLayerVisibility(MapLayers.Property)
                }
              >
                <FontAwesomeIcon icon={faMapPin} className="icon-place" />{' '}
                {locale.map._propertyLayer}
              </div>
              {this.props.companySettings?.trackingLineLayer?.enabled && (
                <div
                  className={
                    this.state.layerVisibility[MapLayers.TrackingLine]
                      ? 'selected-item'
                      : 'not-selected-item'
                  }
                  onMouseDown={() =>
                    this.toggleLayerVisibility(MapLayers.TrackingLine)
                  }
                >
                  <FontAwesomeIcon icon={faRoute} className="icon-place" />{' '}
                  {locale.map._trackingLineLayer}
                </div>
              )}
              {this.props.companySettings?.trackInfoLayer?.enabled &&
                this.props.companySettings?.trackInfoLineLayer?.enabled && (
                  <div
                    className={
                      this.state.layerVisibility[MapLayers.TrackInfo] &&
                      this.state.layerVisibility[MapLayers.TrackInfoLine]
                        ? 'selected-item'
                        : 'not-selected-item'
                    }
                    onMouseDown={() =>
                      this.toggleLayerVisibility([
                        MapLayers.TrackInfo,
                        MapLayers.TrackInfoLine,
                      ])
                    }
                  >
                    <FontAwesomeIcon icon={faRoute} className="icon-place" />{' '}
                    {locale.map._trackInfoPointsAndLines}
                  </div>
                )}
              {this.props.companySettings?.WMSLayer?.enabled && (
                <div
                  className={
                    this.state.layerVisibility[MapLayers.WMS]
                      ? 'selected-item'
                      : 'not-selected-item'
                  }
                  onMouseDown={() => this.toggleLayerVisibility(MapLayers.WMS)}
                >
                  <FontAwesomeIcon icon={faLayerGroup} className="icon-place" />{' '}
                  {locale.map._wmsLayer}
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <CacheRefreshToggle />
        </Map>
      </>
    );
  }
}
