import React, { Component, ReactNode } from 'react';
import { IFraction } from '../../models/Fraction.model';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import { Spinner2 } from '../shared/spinner2';
import TextField from '../shared/formikFields/TextField';
import { debounceEventHandler } from '../shared/submitFormUtility';
import FractionService from '../../services/fractionService';
import { Toastr } from '../../utils/Toastr';
import FractionPictureSelector from './FractionPictureSelector';
import { pictureStorageUrl } from '../../appConfig';
import yup from '../../common/validation';
import { locale } from '../../common/localization/localizationService';

export interface IFractionDialogProps {
  customerId: number;
  show: boolean;
  fraction?: IFraction;
  onHide: (renderer: boolean) => void;
  fractionPictures: Array<string>;
}

export interface IFractionDialogState {
  isLoading: boolean;
  selectedImage: string;
}

export default class FractionDialog extends Component<
  IFractionDialogProps,
  IFractionDialogState
> {
  public readonly state: IFractionDialogState = {
    isLoading: false,
    selectedImage: this.props.fraction?.url || '',
  };

  private async onSubmit(fraction: IFraction, isNew: boolean): Promise<void> {
    fraction.url = this.state.selectedImage;
    if (isNew) {
      try {
        this.setState({ ...this.state, isLoading: true });
        await FractionService.createFraction(fraction);
        Toastr.success(locale.fractions._fractionCreated);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.setState({ ...this.state, isLoading: false });
        this.props.onHide(true);
      }
    } else {
      try {
        this.setState({ ...this.state, isLoading: true });
        await FractionService.updateFraction(fraction);
        Toastr.success(locale.fractions._fractionUpdated);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.setState({ ...this.state, isLoading: false });
        this.props.onHide(true);
      }
    }
  }

  private newFraction(): IFraction {
    const newFraction: IFraction = {
      customerId: this.props.customerId,
      fractionId: 0,
      name: '',
      url: '',
    };
    return newFraction;
  }

  private onImageChange(url: string): void {
    const index = url.lastIndexOf('/');
    const imageName = url.substring(index + 1);
    this.setState({ ...this.state, selectedImage: imageName });
  }

  private getSchema() {
    return yup.object({
      name: yup.string().required().label(locale.fractions._fractionName),
    });
  }

  public render(): ReactNode {
    const isNew = !this.props.fraction;
    const fraction = this.props.fraction || this.newFraction();
    const { show, onHide, fractionPictures } = this.props;
    const { isLoading, selectedImage } = this.state;
    const selectedImageUrl = selectedImage
      ? pictureStorageUrl + selectedImage
      : null;
    return (
      <>
        <Formik
          onSubmit={(fraction: IFraction) => this.onSubmit(fraction, isNew)}
          initialValues={{ ...fraction }}
          validationSchema={this.getSchema()}
        >
          {({ handleSubmit }) => (
            <Modal
              show={show}
              onHide={() => onHide(false)}
              className="fraction-dialog"
            >
              <Spinner2 show={isLoading} />
              <Modal.Header closeButton>
                <Modal.Title>
                  {isNew
                    ? locale.fractions._newFraction
                    : locale.fractions._updateFraction}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                  <TextField
                    label={locale.fractions._fractionName}
                    name="name"
                    placeholder={locale.fractions._fractionName}
                  />
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <label className="form-label">
                        {locale.fractions._picture}
                      </label>
                    </div>
                    <FractionPictureSelector
                      imageUrls={fractionPictures}
                      initialSelectedImage={selectedImageUrl}
                      onSelect={this.onImageChange.bind(this)}
                    />
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => onHide(false)}>
                  {locale.fractions._cancelButton}
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!this.state.selectedImage}
                  onClick={debounceEventHandler(handleSubmit, 250)}
                >
                  {locale.fractions._submit}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </Formik>
      </>
    );
  }
}
