import { IRootState } from '../redux/state';
import { locale } from '../common/localization/localizationService';

export const convertStringToBoolean = (value: string): boolean => {
  return value === 'true';
};

export const isDataFetching = (
  state: IRootState,
  component: string
): boolean => {
  if (!state.loading?.loadingInProgress?.length) {
    return false;
  } else if (state.loading?.loadingInProgress?.length > 0) {
    for (let i = 0; i < state.loading.loadingInProgress.length; i++) {
      if (
        state.loading.loadingInProgress[i].toLowerCase().includes(component)
      ) {
        return true;
      }
    }
  }
  return false;
};
export function getDirectMessageStatus(status: number): string {
  switch (status) {
    case 0:
      return locale.directMessages._sent;
    case 1:
      return locale.directMessages._received;
    case 2:
      return locale.directMessages._read;
    default:
      return '';
  }
}

export function getOrderStatus(status: number): string {
  switch (status) {
    case 0:
      return locale.orders.status._noDone;
    case 1:
      return locale.orders.status._okDone;
    case 2:
      return locale.orders.status._downloaded;
    case 3:
      return locale.orders.status._started;
    case 4:
      return locale.orders.status._backOrder;
    case 5:
      return locale.orders.status._localBackOrder;
    case 6:
      return locale.orders.status._partiallyStarted;
    case 7:
      return locale.orders.status._partiallyCompleted;
    case 8:
      return locale.orders.status._rejected;
    case 10:
      return locale.orders.status._planned;
    case 99:
      return locale.orders.status._deleted;
    default:
      return '';
  }
}
