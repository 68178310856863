import React, { useEffect } from 'react';
import { useState } from 'react';
import { IAddFractionToUnit, IUnit } from '../../models';
import { IFraction } from '../../models/Fraction.model';
import { Form, Formik } from 'formik';
import { Button, Modal } from 'react-bootstrap';
import TextField from '../shared/formikFields/TextField';
import { debounceEventHandler } from '../shared/submitFormUtility';
import Select from 'react-select';
import UnitsService from '../../services/unitsService';
import { Toastr } from '../../utils/Toastr';
import { Spinner2 } from '../shared/spinner2';
import { locale } from '../../common/localization/localizationService';

export interface IEditUnitDialogProps {
  show: boolean;
  unit: IUnit;
  fractions: Array<IFraction>;
  onHide: (rerender: boolean) => void;
}

export interface IFractionSelectOption {
  value: number;
  label: string;
}

export default function EditUnitDialog(
  props: IEditUnitDialogProps
): JSX.Element {
  const [isLoading, setIsloading] = useState<boolean>(false);

  const [selectedFractionIds, setSelectedFractionIds] = useState<Array<number>>(
    []
  );

  const [fractionOptions, setFractionOptions] = useState<
    Array<IFractionSelectOption>
  >([]);

  const defaultFractions = fractionOptions.filter((fraction) =>
    selectedFractionIds.includes(fraction.value)
  );

  useEffect(() => {
    const options = props.fractions.map((fraction) => ({
      value: fraction.fractionId,
      label: fraction.name,
    }));

    const selectedIds = props.unit.fractions.map(
      (fraction: IFraction) => fraction.fractionId
    );

    setFractionOptions(options);
    setSelectedFractionIds(selectedIds);
  }, [props.fractions]);

  const onSubmit = async (): Promise<void> => {
    setIsloading(true);
    const addFractionToUnit: IAddFractionToUnit = {
      commune: props.unit.commune,
      unitId: props.unit.unitId,
      fractionIds: selectedFractionIds,
    };
    try {
      await UnitsService.AddFractionToUnit(addFractionToUnit);
      Toastr.success(locale.units._unitUpadated);
    } catch (error) {
      Toastr.error(error);
    } finally {
      setIsloading(false);
      props.onHide(true);
    }
  };

  const fractoinFilterChanged = (selectedOptions: any) => {
    if (selectedOptions) {
      const selectedIds = selectedOptions.map((option: any) => option.value);
      setSelectedFractionIds(selectedIds);
    }
  };

  return (
    <>
      <Formik initialValues={props.unit} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <Modal show={props.show} onHide={() => props.onHide(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{locale.units._manageFractions}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Spinner2 show={isLoading} />
              <Form noValidate onSubmit={handleSubmit}></Form>
              <TextField label="ID" name="unitId" disabled placeholder="" />
              <TextField
                label={locale.units._unitName}
                name="name"
                disabled
                placeholder=""
              />
              <TextField
                label={locale.units._commune}
                name="commune"
                disabled
                placeholder=""
              />
              <label>{locale.units._fractions}</label>
              <Select
                options={fractionOptions}
                value={defaultFractions}
                onChange={(e: any) => fractoinFilterChanged(e)}
                isMulti
                placeholder={locale.units._selectFractions}
                className="input-field react-select"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => props.onHide(false)}>
                {locale.units._cancel}
              </Button>
              <Button
                type="submit"
                variant="primary"
                onClick={debounceEventHandler(handleSubmit, 250)}
              >
                {locale.units._save}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Formik>
    </>
  );
}
