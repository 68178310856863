import { wasteManagementUrls as urls } from '../appConfig';
import api from '../services/wasteManagementClient';
class GroupService {
  public static async getGroups(ignoreCache: boolean = false): Promise<Array<ICustomerGroup>> {
    try {
      const params = {
        cache: { ignoreCache, maxAge: 1000 * 60 * 60 * 12 },
      };
      const response = await api.get(`${urls.customers}/types`, params);
      return response.status === 200
        ? response.data : [];
    } catch (error) {
      console.error(error);
      throw new Error(error);
    }
  }
}

export interface ICustomerGroup {
  description: string;
  customerTypeId: number;
}
export default GroupService;
