import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import routeOptimalizationActions from '../../redux/modules/routeOptimalization/routeOptimalizationActions';
import agreementConstants from '../../redux/modules/agreement/agreementConstants';
import RouteOptimalizationWizard from './RouteOptimalizationWizard';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';

const isAgreementsLoading = createLoadingSelector(
  agreementConstants.GET_AGREEMENTS
);

function mapStateToProps(state) {
  return {
    importedRoutes: state.routeOptimalization.importedRoutes,
    unassignedStops: state.routeOptimalization.unassignedStops,
    worklists: state.routeOptimalization.worklists,
    activeTab: state.routeOptimalization.activeTab,
    isAgreementsLoading: isAgreementsLoading(state),
    avaibleServiceOrders: state.routeOptimalization.avaibleServiceOrders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    routeOptimalizationActions: bindActionCreators(
      routeOptimalizationActions,
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RouteOptimalizationWizard);
