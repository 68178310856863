import React from 'react';
import PropTypes from 'prop-types';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import RoutePointTooltip from './RoutePointTooltip';
import { CircleMarker } from '../../shared/customMapLayer';

function RoutePointMarker({ id, name, position, zoomLevel }) {
  return (
    <CircleMarker
      position={position}
      icon={faCircle}
      color={'green'}
      zoomLevel={zoomLevel}
    >
      <RoutePointTooltip id={id} name={name} />
    </CircleMarker>
  );
}

RoutePointMarker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.array.isRequired,
  zoomLevel: PropTypes.number.isRequired,
};

export default React.memo(RoutePointMarker);
