import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../../common/localization/localizationService';

export default function DuplicateRoutesWarning({ duplicateGlns: duplicates }) {
  if (!duplicates) {
    return null;
  }

  return (
    <div className="duplicate-routes-warning">
      <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" />
      <span>{`${locale.routeOptimalizationWizard._duplicateRoutesWarning}: ${
        locale.routeOptimalizationWizard.routesGrid._gln
      }: ${duplicates['glns'].join(', ')}, ${
        locale.routeOptimalizationWizard.routesGrid._serial
      }: ${duplicates['serials'].join(', ')}`}</span>
    </div>
  );
}

DuplicateRoutesWarning.propTypes = {
  duplicateGlns: PropTypes.object,
};

DuplicateRoutesWarning.defaultProps = {
  duplicateGlns: [],
};
