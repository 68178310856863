/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { wasteManagementUrls } from '../appConfig';
import { IAddFractionToUnit, IUnit } from '../models';
import wasteManagementClient from './wasteManagementClient';

export default class UnitsService {
  static getUnits(ignoreCache = false): Promise<Array<IUnit>> {
    return wasteManagementClient
      .get(wasteManagementUrls.units, { cache: { ignoreCache } })
      .then((resp) => {
        if (resp?.data && resp.status === 200) {
          return resp.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static AddFractionToUnit(
    dto: IAddFractionToUnit,
    ignoreCache = false
  ): Promise<boolean> {
    return wasteManagementClient
      .post(wasteManagementUrls.units, dto, { cache: { ignoreCache } })
      .then((resp) => {
        if (resp?.data && resp.status === 200) {
          return resp.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
