import { Dispatch } from 'redux';
import { IFraction } from '../../../models/Fraction.model';
import * as Actions from './fractionsActions';
import FractionService from '../../../services/fractionService';
import { FractionsConstants } from './fractionsConstants';

export function createFractionRequest(): Actions.FetchFractionList {
  return {
    type: FractionsConstants.GET_ALL_FRACTIONS_REQUEST,
  };
}
export function createFractionSuccess(
  payload: Array<IFraction>
): Actions.FetchFractionList.Success {
  return {
    type: FractionsConstants.GET_ALL_FRACTIONS_SUCCESS,
    fractions: payload,
  };
}
export function createFractionFailure(
  error: Error
): Actions.FetchFractionList.Failure {
  return {
    type: FractionsConstants.GET_ALL_FRACTIONS_FAILED,
    error,
  };
}

export function loadFractions(
  ignoreCache: boolean = false
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createFractionRequest());
    try {
      const result: Array<IFraction> = await FractionService.getAllFractions(
        ignoreCache
      );
      dispatch(createFractionSuccess(result));
    } catch (error) {
      dispatch(createFractionFailure(error));
    }
  };
}

export function createFractionPicturesRequest(): Actions.FetchFractionPicturesList {
  return {
    type: FractionsConstants.GET_FRACTION_PICTURES_REQUEST,
  };
}
export function createFractionPicturesSuccess(
  payload: Array<string>
): Actions.FetchFractionPicturesList.Success {
  return {
    type: FractionsConstants.GET_FRACTION_PICTURES_SUCCESS,
    pictures: payload,
  };
}
export function createFractionPicturesFailure(
  error: Error
): Actions.FetchFractionPicturesList.Failure {
  return {
    type: FractionsConstants.GET_FRACTION_PICTURES_FAILED,
    error,
  };
}

export function loadFractionPictures(
  ignoreCache = false
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createFractionPicturesRequest());
    try {
      const result: Array<string> = await FractionService.getFractionPictures(
        ignoreCache
      );
      dispatch(createFractionPicturesSuccess(result));
    } catch (error) {
      dispatch(createFractionPicturesFailure(error));
    }
  };
}
