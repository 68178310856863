import React, { Component, ReactNode } from 'react';
import {
  Grid,
  GridColumn,
  GridColumnMenuFilter,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization/localizationService';
import CommandCell from '../userAdmin/CommandCell';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { getDirectMessageStatus } from '../../utils/GeneralUtils';
import ColumnMenu from '../shared/ColumnMenu';
import moment from 'moment';

export interface IDirectMessagesGridProps {
  data: Array<any>;
  openMessageDialog: (message: any) => void;
  clasName?: string;
}

export interface IDirectMessagesGridState {
  dataState: Record<string, any>;
}

export class DirectMessageGrid extends Component<
  IDirectMessagesGridProps,
  IDirectMessagesGridState
> {
  public readonly state: IDirectMessagesGridState = {
    dataState: {
      sort: [
        {
          dir: 'desc',
          field: 'date',
        },
      ],
      take: 50,
      skip: 0,
    },
  };

  public dataStateChange(event: Record<string, any>): void {
    this.setState({ ...this.state, dataState: event.data });
  }

  private formatDate(props: any): any {
    let date;
    const { field, dataItem } = props;

    switch (field) {
      case 'date':
        if (dataItem.date) {
          date = new Date(dataItem.date);
        }
        break;

      case 'received':
        if (dataItem.received) {
          date = new Date(dataItem.received);
        }
        break;

      case 'expires':
        if (dataItem.expires) {
          date = new Date(dataItem.expires);
        }
        break;

      default:
        break;
    }

    const formattedDate = this.getFormattedDate(date, props.field);
    if (formattedDate) {
      return (
        <>
          <td className="auto-resize-column ">{formattedDate}</td>
        </>
      );
    } else {
      return (
        <>
          <td className="auto-resize-column "></td>
        </>
      );
    }
  }

  private getFormattedDate(date: Date, field: string): string {
    let formattedDate = '';
    if (date) {
      if (field === 'date' || field === 'received') {
        formattedDate = moment(date).format(locale.general._fullDateWithTime);
      } else if (field === 'expires' && date) {
        formattedDate = moment(date).format(locale.general._fullDateFormat);
      }
    }

    return formattedDate;
  }

  private formatStatus(props: any) {
    const statusString = getDirectMessageStatus(props.dataItem.status);
    return (
      <>
        <td className="auto-resize-column ">{statusString}</td>
      </>
    );
  }

  private InfoCell(props: any) {
    return (
      <CommandCell
        {...props}
        edit={this.props.openMessageDialog}
        icon={faInfoCircle}
      />
    );
  }

  private columnProps(field: any) {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: this.isColumnActive(field, this.state.dataState)
        ? 'active'
        : '',
    };
  }

  private isColumnActive(field: any, dataState: any) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  public render(): ReactNode {
    this.props.data.map((message) => {
      message.date = new Date(message.date);
      message.expires = new Date(message.expires);
      if (message.received) {
        message.received = new Date(message.received);
      }
      message.statusText = getDirectMessageStatus(message.status);
      return message;
    });
    const result = process(this.props.data, this.state.dataState);
    return (
      <>
        <Grid
          {...this.state.dataState}
          className={`messages-grid-wrapper ${this.props.clasName}`}
          data={result}
          selectedField="selected"
          pageable
          resizable
          sortable
          pageSize={50}
          onDataStateChange={this.dataStateChange.bind(this)}
        >
          <GridNoRecords>{locale.general._noRecords}</GridNoRecords>

          <GridColumn field="id" title={'ID'} width={60} />
          <GridColumn
            {...this.columnProps('date')}
            filter="date"
            title={locale.directMessages._date}
            format={'{0:' + locale.general._fullDateWithTime + '}'}
            width={150}
          />
          <GridColumn
            {...this.columnProps('expires')}
            filter="date"
            title={locale.directMessages._expires}
            format={'{0:' + locale.general._fullDateFormat + '}'}
            width={100}
          />
          <GridColumn
            field="received"
            {...this.columnProps('received')}
            title={locale.directMessages._read}
            format={'{0:' + locale.general._fullDateWithTime + '}'}
            width={150}
          />
          <GridColumn
            {...this.columnProps('received')}
            field="sender"
            title={locale.directMessages._sender}
            width={150}
          />
          <GridColumn
            {...this.columnProps('address')}
            title={locale.directMessages._address}
            width={150}
          />
          <GridColumn
            {...this.columnProps('text')}
            title={locale.directMessages._messages}
            className="message-column"
          />
          <GridColumn
            {...this.columnProps('vehicleId')}
            title={locale.directMessages._vehicle}
            width={90}
            className="auto-resize-column"
          />
          <GridColumn
            {...this.columnProps('statusText')}
            title={locale.directMessages._status}
            width={90}
          />
          <GridColumn
            cell={this.InfoCell.bind(this)}
            width={26}
            sortable={false}
          />
        </Grid>
      </>
    );
  }
}
