/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import { Component, ReactNode } from 'react';
import { IUser } from '../../models';
import PaneHeadline from '../shared/paneHeadline';
import { faWarehouse } from '@fortawesome/free-solid-svg-icons';
import './depots.scss';
import { IDepot } from '../../models/Depot.model';
import { DepotGrid } from './DepotsGrid';
import DepotDialog from './DepotDialog';
import { Spinner2 } from '../shared/spinner2';
import { Toastr } from '../../utils/Toastr';
import DepotsService from '../../services/DepotsService';
import { locale } from '../../common/localization/localizationService';

export interface IDepotsProps {
  depotActions: any;
  impersonatedUser: any;
  authentication: IUser;
  depotList: Array<IDepot>;
  loading: boolean;
}

export interface IDepotsState {
  depotDialog: {
    show: boolean;
    depot?: IDepot;
    customerId: number;
  };
  deleteLoading: boolean;
}

export class Depots extends Component<IDepotsProps, IDepotsState> {
  public readonly state: IDepotsState = {
    depotDialog: {
      show: false,
      customerId: null,
      depot: null,
    },
    deleteLoading: false,
  } as IDepotsState;

  public componentDidMount(): void {
    this.props.depotActions.getDepots();
  }

  private addNewDepot(): void {
    this.setState({
      ...this.state,
      depotDialog: {
        customerId: this.props.authentication.customerId,
        depot: null,
        show: true,
      },
    });
  }

  private editDepot(depot: IDepot): void {
    depot.latitude = depot.latitude
      .toString()
      .replace('.', locale.general._decimalSeparator);
    depot.longitude = depot.longitude
      .toString()
      .replace('.', locale.general._decimalSeparator);
    this.setState({
      ...this.state,
      depotDialog: {
        customerId: this.props.authentication.customerId,
        depot: depot,
        show: true,
      },
    });
  }

  private async deleteDepot(depot: IDepot): Promise<void> {
    if (confirm(locale.depots._deleteConfirm)) {
      try {
        this.setState({ ...this.state, deleteLoading: true });
        await DepotsService.deleteDepot(depot.depotId, depot.customerId);
        Toastr.success(locale.depots._deleteSuccess);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.setState({ ...this.state, deleteLoading: false });
        this.props.depotActions.getDepots(true);
      }
    }
  }

  private hideDepotDialog(rerender = false): void {
    this.setState({
      ...this.state,
      depotDialog: {
        customerId: null,
        depot: null,
        show: false,
      },
    });
    if (rerender) {
      this.props.depotActions.getDepots(true);
    }
  }

  public render(): ReactNode {
    const { depotList, loading } = this.props;
    const { deleteLoading } = this.state;
    return (
      <>
        <div className="depots">
          <div className="row">
            <PaneHeadline
              titleText={locale.depots._depots}
              titleIcon={faWarehouse}
              className="col-sm-6"
            />
            <div className="col-sm-6 text-right mb-3">
              <button
                className="btn btn-outline-dark"
                onClick={() => this.addNewDepot()}
              >
                {locale.depots._newDepot}
              </button>
            </div>
          </div>
          <div className="d-flex">
            <Spinner2 show={loading || deleteLoading} />
            <DepotGrid
              data={depotList}
              editDepot={this.editDepot.bind(this)}
              deleteDepot={this.deleteDepot.bind(this)}
              className="mb-3"
            />
          </div>
          {this.state.depotDialog.show && (
            <DepotDialog
              depot={this.state.depotDialog.depot}
              customerId={this.state.depotDialog.customerId}
              show={this.state.depotDialog.show}
              onHide={this.hideDepotDialog.bind(this)}
            />
          )}
        </div>
      </>
    );
  }
}
