import { bindActionCreators, Dispatch } from 'redux';
import { IUser } from '../../models';
import { IRootState } from '../../redux/state';
import depotActions from '../../redux/modules/depot/depotActions';
import { connect } from 'react-redux';
import { Depots } from './Depots';
import { isDataFetching } from '../../utils/GeneralUtils';

function mapStateToProps(state: IRootState) {
  return {
    depotList: state.depot.depots,
    authentication: state.authentication as unknown as IUser,
    impersonatedUser: state.authentication.impersonatedUser,
    loading: isDataFetching(state, 'depot'),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    depotActions: bindActionCreators(depotActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Depots);
