const routeOptimalizationConstants = {
  SET_IMPORTED_ROUTES: 'SET_IMPORTED_ROUTES',
  ADD_IMPORTED_ROUTE: 'ADD_IMPORTED_ROUTE',
  REMOVE_IMPORTED_ROUTE: 'REMOVE_IMPORTED_ROUTE',
  CLEAR_IMPORTED_ROUTE_STORE: 'CLEAR_IMPORTED_ROUTE_STORE',
  SET_UNASSIGNED_STOPS: 'SET_UNASSIGNED_STOPS',

  SET_WORKLISTS: 'SET_WORKLISTS',
  SET_SELECTED_WORKLIST: 'SET_SELECTED_WORKLIST',

  SET_ACTIVE_ROUTE_OPTIMALIZATION_TAB: 'SET_ACTIVE_ROUTE_OPTIMALIZATION_TAB',

  SET_DEPOT_STOP_LISTS: 'SET_DEPOT_STOP_LISTS',

  GET_AVAIBLE_SERVICE_ORDERS: 'GET_AVAIBLE_SERVICE_ORDERS',
};

export default routeOptimalizationConstants;
