import React, { Component, ReactNode } from 'react';
import RoutestopDetailsTooltip from '../shared/customMapLayer/RoutestopDetailsTooltip';
import { IRouteDeviation, IRouteStop } from '../../models';

export interface IRouteStopTooltipProps {
  stop?: IRouteStop; // Allow a single route stop
  stops?: Array<IRouteStop>;
  data: IRouteStop | Array<IRouteStop>;
}

export class RouteStopTooltip extends Component<IRouteStopTooltipProps> {
  public formatDeviations(deviations: Array<IRouteDeviation>): string {
    return deviations && deviations.length > 0 ? deviations.join(', ') : null;
  }

  public render(): ReactNode {
    const { data } = this.props;
    let stop: IRouteStop;
    let stops: Array<IRouteStop>;
    if (Array.isArray(data)) {
      stops = data;
    } else {
      stop = data;
    }

    if (stop) {
      return <RoutestopDetailsTooltip stops={[stop]} />;
    }

    if (stops && stops.length > 0) {
      return <RoutestopDetailsTooltip stops={stops} />;
    }

    return null;
  }
}
