export const localeEn = {
  general: {
    _administration: 'Administration',
    _username: 'Username',
    _password: 'Password',
    _date: 'Date',
    _time: 'Time',
    _orderOverview: 'Order overview',
    _reports: 'Reports',
    _kg: 'kg',
    _ton: 'ton',
    _callOrder: 'Call orders',
    _serviceList: 'Work lists',
    _impersonationManager: 'Set customer/user',
    _userAdmin: 'User administration',
    _routeAdmin: 'Routes',
    _backendError: 'An error occurred during the request',
    _accessError: 'You do not have access to this page',
    _vehicles: 'Cars',
    _vehicleSettings: 'Administrative Settings',
    _zoomIn: 'Zoom in',
    _zoomOut: 'Zoom out',
    _warning: 'Warning!',
    _customerRegister: 'Customer register',
    _commune: 'Commune',
    _logOut: 'Log out',
    _selectLanguage: 'Select language',
    _unitDesrciption: 'Description',
    _fullDateFormat: 'MM.dd.yyyy',
    _fullDateWithTime: 'MM.dd.yyyy HH:mm:ss',
    _fullMomentFormat: 'MM.DD.YYYY',
    _fullMomentWithTime: 'MM.DD.YYYY HH:mm:ss',
    _noRecords: 'No record available.',
    _dashboard: 'Dashboard',
    _decimalSeparator: '.',
    validation: {
      _isRequired: 'is required',
      _stringMaxCharA: 'must be at most',
      _stringMaxCharB: 'characters',
      _emailNotValid: 'is not a valid email address!',
      _lessOrEqual: 'must be less than or equal to',
      _greaterOrEqual: 'must be greater than or equal to',
      _laterThan: 'must be later than',
      _earlierThan: 'must be earlier than',
    },
  },
  login: {
    _usernameEmail: 'E-mail',
    _usernameRequired: 'Username is required!',
    _passwordRequired: 'Password is required!',
    _usernamePasswordInvalid: 'Incorrect username or password',
    _invalidEmail: 'Username is not a valid email address!',
    _submit: 'Log In',
    _welcome: 'Welcome to GPS Realtime WMS',
    _pressLogin: 'Press Log in to log in to the portal',
    _ifAssistance: 'If assistance is needed,',
    _pleaseContact: 'please contact var support',
  },
  tooltip: {
    _deviceId: 'Unit number',
    _altitude: 'Height',
    _speed: 'Speed',
    _temperature: 'Temperature',
    _orderId: 'Order no',
    _address: 'Address',
    _routeName: 'Route',
  },
  orders: {
    _update: 'Updated',
    _start: 'Start',
    _route: 'Route',
    _vehicleId: 'Vehicle',
    _orderId: 'Order',
    _status: 'Status',
    _notStarted: 'Not started',
    _now: 'Now',
    _clearFilter: 'Clear filter',
    _fraction: 'Fraction',
    _showFilters: 'Show filters',
    _chooseContractor: 'Choose carrier',
    _chooseFraction: 'Choose fraction',
    _chooseStatus: 'Choose status',
    _expires: 'Expires',
    _date: 'Date',
    _ordinaryDate: 'Ordinary date',
    _earlierDateValidation:
      'Expiration date cannot be earlier than the start date',
    _fractions: 'Fractions',
    _selectFractions: 'Select fractions',
    _noFractions: 'No fractions',
    _updateOrder: 'Update order',
    _updateSuccess: 'Order is updated',
    _fractionsLoading: 'Fractions are loading',
    _notEditableWarning:
      'Once the route has started, only the expiration date can be changed.',
    _contractor: 'Contractor',
    _customerGroup: 'Customer Group',
    _newOrder: 'New Order',
    _selectContractor: 'Select Contractor',
    _selectRoute: 'Select Route',
    _selectFractions: 'Select Fraction/Waste Type',
    _selectCustomerGroup: 'Select Customer Group',
    _selectFrequency: 'Select Frequency',
    _selectDays: 'Select Day(s)',
    _days: 'Day(s)',
    _startDate: 'Start Date',
    _ordinaryDate: 'Enter Ordinary Date',
    _expireDate: 'Enter Expiration Date',
    _repetationEndDate: 'Repetition End Date',
    _frequency: 'Frequency',
    status: {
      _noDone: 'Not completed',
      _okDone: 'Ok, completed',
      _downloaded: 'Downloaded',
      _started: 'Started',
      _backOrder: 'Back order',
      _localBackOrder: 'Local back order',
      _partiallyStarted: 'Partially started',
      _partiallyCompleted: 'Partially completed',
      _rejected: 'Rejected',
      _planned: 'Planned',
      _deleted: 'Deleted',
    },
  },
  orderDetails: {
    _address: 'Address',
    _unit: 'Unit',
    _executed: 'Done',
    _vehicleId: 'Vehicle',
    _driver: 'Driver',
    _time: 'Time',
    _deviation: 'Deviation',
    _orderDoesNotExist: 'The order does not exist',
    _companyName: 'Customer',
    _pictureTakenText: 'pictures',
    _sequence: 'Sequence',
  },
  orderStatuses: {
    _started: 'Started',
    _done: 'OK, driven',
    _partiallyDone: 'Partially completed',
  },
  orderDetailsDiagram: {
    _executionStatusHeader: 'Done (Yes / No)',
    _deviationsHeader: 'Deviation / Note',
    _noDeviations: 'Deviation / Note',
  },
  routeStopDetails: {
    _routeStopDoesNotExist: 'The route stop does not exist',
    _departureTime: 'Departure time',
    _arrivalTime: 'Time of arrival',
    _address: 'Address',
    _agreementId: 'Agreement ID',
    _unit: 'Unit',
    _agreementLineId: 'Agreement line ID',
    _executed: 'Done',
    _externalOrderId: 'Order no',
    _vehicleId: 'Vehicle',
    _driver: 'Driver',
    _orderData: 'Order data',
    _pictures: 'Pictures',
    _deviation: 'Deviation / Note',
    _comment: 'Comment',
  },
  routeAdminPage: {
    _selectRoute: 'Select route(s)',
    _refresh: 'Refresh',
    _withoutPosition: 'Without position',
    _sequence: 'Sequence',
    _description: 'Description',
    _routeLineId: 'Stopping point',
    _agreementId: 'Agreement id',
    _name: 'name',
    _estate: 'Property',
    _unitName: 'Unit name',
    _agreementLineId: 'Agreement Line ID',
    _physicalAmount: 'Quantity',
    _route: 'Route',
    _degLat: 'Latitude',
    _degLong: 'Longitude',
    _invalidSequence: 'Invalid sequence number',
    _changeSequence: 'Change sequence',
    _cancelButton: 'Cancel',
    _submitButton: 'Save',
    _validateButton: 'Validate',
    _restoreButton: 'Cancel',
    _locationChangeTitle: 'Are you sure you want to save?',
    _locationChangeContent: 'Your changes will now be saved',
    _splitStoppoint: 'Split breakpoint',
    _splitModalPlaceholder: 'Drag a customer here to create a new stop',
    _splitModalErrorMessage: 'You must create at least 2 stop points',
    _mergeModalTitle: 'Merge the breakpoints',
    _mergeModalContent: 'Selected the stopping points',
    _selectOnlyOne: 'Please select only 1 stop',
    _selectAtLeastOne: 'At least 1 stop point must be selected',
    _selectAtLeastTwo: 'At least 2 stopping points must be selected',
    _mergeStoppoints: 'Merge pickup location',
    _sequenceEdit: 'Change sequence',
    _savePosition: 'Save position',
    _changePosition: 'Change position',
    _changePositionTooltip: 'Change the position of the selected stop point',
    _agreementGid: 'GnrBnrFnrSnr',
    _placeId: 'Pickup location ID',
    _address: 'Address',
    _customerName: 'Kundenavn',
    _propertyLinesWarning: 'Some of the points may appear off the map.',
    _propertyLinesCustom: 'Show only points with coordinates.',
    _propertyLinesAll: 'Vis alle punkter.',
    _changesHeader: 'The following sequence numbers are affected',
    _changedFrom: 'changed from',
    _changedTo: 'to',
    _notInNorwayErrorMessage: 'The position you set is outside Norway',
    _alreadyMerged: 'Marked deals have already been merged',
    _mergeModalTargetText: "Select the stop point's view/address",
    _mergeModalTargetDropdownText: 'Choose which view/address',
    _setPosition: 'Set position',
    _setPositionTooltip: 'Set the position of the selected stop point',
    _noRecords: 'No record available.',
    _isLoadingMessage: 'Records are being loaded.',
    _sequenceSuccessfullyChanged: 'The sequence has been updated',
    _remove: 'Remote',
    _selection: 'Selection',
    _hideWithoutAgreement: 'Hide without agreement',
    _chooseRoutes: 'Choose routes',
  },
  reports: {
    _exportTitle: 'Export to Excel file',
    _nothingToExportTitle: 'No data to export',
    _showReport: 'Show report',
    _fromDate: 'From date',
    _toDate: 'To date',
    _selectReport: 'Select report',
    _reportEmpty: 'No record available',
    _header: 'My reports',
    _summary:
      'Here we have collected all their reports. Reports can be ordered from our consultants. Contact us by email {mailto}.',
    _export: 'Export',
  },
  serviceLists: {
    _noOrders: 'No orders scheduled.',
    _delete: 'Delete',
    _add: 'Add',
    _deleteConfirm: 'Are you sure you want to delete this worklist: ',
  },
  routeOptimalizationWizard: {
    _title: 'Create driving lists',
    _uploadFileStep: 'Load file',
    _uploadFileButton: 'Select file',
    _loadInButton: 'Load in',
    _analyseStep: 'Analyze',
    _analyseButton: 'Run analysis',
    _saveStep: 'Driving lists',
    _saveButton: 'Create driving lists',
    _downloadTemplateButton: 'Download import template',
    _importedRouteTabTitle: 'Order basis',
    _worklistsTabTitle: 'Driving lists',
    _unassignedStopsTabTitle: 'Orders not included',
    _noRoutesImported: 'No order, load file',
    _worklistEmpty: 'The driving list is empty',
    _worklistOptionText: 'Kjørerute',
    _addCustomer: 'Driving route',
    _duplicateRoutesWarning: 'There are duplicate orders in the order basis',
    _noFileSelectedTitle: 'No file selected!',
    _noFileSelectedDescription:
      'Upload the order basis list file with the following import template:',
    _avaibleOrders: 'Avaible orders',
    _loadIn: 'Load in',
    _loadOrders: 'Load orders',
    importManager: {
      _unknownParseError: 'An unknown error occurred',
      _fileLoadResultsModalTitle: 'File upload results',
      _fileLoadSuccess: 'File upload successful!',
      _fileLoadModalCloseButton: 'Close',
      _agreementNotFound: 'Customers not found for customer no',
      _wrongFileFormat: 'Incorrect file format. Download import template',
      _fileEmpty: 'The file contains no data',
    },
    addCustomerModal: {
      _title: 'Add customer',
      _closeButton: 'Close',
      _addButton: 'Add',
      _dateLabel: 'Date',
      _agreementLabel: 'Customer',
      _descriptionLabel: 'Description',
      _serialLabel: 'Serial number',
      _noOptionsMessage: 'No customers found',
      _loadingMessage: 'Customers are loaded',
      _agreementSelector: 'Select customer',
      _agreementSelectorPlaceholder: 'Select customer...',
    },
    routeAnalysisModal: {
      _title: 'Analyze order basis',
      _closeButton: 'Close',
      _cancelButton: 'Cancel',
      _analyseButton: 'Analyze',
      _selectStartAndStop: 'Select start and stop',
      _workTime: 'Time effective working day',
      _timePerStop: 'Time spent per stop (minutes)',
      _maximumCars: 'Max number of cars in analysis',
      _startStopLabel: 'Start and stop point',
      _workHoursLabel: 'Hours',
      _workMinutesLabel: 'Minutes',
      _timePerStopLabel: 'Time spent',
      _maximumCarsLabel: 'Maximum number of vehicles',
      _noDepotOptionsMessage: 'No repositories found',
      _depotsLoadingMessage: 'Depots are loaded',
      _depotSelectorPlaceholder: 'Select depository...',
      _analysationError: 'Error when analyzing the order basis',
    },
    routesGrid: {
      _date: 'Date',
      _gln: 'Agreement no',
      _customerName: 'Customer name',
      _description: 'Description',
      _serial: 'Serial number',
      _orderNumber: 'Order no',
    },
    worklistResults: {
      _sequence: 'Sequence',
      _arrivalTime: 'Time',
      _gln: 'Customer no',
      _customerName: 'Customer name',
      _description: 'Description',
      _serial: 'Serial number',
      _unassignedWarning: 'orders not included!',
      worklistSummary: {
        _totalTime: 'Total time',
      },
      moveWorklistItemModal: {
        _title: 'Total time',
        _targetListLabel: 'Driving list',
        _closeButton: 'Close',
        _moveButton: 'Total time',
      },
    },
    createWorklistsModal: {
      _title: 'Create driving lists',
      _closeButton: 'Close',
      _saveButton: 'Create',
      _itemCount: 'Quantity',
      _listName: 'List names',
      _saveError: 'Error when creating driving lists',
    },
  },
  dateRangePicker: {
    _thisWeek: 'This week',
    _lastWeek: 'Last week',
    _thisMonth: 'This month',
  },
  serviceListsGrid: {
    _listName: 'List names',
    _vehicleId: 'Vehicle',
    _updated: 'Updated',
    _started: 'Start',
    _listDate: 'Date',
  },
  serviceOrders: {
    _noOrders: 'No orders scheduled.',
    _excelExportButton: 'Excel',
    _pdfExportButton: 'PDF',
    _deleteConfirm: 'Are you sure you want to delete this order: ',
  },
  serviceOrdersGrid: {
    _updated: 'Time',
    _address: 'Address',
    _executed: 'Done',
    _unit: 'Unit',
    _orderItemType: 'Type',
    _deviation: 'Deviation',
    _externalOrderId: 'GLN',
    _sequence: 'Sequence',
    _agreementCompanyName: 'Customer',
    _estimatedArrivalTime: 'Planned',
  },
  serviceOrderDetails: {
    _serviceOrders: 'Service orders',
  },
  serviceOrderDetailsGrid: {
    _agreementCompanyName: 'Customer',
    _address: 'Address',
    _externalOrderId: 'GLN',
    _sequence: 'Sequence',
    _estimatedArrivalTime: 'Planned',
    _description: 'Description',
    _listDescription: 'List description',
    _executed: 'Done',
    _vehicleId: 'Vehicle',
    _updated: 'Executed time',
    _unitName: 'Unit',
    _orderItemType: 'Type',
    _time: 'Time',
  },
  containerOrders: {
    _noOrders: 'No orders scheduled.',
    _addVehicle: 'Add vehicle',
    _cancel: 'Cancel',
    _save: 'Assign vehicle',
    _addVehicleSuccess: 'Vehicle added',
    _addVehicleFailure: 'Vehicle has not been set',
    _dateRange: 'Date range',
    _routes: 'Routes',
    _selectRoutes: 'Select routes',
    _vehicles: 'Vehicles',
    _selectVehicles: 'Select vehicles',
  },
  containerOrdersGrid: {
    _orderId: 'Order ID',
    _expires: 'Expires',
    _date: 'Date',
    _routeName: 'Route',
    _address: 'Address',
    _unitName: 'Unit',
    _orderTypeName: 'Type',
    _fraction: 'Fraction',
    _vehicleId: 'Vehicle',
    _route: 'Order route',
  },
  customerSearchGrid: {
    _id: 'Id',
    _system: 'System',
    _municipality: 'Municipality',
    _name: 'Name',
    _address: 'Address',
    _zipCode: 'Zip Code',
    _type: 'Profile Type',
    _unitDesrciption: 'Unit Type',
    _sendMessage: 'Send direct message',
  },
  containerOrderDetails: {
    _unitName: 'Unit',
    DetailData_Name: 'Name',
    DetailData_Address1: 'Address 1',
    DetailData_Address2: 'Address 2',
    DetailData_PostalCode: 'Postal code',
    DetailData_Other: 'Other',
    DetailData_Message: 'Comment',
    DetailData_OrderId: 'Order id',
    DetailData_Expires: 'Expires',
    DetailData_LastDate: 'Last date',
    DetailData_LastVolume: 'Volume',
    DetailData_MessageSeptic: 'Notice',
    DetailData_Material: 'Material type',
    DetailData_NumberOfChambers: 'Number of chambers',
    DetailData_TubeLength: 'Tube length',
    DetailData_ExtOrderId: 'Order id',
    DetailData_ExtOrderNr: 'External Order No',
    DetailData_Route: 'Route',
    DetailData_RouteName: 'RouteName',
    DetailData_OrderDate: 'Orderdate',
    DetailData_OrderType: 'OrderType',
    DetailData_OrderTypeText: 'Ordertype',
    DetailData_PASystem: 'PASystem',
    DetailData_AgreementLines: 'AgreementLines',
    DetailData_RoutelineId: 'RoutelineId',
    AgreementLineDetailData_PASystem: 'PASystem',
    AgreementLineDetailData_AgreementId: 'AgreementId',
    AgreementLineDetailData_AgreementLineId: 'AgreementLineId',
    AgreementLineDetailData_UnitId: 'UnitId',
    AgreementLineDetailData_Amount: 'Amount',
    AgreementLineDetailData_PhysicalAmount: 'PhysicalAmount',
    AgreementLineDetailData_Message: 'Plassering',
    AgreementLineDetailData_ExtOrderId: 'Order id',
    AgreementLineDetailData_LastChanged: 'Last changed',
    AgreementLineDetailData_DegLat: 'Latitude',
    AgreementLineDetailData_DegLon: 'Longitude',
    AgreementLineDetailData_ContainerId: 'ContainerId',
    AgreementLineDetailData_Fraction: 'Fraction',
    AgreementDetailData_PASystem: 'PASystem',
    AgreementDetailData_AgreementId: 'AgreementId',
    AgreementDetailData_Route: 'Route',
    AgreementDetailData_CustomerId: 'CustomerId',
    AgreementDetailData_Name: 'Name',
    AgreementDetailData_ContactName: 'ContactName',
    AgreementDetailData_Address1: 'Address 1',
    AgreementDetailData_Address2: 'Address 2',
    AgreementDetailData_PostalCode: 'PostalCode',
    AgreementDetailData_City: 'City',
    AgreementDetailData_Other: 'Other',
    AgreementDetailData_Message: 'Message',
    AgreementDetailData_Type: 'Type',
    AgreementDetailData_LastChanged: 'Last changed',
  },
  impersonationManager: {
    _selectUser: 'Select customer/user',
    _noOptionsMessage: 'No users found',
    _impersonate: 'Use',
    _stopImpersonation: 'Stop use',
    _selectUserPlaceholder: 'Select customer/user...',
  },
  userAdmin: {
    _noUsers: 'No users available',
    _userName: 'E-mail',
    _system: 'System',
    _contractor: 'Contractor',
    _contractorId: 'Contractor ID',
    _localAdmin: 'Local admin',
    _admin: 'Admin',
    _modules: 'Modulee',
    _add: 'Add',
    _update: 'Update',
    _cancel: 'Cancel',
    _newUser: 'Add new user',
    _userEditDialogTitle: 'Edit user',
    _userAddDialogTitle: 'Add new user',
    _password: 'Password',
    _passwordConfirmation: 'Confirm password',
    _confirmPasswordError: 'The passwords must be the same',
    _noCustomerOptionsMessage: 'No customers found',
    _noContractorOptionsMessage: 'No contractors found',
    _contractorsLoadingMessage: 'Contractors are loaded',
    _noModuleOptionsMessage: 'No modules found',
    _modulesLoadingMessage: 'Modules are loaded',
    _customerSelectorPlaceholder: 'Select customer...',
    _impersonationWarningPart1: 'You are logged in as ',
    _impersonationWarningPart2:
      ', and lack rights to use this page. Press the button below to return to the administrator function.',
  },
  vehicles: {
    _noVehicles: 'No cars available',
    _vehicles: 'No cars available',
    _system: 'System',
    _DTAShort: 'DTA',
    _DTALong: 'Digital Truck Assistant',
    _ajourShort: 'Ajour',
    _ajourLong: 'GPS Ajour',
    _vehicleId: 'Vehicle',
    _externalId: 'External Id',
    _description: 'Description',
    _phoneNr: 'SIM Nr',
    _driver: 'Driver',
    _password: 'Password',
    _repeatPassword: 'Repeat password',
    _edit: 'Change',
    _cancel: 'Cancel',
    _update: 'Update',
    _submit: 'Save',
    _addNewVehicle: 'Add new car',
    _editMessageSuccessful: 'Add new vehicle',
    _addMessageSuccessful: 'Vehicle added',
    _editMessageFailed: 'The error when editing vehicle',
    _addMessageFailed: 'Error when creating vehicle',
    _vehicleAlreadyExistingError: 'A vehicle with the same name already exists',
    _validationError: 'Only numbers and letters are allowed',
    _contractorSelectorPlaceholder: 'Select contractor...',
    _noContractorFound: 'No contractors available',
    _vehicleLoadingMessage: 'Vehicles are loaded',
  },
  vehicleSettings: {
    _copyFromVehicleButton: 'Copy from...',
    _copyFromVehicleDialogTitle: 'Copy settings from user',
    _copyButton: 'Copy',
    _copyFromVehicleError: 'Error copying user settings',
    _copyFromVehicleSuccess:
      'The settings have been copied from the selected user',
    _noSettings: 'No settings available',
    _parameter: 'Parameter',
    _value: 'Value',
    _addToMultiple: 'Add more vehicless?',
    _description: 'Description',
    _vehicle: 'Vehicle',
    _edit: 'Change',
    _cancel: 'Cancel',
    _multiselectError: 'Choose at least one vehicle',
    _update: 'Update',
    _submit: 'Save',
    _addNewSetting: 'Add new setting',
    _editMessageSuccessful: 'User setting updated',
    _addMessageSuccessful: 'User setting added',
    _deleteMessageSuccessful: 'User setting deleted',
    _editMessageFailed: 'Error when changing user settings',
    _addMessageFailed: 'Error creating user setting',
    _deleteMessageFailed: 'Error deleting user settings',
    _deleteConfirm: 'Are you sure you want to delete this user setting?',
    _vehicleSelectorPlaceholder: 'Select vehicle...',
    _selectVehicle: 'Vehicle',
    _selectCustomer: 'Customer',
    _selectContractor: 'Contractor',
    _settingTemplateSelectorPlaceholder: 'Select setting type...',
    _noSettingTemplateOptionsMessage: 'No settings found',
    _cannotAddNewSettingMessage:
      'Unable to add settings. All settings already exist.',
    _failedToLoadSettingTempletes:
      'Cannot change or add settings. Missing default settings.',
  },
  map: {
    _trackingLineLayer: 'Tracking Line',
    _trackInfo: 'GPS info',
    _trackInfoLine: 'GPS Track Log',
    _trackInfoPointsAndLines: 'Track log',
    _streetLayer: 'Base map',
    _satelliteLayer: 'Aerial photo',
    _propertyLayer: 'Land registry display',
    _wmsLayer: 'WMS',
    _nWMSLayersSelected: '{0} Area(s) have been selected',
    _lastUpdated: 'Updated',
    _trackingLineLegend: {
      _belowSix: '< 6 hours',
      _sixToTwelve: '6-12 hours',
      _twelveToTwentyFour: '12-24 hours',
      _overTwentyFour: '> 24 hours',
      _gray: 'Unknown',
    },
  },
  contractors: {
    _contractors: 'Contractors',
    _name: "Contractor's name",
    _externalId: 'External contractor ID',
    _addContractor: 'Add new contractor',
    _deleteConfirm: 'Are you sure you want to delete this contractor: ',
    _deleteSuccess: 'Contractor deleted',
    _addSuccess: 'Contractor added',
    _editSuccess: 'Contractor updated',
  },
  deviations: {
    _deviations: 'Deviation',
    _deviation: 'Deviation',
    _deviatonExtId: 'Deviation external Id',
    _category: 'Category',
    _deleteConfirm: 'Are you sure you want to delete this discrepancy: ',
    _addNew: 'Add deviations',
    _addSuccess: 'Deviation added',
    _editSuccess: 'Deviation updated',
    _deleteSuccess: 'Deviation deleted',
    _selectCategory: 'Select deviation category',
    _noCategory: 'No deviation category found',
    _deleteCategoryConfirm:
      'Are you sure you want to delete this deviation category: ',
    _categoryDeleteSuccess: 'Deviation category deleted',
    _deviationCategory: 'Deviation categories',
    _categoryName: 'Name of deviation category',
    _addCategorySuccess: 'Deviation category added',
    _editCategorySuccess: 'Deviation category updated',
    _addNewCategory: 'Add new deviation category',
  },
  customerRegister: {
    message: {
      _directMessageToCustomer: 'Send message to vehicle',
      _address: 'Address',
      _sender: 'Sender',
      _expires: 'Expires',
      _vehicle: 'Vehicle',
      _selectVehicle: 'Select vehicle',
      _title: 'Title',
      _message: 'Message',
      _send: 'Send message',
      _messageSent: 'Message sent',
      _noVehicle: 'No vehicle',
    },
    grid: {
      _infoText:
        'Here you can search for the customer and send a direct message about the customer to the vehicle.',
    },
  },
  newOrder: {
    days: {
      _monday: 'Monday',
      _tuesday: 'Tuesday',
      _wednesday: 'Wednesday',
      _thursday: 'Thursday',
      _friday: 'Friday',
      _saturday: 'Saturday',
      _sunday: 'Sunday',
    },
    frequency: {
      _oneTime: 'One occurrence',
      _weekly: 'Weekly',
      _weeks: 'Every # week',
    },
  },
  directMessages: {
    _messages: 'Messages',
    _address: 'Address',
    _sender: 'Sender',
    _expires: 'Expires',
    _vehicle: 'Vehicle',
    _title: 'Title',
    _message: 'Message',
    _date: 'Date',
    _closeButton: 'Close',
    _sent: 'Sent',
    _received: 'Processed',
    _read: 'Received',
    _status: 'Status',
    _newMessage: 'New message',
    _fromDate: 'From',
    _toDate: 'To',
    _vehicles: 'Vehicles',
    _chooseVehicles: 'Choose vehicle(s)',
    _statuses: 'Satuses',
    _chooseStatuses: 'Choose status(es)',
  },
  depots: {
    _deleteConfirm: 'Are you sure you want to delete this depot?',
    _deleteSuccess: 'Depot deleted',
    _depots: 'Depots',
    _newDepot: 'New depot',
    _id: 'Id',
    _customerId: 'Customer Id',
    _description: 'Description',
    _latitude: 'Latitude',
    _longitude: 'Longitude',
    _depotCreated: 'Depot created',
    _depotUpdated: 'Depot udpadet',
    _createNew: 'Create new depot',
    _update: 'Update depot',
    _submit: 'Save',
    _cancelButton: 'Cancel',
  },
  orderOverview: {
    _hideFilter: 'Hide filter',
    _showFilter: 'Show filter',
    _filter: 'Filter',
    _order: 'Order',
    _date: 'Date',
    _ordinaryDate: 'Ordinary date',
    _expires: 'Expires',
    _route: 'Route',
    _fractions: 'Fractions',
    _contractor: 'Contractor',
    _status: 'Status',
    _dateFilterInvalid: 'Date filter is invalid',
    _from: 'From',
    _to: 'To',
    _contractors: 'Contractors',
    _orderNumber: 'Order Number',
    _routes: 'Routes',
    _chooseRoutes: 'Choose route(s)',
  },
  fractions: {
    _deleteConfirm: 'Are you sure you want to delete this fraction?',
    _deleteSuccess: 'Fraction deleted',
    _newFraction: 'New fraction',
    _fraction: 'Fraction',
    _fractions: 'Fractions',
    _picture: 'Picture',
    _fractionCreated: 'Fraction created',
    _fractionUpdated: 'Fraction updated',
    _fractionName: 'Fraction name',
    _updateFraction: 'Update fraction',
    _submit: 'Save',
    _cancelButton: 'Cancel',
    _selectImage: 'Select an image',
  },
  units: {
    _units: 'Units',
    _unitUpadated: 'Unit updated',
    _unitName: 'Unit name',
    _commune: 'Commune',
    _fractions: 'Fractions',
    _selectFractions: 'Select fractions',
    _cancel: 'Cancel',
    _save: 'Save',
    _manageFractions: 'Manage fractions',
    _shortName: 'Liter',
    _freeBasedProduct: 'Fee-based product',
    _distance: 'Distance',
    _container: 'Container',
  },
};
