import { locale } from '../../../common/localization/localizationService';

export interface Repetition {
  label: string;
  value: number;
}

export interface Repetition {
  label: string;
  value: number;
}

export const daylist: Repetition[] = [
  {
    label: locale.newOrder.days._monday,
    value: 1,
  },
  {
    label: locale.newOrder.days._tuesday,
    value: 2,
  },
  {
    label: locale.newOrder.days._wednesday,
    value: 3,
  },
  {
    label: locale.newOrder.days._thursday,
    value: 4,
  },
  {
    label: locale.newOrder.days._friday,
    value: 5,
  },
  {
    label: locale.newOrder.days._saturday,
    value: 6,
  },
  {
    label: locale.newOrder.days._sunday,
    value: 7,
  },
];

export function getFrequency(): Repetition[] {
  const frequency: Repetition[] = [
    {
      label: locale.newOrder.frequency._oneTime,
      value: 1,
    },
    {
      label: locale.newOrder.frequency._weekly,
      value: 7,
    },
  ];
  
  const weeksString = locale.newOrder.frequency._weeks;
  for (let i = 2; i < 9; i++) {
    if(i==5 || i==7) continue;

    const weeklyRep: Repetition = {
      label: weeksString.replace('#', `${i}`),
      value: i*7,
    };
    frequency.push(weeklyRep);
  }
  return frequency;
}

export enum NewOrderConstants {
  CUSTOMER_GROUP_REQUEST = 'CUSTOMER_GROUP_REQUEST',
  CUSTOMER_GROUP_SUCCESS = 'CUSTOMER_GROUP_SUCCESS',
  CUSTOMER_GROUP_FAILURE = 'CUSTOMER_GROUP_FAILURE',
  FRACTIONS_REQUEST = 'FRACTIONS_REQUEST',
  FRACTIONS_SUCCESS = 'FRACTIONS_SUCCESS',
  FRACTIONS_FAILURE = 'FRACTIONS_FAILURE',
}
