import { Dispatch } from 'redux';
import GroupService, { ICustomerGroup } from '../../../services/groupService';
import { NewOrderConstants } from '../../../components/dashboard/newOrderModal/newOrderConstants';
import * as Actions from './newOrderAction';

export function createCustomerGroupRequest(): Actions.SetCustomerGroupList {
  return {
    type: NewOrderConstants.CUSTOMER_GROUP_REQUEST,
  };
}
export function createCustomerGroupSuccess(
  payload: Array<ICustomerGroup>
): Actions.SetCustomerGroupList.Success {
  return {
    type: NewOrderConstants.CUSTOMER_GROUP_SUCCESS,
    payload,
  };
}
export function createCustomerGroupFailure(
  error: Error
): Actions.SetCustomerGroupList.Failure {
  return {
    type: NewOrderConstants.CUSTOMER_GROUP_FAILURE,
    error,
  };
}

export function loadCustomerGroups(
  ignoreCache: boolean = false
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createCustomerGroupRequest());
    try {
      const result: Array<ICustomerGroup> = await GroupService.getGroups(
        ignoreCache
      );
      dispatch(createCustomerGroupSuccess(result));
    } catch (error) {
      dispatch(createCustomerGroupFailure(error));
    }
  };
}
