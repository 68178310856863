import React, { Component, ReactNode } from 'react';
import { IUser, IVehicle } from '../../models';
import PaneHeadline from '../shared/paneHeadline';
import { faEnvelope, faFilter } from '@fortawesome/free-solid-svg-icons';
import { locale } from '../../common/localization/localizationService';
import { ICustomerMessage } from '../../models/CustomerMessage.model';
import { DirectMessageGrid } from './DirectMessagesGrid';
import './messages.scss';
import { Spinner2 } from '../shared/spinner2';
import routes from '../../common/routes';
import { Link } from 'react-router-dom';
import InfoMessageModal from './InfoMessageModal';
import EventBus from '../../common/EventBus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import moment from 'moment';
import { IVehiclesState } from '../../redux/modules/vehicles/vehiclesState';
import Select from 'react-select';
import { filterVehicles } from '../vehicles/vehicleUtils';
import { FilterOption } from '../orderOverview/OrderOverview';

export interface IMessagesProps {
  authentication: IUser;
  getMessages: (ignoreCache?: boolean) => Promise<Record<string, any>>;
  messageList: Array<ICustomerMessage>;
  isLoading: boolean;
  directMessagesFilter: IDirectMessagesFilter;
  setFilter: (filter: IDirectMessagesFilter) => void;
  fetchVehicles: (ignoreCache?: boolean) => Promise<void>;
  vehicles: IVehiclesState;
}

export interface IMessagesState {
  messageDialog: {
    show: boolean;
    message: any;
  };
  isFilterVisible: boolean;
  isFilterInvalid: boolean;
  defaultVehicles: Array<FilterOption>;
}

export interface IDirectMessagesFilter {
  fromDate: Date;
  toDate: Date;
  vehicleIds: Array<string>;
  statusIds: Array<number>;
}

const statusOptions: Array<FilterOption> = [
  { label: locale.directMessages._sent, value: 0 },
  {
    label: locale.directMessages._received,
    value: 1,
  },
  {
    label: locale.directMessages._read,
    value: 2,
  },
];

export class Messages extends Component<IMessagesProps, IMessagesState> {
  public readonly state: IMessagesState = {
    messageDialog: {
      show: false,
      message: null,
    },
    isFilterVisible: true,
    isFilterInvalid: false,
    defaultVehicles: [],
  };

  public componentDidMount(): void {
    this.props.getMessages(true);
    this.props.fetchVehicles();
    EventBus.emit('fullScreenChange', true);
  }

  public componentWillUnmount(): void {
    EventBus.emit('fullScreenChange', false);
  }

  private openMessageDialog(message: any): void {
    this.setState({
      ...this.state,
      messageDialog: { message, show: true },
    });
  }

  private hideMessageDialog(): void {
    this.setState({
      ...this.state,
      messageDialog: { message: null, show: false },
    });
  }

  private showHideFilters(): void {
    this.setState({
      ...this.state,
      isFilterVisible: !this.state.isFilterVisible,
    });
  }

  private handleFromDateChange(event: any) {
    this.checkDateValidity(event.value, this.props.directMessagesFilter.toDate);
    this.props.setFilter({
      ...this.props.directMessagesFilter,
      fromDate: event.value,
    });
  }

  private handleToDateChange(event: any) {
    this.checkDateValidity(
      this.props.directMessagesFilter.fromDate,
      event.value
    );
    this.props.setFilter({
      ...this.props.directMessagesFilter,
      toDate: event.value,
    });
  }

  private checkDateValidity(from: Date, to: Date): void {
    if (moment(from).unix() > moment(to).unix()) {
      this.setState({ ...this.state, isFilterInvalid: true });
    } else {
      this.setState({ ...this.state, isFilterInvalid: false });
    }
  }

  private getVehicles(vehicles: Array<IVehicle>, user: IUser): Array<IVehicle> {
    let filteredVehicles: Array<IVehicle> = [];
    if (vehicles?.length) {
      if (user.impersonatedUser) {
        return vehicles;
      }

      const customerId = user.customerId;
      if (user.isAdmin) {
        filteredVehicles = filterVehicles(vehicles, { customerId });
      } else {
        const contractorId = user.contractorId;
        filteredVehicles = filterVehicles(vehicles, {
          customerId,
          contractorId,
        });
      }
      return filteredVehicles;
    } else {
      return filteredVehicles;
    }
  }

  private filterByVehicle(selectedOptions: Array<FilterOption>): void {
    if (selectedOptions) {
      const selectedIds = selectedOptions.map((option) => option.label);
      this.props.setFilter({
        ...this.props.directMessagesFilter,
        vehicleIds: selectedIds,
      });
    }
  }

  private filterByStatus(selectedOptions: Array<FilterOption>): void {
    if (selectedOptions) {
      const selectedIds = selectedOptions.map((option) => option.value);
      this.props.setFilter({
        ...this.props.directMessagesFilter,
        statusIds: selectedIds,
      });
    }
  }

  private filterMessages(
    messageList: Array<ICustomerMessage>,
    filter: IDirectMessagesFilter
  ): Array<ICustomerMessage> {
    let allMessages = messageList;
    if (filter.fromDate && filter.toDate) {
      const filteredMessages: Array<ICustomerMessage> = [];
      const fromTime = new Date(filter.fromDate).setHours(0, 0, 0, 0);
      const toTime = new Date(filter.toDate).setHours(23, 59, 59, 59);
      allMessages.forEach((message) => {
        const messageTime = new Date(message.date).getTime();
        if (messageTime > fromTime && messageTime < toTime) {
          filteredMessages.push(message);
        }
      });
      allMessages = filteredMessages;
    }
    if (filter.vehicleIds.length > 0) {
      const messages = allMessages.filter((message) =>
        filter.vehicleIds.includes(message.vehicleId)
      );
      allMessages = messages;
    }
    if (filter.statusIds.length > 0) {
      console.log(allMessages, filter.statusIds);
      const messages = allMessages.filter((message) => {
        console.log(filter.statusIds.includes(message.status));
        return filter.statusIds.includes(message.status);
      });
      allMessages = messages;
    }
    return allMessages;
  }

  public render(): ReactNode {
    const {
      messageList,
      isLoading,
      directMessagesFilter,
      vehicles,
      authentication,
    } = this.props;
    const customerVehicles = this.getVehicles(
      vehicles.vehiclesList,
      authentication
    );
    const vehicleOptions = customerVehicles.map((vehicle) => {
      return { value: vehicle.vehicleId, label: vehicle.vehicleId };
    });
    const defaultVehicles = vehicleOptions.filter((vehicle) => {
      return directMessagesFilter.vehicleIds.includes(vehicle.value);
    });
    const defaultStatuses = statusOptions.filter((status) => {
      return directMessagesFilter.statusIds.includes(status.value);
    });
    const filteredMessages = this.filterMessages(
      messageList,
      directMessagesFilter
    );
    return (
      <div className="direct-messages">
        <div className="row">
          <PaneHeadline
            titleText={locale.directMessages._messages}
            titleIcon={faEnvelope}
            className="col-sm-6"
          />
          <div className="col-sm-6 text-right mb-3">
            <button
              className={`show-filters-button ${
                this.state.isFilterVisible ? 'filter-active' : ''
              }`}
              onClick={() => this.showHideFilters()}
            >
              <FontAwesomeIcon icon={faFilter} />
            </button>
            <Link className="btn btn-outline-dark" to={routes.customerSearch}>
              {locale.directMessages._newMessage}
            </Link>
          </div>
        </div>
        <Spinner2 show={isLoading} />
        <div className="direct-messages-content">
          <DirectMessageGrid
            data={filteredMessages}
            openMessageDialog={this.openMessageDialog.bind(this)}
            clasName={this.state.isFilterVisible ? 'filter-open' : ''}
          />
          <div
            className={`direct-messages-filter ${
              this.state.isFilterVisible ? 'open' : ''
            }`}
          >
            <div className="filter-column">
              <label>{locale.directMessages._fromDate}</label>
              <DatePicker
                value={new Date(directMessagesFilter.fromDate)}
                onChange={this.handleFromDateChange.bind(this)}
                format={locale.general._fullDateFormat}
                className={`date-picker ${
                  this.state.isFilterInvalid ? 'input-invalid' : ''
                }`}
                popupSettings={{ popupClass: 'date-picker-popup' }}
              />
              <label>{locale.directMessages._toDate}</label>
              <DatePicker
                value={new Date(directMessagesFilter.toDate)}
                onChange={this.handleToDateChange.bind(this)}
                format={locale.general._fullDateFormat}
                className={`date-picker ${
                  this.state.isFilterInvalid ? 'input-invalid' : ''
                }`}
                popupSettings={{ popupClass: 'date-picker-popup' }}
              />
              <label>{locale.directMessages._vehicles}</label>
              <Select
                options={vehicleOptions}
                value={defaultVehicles}
                onChange={this.filterByVehicle.bind(this)}
                isMulti
                isDisabled={
                  vehicleOptions.length < 2 && defaultVehicles.length < 1
                }
                placeholder={locale.directMessages._chooseVehicles}
                className="input-field react-select"
              />
              <label>{locale.directMessages._statuses}</label>
              <Select
                options={statusOptions}
                value={defaultStatuses}
                onChange={this.filterByStatus.bind(this)}
                isMulti
                placeholder={locale.directMessages._chooseStatuses}
                className="input-field react-select"
              />
              <div className="filter-button-wrapper">
                {this.state.isFilterInvalid && (
                  <div className="filter-invalid">
                    {locale.orderOverview._dateFilterInvalid}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {this.state.messageDialog.show && (
          <InfoMessageModal
            onHide={this.hideMessageDialog.bind(this)}
            show={this.state.messageDialog.show}
            selectedMessage={this.state.messageDialog.message}
          />
        )}
      </div>
    );
  }
}

export default Messages;
