import { connect } from 'react-redux';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import Units from './Units';
import { bindActionCreators, Dispatch } from 'redux';
import { loadUnits } from '../../redux/modules/units/unitsCreators';
import { getAllFractions } from '../../redux/modules/order/orderActions';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import { FractionsConstants } from '../../redux/modules/fractions/fractionsConstants';

function mapStateToProps(state: IRootState) {
  const fractionLoading = createLoadingSelector(
    FractionsConstants.GET_ALL_FRACTIONS_REQUEST
  );
  return {
    impersonatedUser: state.authentication.impersonatedUser,
    authentication: state.authentication as unknown as IUser,
    units: state.unit.units,
    isLoading: state.unit.isLoading || fractionLoading(state),
    fractions: state.order.allFractions,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUnits: bindActionCreators(loadUnits, dispatch),
    getFractions: bindActionCreators(getAllFractions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Units);
