import messagesConstants from './directMessagesConstants';
import loadingActions from '../loading/loadingActions';
import DirectMessageService from '../../../services/DirectMessageService';

function getMessages(ignoreCache = false, silent = false) {
  return (dispatch) => {
    if (!silent) {
      dispatch(request());
    }

    return DirectMessageService.getDirectMessages(ignoreCache)
      .then(
        (messages) => {
          dispatch(success(messages));
        },
        (error) => dispatch(failure(JSON.stringify(error)))
      )
      .finally(() => {
        dispatch(
          loadingActions.removeLoadingInProgress(messagesConstants.GET_MESSAGES)
        );
      });
  };

  function request() {
    return { type: messagesConstants.GET_MESSAGES };
  }

  function success(messages) {
    return { type: messagesConstants.GET_MESSAGES_SUCCESS, messages };
  }

  function failure(error) {
    return { type: messagesConstants.GET_MESSAGES_FAILURE, error };
  }
}

function setDirectMessagesFilter(filter) {
  return (dispatch) => {
    dispatch(setFilter(filter));
  };
  function setFilter(filter) {
    return {
      type: messagesConstants.SET_DIRECT_MESSAGES_FILTER,
      filter,
    };
  }
}

export default {
  getMessages,
  setDirectMessagesFilter,
};
