import { INewOrderState } from './newOrderState';
import { All as Actions } from './newOrderAction';
import { NewOrderConstants } from '../../../components/dashboard/newOrderModal/newOrderConstants';

const initialState: INewOrderState = {
  customerGroups: [],
  isCustomerGroupsLoading: false,
  errorMessage: null,
};

export function newOrder(
  state = initialState,
  action: Actions
): INewOrderState {
  switch (action.type) {
    case NewOrderConstants.CUSTOMER_GROUP_REQUEST: {
      return {
        ...state,
        isCustomerGroupsLoading: true,
      };
    }
    case NewOrderConstants.CUSTOMER_GROUP_SUCCESS: {
      return {
        ...state,
        isCustomerGroupsLoading: false,
        customerGroups: action.payload,
      };
    }
    case NewOrderConstants.CUSTOMER_GROUP_FAILURE: {
      return {
        ...state,
        isCustomerGroupsLoading: false,
        errorMessage: action.error.message,
      };
    }
    default:
      return state;
  }
}
