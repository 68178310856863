import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  GridColumn as Column,
  GridColumnMenuFilter,
  GridNoRecords,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import { locale } from '../../common/localization/localizationService';
import ColumnMenu from '../shared/ColumnMenu';
import InfoButtonCell from '../shared/infoButtonCell/InfoButtonCell';
import routes from '../../common/routes';
import {
  getOrderTypeName,
  getContainerOrderRouteName,
  getContainerOrderAddress,
} from './ContainerOrdersUtility';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import CommandCell from '../userAdmin/CommandCell';

class ContainerOrdersGrid extends Component {
  constructor(props) {
    super(props);

    this.dataStateChange = this.dataStateChange.bind(this);
    this.InfoButtonCell = this.InfoButtonCell.bind(this);
  }

  static propTypes = {
    onRowClick: PropTypes.func.isRequired,
    addVehicle: PropTypes.func.isRequired,
    containerOrders: PropTypes.array,
    selectedOrderId: PropTypes.number,
    className: PropTypes.string,
  };

  state = { dataState: {} };

  static processOrders(containerOrders, selectedOrderId, dataState) {
    const extendedOrders = containerOrders.map((order) => {
      const agreementLine = order.agreementLine;
      const unit = agreementLine && agreementLine.unit;

      return {
        ...order,
        date: new Date(order.date),
        expires: new Date(order.expires),
        selected: order.orderId === selectedOrderId,
        routeName: getContainerOrderRouteName(order),
        route: order.name,
        address: getContainerOrderAddress(order),
        unitName: unit && unit.name,
        orderTypeName: getOrderTypeName(order.orderType),
        fraction: agreementLine && agreementLine.fraction,
      };
    });

    return process(extendedOrders, dataState);
  }

  dataStateChange(event) {
    this.setState({ dataState: event.data });
  }

  columnProps(field) {
    return {
      field: field,
      columnMenu: ColumnMenu,
      headerClassName: this.isColumnActive(field, this.state.dataState)
        ? 'active'
        : '',
      title: locale.containerOrdersGrid['_' + field],
    };
  }

  isColumnActive(field, dataState) {
    return GridColumnMenuFilter.active(field, dataState.filter);
  }

  InfoButtonCell(gridProps) {
    return (
      <InfoButtonCell
        linkToUrl={`${routes.callordersPath}/${gridProps.dataItem.orderId}`}
        {...gridProps}
      />
    );
  }

  AddVehicleCell(props) {
    return (
      <CommandCell {...props} edit={this.props.addVehicle} icon={faTruck} />
    );
  }

  render() {
    const { containerOrders, selectedOrderId, onRowClick, className } =
      this.props;
    const result = ContainerOrdersGrid.processOrders(
      containerOrders,
      selectedOrderId,
      this.state.dataState
    );
    return (
      <Grid
        {...this.state.dataState}
        className={`mb-3 call-order-grid ${className}`}
        data={result}
        selectedField="selected"
        scrollable={'none'}
        onRowClick={onRowClick}
        sortable
        onDataStateChange={this.dataStateChange}
      >
        <GridNoRecords>{locale.containerOrders._noOrders}</GridNoRecords>
        <Column {...this.columnProps('orderId')} width={80} />
        <Column {...this.columnProps('routeName')} />
        <Column {...this.columnProps('route')} />
        <Column {...this.columnProps('address')} />
        <Column {...this.columnProps('unitName')} />
        <Column {...this.columnProps('orderTypeName')} />
        <Column {...this.columnProps('vehicleId')} />
        <Column
          {...this.columnProps('date')}
          filter="date"
          format="{0:dd.MM.yyyy}"
          width={80}
        />
        <Column
          {...this.columnProps('expires')}
          filter="date"
          format="{0:dd.MM.yyyy}"
          width={80}
        />
        <Column width={40} cell={this.InfoButtonCell} />
        <Column width={40} cell={this.AddVehicleCell.bind(this)} />
      </Grid>
    );
  }
}

export default ContainerOrdersGrid;
