import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import routeOptimalizationActions from '../../../redux/modules/routeOptimalization/routeOptimalizationActions';
import WorklistResults from './WorklistResults';

function mapStateToProps(state) {
  return {
    worklists: state.routeOptimalization.worklists,
    depotStopLists: state.routeOptimalization.depotStopLists,
    selectedWorklist: state.routeOptimalization.selectedWorklist,
    unassignedStops: state.routeOptimalization.unassignedStops,
    avaibleServiceOrders: state.routeOptimalization.avaibleServiceOrders,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    routeOptimalizationActions: bindActionCreators(
      routeOptimalizationActions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorklistResults);
