import React, { useState } from 'react';
import { IUnit } from '../../models';
import { IFraction } from '../../models/Fraction.model';
import ColumnMenu from '../shared/ColumnMenu';
import {
  Grid,
  GridColumn,
  GridColumnMenuFilter,
} from '@progress/kendo-react-grid';
import { process } from '@progress/kendo-data-query';
import CommandCell from '../userAdmin/CommandCell';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { pictureStorageUrl } from '../../appConfig';
import { locale } from '../../common/localization/localizationService';
import { Checkbox } from 'primereact/checkbox';

export interface IUnitGridProps {
  data: Array<IUnit>;
  className?: string;
  editUnit: (dataItem: IUnit) => void;
}

export interface IUnitGridState {
  dataState: Record<string, any>;
}

export const UnitGrid: React.FC<IUnitGridProps> = (props: IUnitGridProps) => {
  let pictureCounter = 1;

  const [dataState, setDataState] = useState({
    sort: [{ dir: 'asc', field: 'orderId' }],
    take: 26,
    skip: 0,
  });
  const dataStateChange = (event: any) => {
    setDataState(event.data);
  };
  const columnProps = (field: string) => {
    if (field === 'fractions') {
      return {
        field: field,
      };
    } else {
      return {
        field: field,
        columnMenu: ColumnMenu,
        headerClassName: isColumnActive(field, dataState) ? 'active' : '',
      };
    }
  };

  const UpdateUnitCell = (columnProps: any) => {
    return (
      <>
        <CommandCell {...columnProps} edit={props.editUnit} icon={faEdit} />
      </>
    );
  };

  const isColumnActive = (field: string, dataState: any) => {
    return GridColumnMenuFilter.active(field, dataState.filter);
  };

  const getFractionsField = (gridProps: any) => {
    return (
      <td className="picture-cell">
        {gridProps.dataItem.fractions &&
          gridProps.dataItem.fractions
            .filter(
              (frac: IFraction, index: number, self: any) =>
                index === self.findIndex((t: any) => t.name === frac.name)
            )
            .map((frac: IFraction) => (
              <img
                className="fraction-picture"
                title={frac.name}
                src={`${pictureStorageUrl}${frac.url}`}
                key={`frac-pic-${pictureCounter++}`}
              />
            ))}
      </td>
    );
  };

  const getContainerField = (gridProps: any) => {
    return (
      <td className="unit-container-cell">
        <Checkbox checked={gridProps.dataItem.container} disabled />
      </td>
    );
  };

  const result = process(props.data, dataState);
  return (
    <>
      <Grid
        {...dataState}
        className={`unit-grid ${props.className}`}
        data={result}
        selectedField="selected"
        pageable
        resizable
        pageSize={26}
        onDataStateChange={dataStateChange}
        sortable
      >
        <GridColumn {...columnProps('unitId')} title="ID" sortable width={95} />
        <GridColumn
          {...columnProps('name')}
          title={locale.units._unitName}
          sortable
        />
        <GridColumn
          {...columnProps('shortName')}
          title={locale.units._shortName}
          sortable
          width={95}
        />
        <GridColumn
          {...columnProps('commune')}
          title={locale.units._commune}
          sortable
          width={95}
        />
        <GridColumn
          {...columnProps('ajour')}
          title={locale.units._distance}
          width={95}
          sortable
        />
        <GridColumn
          {...columnProps('nonFysical')}
          title={locale.units._freeBasedProduct}
          sortable
          width={130}
        />
        <GridColumn
          {...columnProps('container')}
          title={locale.units._container}
          sortable
          cell={getContainerField}
          width={95}
        />
        <GridColumn
          {...columnProps('fractions')}
          title={locale.units._fractions}
          sortable
          cell={getFractionsField}
        />
        <GridColumn width="40px" cell={UpdateUnitCell} />
      </Grid>
    </>
  );
};
