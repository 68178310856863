import { createSelector } from 'reselect';
import { ICustomerGroup } from '../../../services/groupService';
import { IRootState } from '../../state';
import { IFraction } from '../../../models/Fraction.model';

export const getCustomerGroups = createSelector(
  [(state: IRootState) => state.newOrder.customerGroups],
  (list: Array<ICustomerGroup>) => list
);

export const getFractions = createSelector(
  [(state: IRootState) => state.fraction.fractions],
  (list: Array<IFraction>) => list
);
