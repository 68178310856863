/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { locale } from '../../common/localization/localizationService';

interface FractionPictureSelectorProps {
  imageUrls: string[];
  onSelect: (url: string) => void;
  initialSelectedImage?: string;
}

const FractionPictureSelector: React.FC<FractionPictureSelectorProps> = ({
  imageUrls,
  onSelect,
  initialSelectedImage,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggleOptions = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    if (initialSelectedImage && initialSelectedImage !== selectedImage) {
      setSelectedImage(initialSelectedImage);
      onSelect(initialSelectedImage);
    }
  }, [initialSelectedImage, selectedImage, onSelect]);

  const handleSelect = (url: string) => {
    if (url !== selectedImage) {
      setSelectedImage(url);
      onSelect(url);
      toggleOptions();
    }
  };

  return (
    <>
      {isOpen && (
        <div onClick={toggleOptions} className="fraction-select-overlay"></div>
      )}
      <div className="image-select">
        <div onClick={toggleOptions} className="image-preview">
          {selectedImage ? (
            <img
              src={selectedImage}
              alt="Selected"
              className="selected-image fraction-image"
            />
          ) : (
            <span>{locale.fractions._selectImage}</span>
          )}
        </div>
        <div className={`image-options ${isOpen ? 'open' : ''}`}>
          {imageUrls.map((url, index) => (
            <div
              key={index}
              className={`image-option ${
                selectedImage === url ? 'selected' : ''
              }`}
              onClick={() => handleSelect(url)}
              aria-selected={selectedImage === url}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => e.key === 'Enter' && handleSelect(url)}
            >
              <img
                src={url}
                alt={`Option ${index + 1}`}
                className="fraction-image"
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default FractionPictureSelector;
