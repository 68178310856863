import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import UserAdminGrid from './UserAdminGrid';
import { locale } from '../../common/localization/localizationService';
import './user-admin.scss';
import UserEditDialog from './UserEditDialog';
import Spinner from '../shared/spinner/Spinner';
import PaneHeadline from '../shared/paneHeadline';
import { faUser } from '@fortawesome/free-solid-svg-icons';

class UserAdmin extends PureComponent {
  constructor(props) {
    super(props);

    this.openUserEditDialog = this.openUserEditDialog.bind(this);
    this.createNewUser = this.createNewUser.bind(this);
    this.hideUserEditDialog = this.hideUserEditDialog.bind(this);
    this.saveUser = this.saveUser.bind(this);

    this.state = {
      selectedCustomerId: this.props.authentication.impersonatedCustomerId ? 
      this.props.authentication.impersonatedCustomerId : 
      this.props.authentication.customerId,
      userEditDialogOptions: { show: false },
    };
  }

  static propTypes = {
    userActions: PropTypes.object.isRequired,
    users: PropTypes.object.isRequired,
    customerActions: PropTypes.object.isRequired,
    moduleActions: PropTypes.object.isRequired,
    modules: PropTypes.object.isRequired,
    contractorActions: PropTypes.object.isRequired,
    contractors: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    vehicleTrackingActions: PropTypes.object.isRequired,
    isVehiclesLoading: PropTypes.bool.isRequired,
    settingsActions: PropTypes.object.isRequired,
    authActions: PropTypes.object.isRequired,
    authentication: PropTypes.object,
  };

  componentDidMount() {
      this.props.userActions.getUsers(this.state.selectedCustomerId, true);
      this.props.contractorActions.getContractors(
        this.state.selectedCustomerId
      );
      this.props.moduleActions.getModules(this.state.selectedCustomerId);
    
  }

  openUserEditDialog(user) {
    this.setState({
      userEditDialogOptions: {
        show: true,
        isNew: !user,
        user: {
          ...user,
          customerId: this.state.selectedCustomerId,
        },
      },
    });
  }

  createNewUser() {
    this.openUserEditDialog();
  }

  saveUser(user, isNew) {
    const { userActions } = this.props;
    this.setState({ userEditDialogOptions: { show: false } });

    if (isNew) {
      userActions.createUser(user);
    } else {
      userActions.updateUser(user);
    }
  }

  hideUserEditDialog() {
    this.setState({ userEditDialogOptions: { show: false } });
  }

  render() {
    const {
      users,
      customers,
      modules,
      contractors,
      isLoading,
      isVehiclesLoading,
    } = this.props;
    const { selectedCustomerId } = this.state;

    const customerContractors = contractors[selectedCustomerId] || [];
    const customerModules = modules[selectedCustomerId] || [];
    const customerUsers = users[selectedCustomerId] || [];
   
    return (
      <div className="user-admin">
        {isVehiclesLoading && <Spinner />}

        <div className="row">
          <PaneHeadline
            titleText={locale.general._userAdmin}
            titleIcon={faUser}
            className="col-8"
          />
          <div className="col-4 new-user-button-col">
            {this.props.authentication.isAdmin && (
              <button
                className="btn btn-outline-dark"
                onClick={this.createNewUser}
              >
                {locale.userAdmin._newUser}
              </button>
            )}
          </div>
        </div>

        <div className="user-admin-grid-wrapper">
          {isLoading && <Spinner />}
          <UserAdminGrid
            users={customerUsers}
            startEditingUser={this.openUserEditDialog}
            contractors={customerContractors}
            authentication={this.props.authentication}
          />
        </div>

        <UserEditDialog
          {...this.state.userEditDialogOptions}
          save={this.saveUser}
          onHide={this.hideUserEditDialog}
          modules={customerModules}
          contractors={customerContractors}
          isAdmin={this.props.authentication.isAdmin}
        />
      </div>
    );  
  }
}

export default UserAdmin;
