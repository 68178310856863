import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as orderActions from '../../redux/modules/order/orderActions';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import { orderConstants } from '../../redux/modules/order/orderConstants';
import OrderOverview from './OrderOverview';
import { IRootState } from '../../redux/state';
import { IUser } from '../../models';
import contractorActions from '../../redux/modules/contractor/contractorActions';
import { getRoutes } from '../../redux/modules/route/routeSelectors';
import { loadRoutes } from '../../redux/modules/route/routeCreators';

const isOrdersLoading = createLoadingSelector(
  orderConstants.GET_ORDER_OVERVIEW
);

function mapStateToProps(state: IRootState) {
  return {
    isLoading: isOrdersLoading(state),
    orderOverview: state.order.orderOverview,
    impersonatedUser: state.authentication.impersonatedUser,
    authentication: state.authentication as unknown as IUser,
    contractors: state.contractor.contractors,
    allFractions: state.order.allFractions,
    orderOverviewFilter: state.order.orderOverviewFilter,
    statuses: state.order.statuses,
    routes: getRoutes(state),
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    orderActions: bindActionCreators(orderActions, dispatch),
    getContractors: bindActionCreators(
      contractorActions.getContractors,
      dispatch
    ),
    loadRoutes: bindActionCreators(loadRoutes, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderOverview);
