/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { wasteManagementUrls } from '../appConfig';
import { IFraction } from '../models/Fraction.model';
import wasteManagementClient from './wasteManagementClient';

export default class FractionService {
  static getAllFractions(ignoreCache = false): Promise<Array<IFraction>> {
    return wasteManagementClient
      .get(wasteManagementUrls.fractions, { cache: { ignoreCache } })
      .then((resp) => {
        if (resp?.data && resp.status === 200) {
          return resp.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static async createFraction(
    newFraction: IFraction,
    ignoreCache = false
  ): Promise<IFraction> {
    try {
      const response = await wasteManagementClient.post(
        wasteManagementUrls.fractions,
        newFraction,
        { cache: { ignoreCache } }
      );
      if (response?.status === 200 && response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  static async updateFraction(
    updateFraction: IFraction,
    ignoreCache = false
  ): Promise<IFraction> {
    try {
      const response = await wasteManagementClient.put(
        wasteManagementUrls.fractions,
        updateFraction,
        { cache: { ignoreCache } }
      );
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  static async deleteFraction(
    fractionId: number,
    customerId: number,
    ignoreCache = false
  ): Promise<any> {
    try {
      const response = await wasteManagementClient.delete(
        wasteManagementUrls.fractions +
          `/fractionId/${fractionId}/customerId/${customerId}`,
        { cache: { ignoreCache } }
      );
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  static getFractionPictures(ignoreCache = false): Promise<Array<any>> {
    return wasteManagementClient
      .get(wasteManagementUrls.fractions + '/images', {
        cache: { ignoreCache },
      })
      .then((resp) => {
        if (resp?.data && resp.status === 200) {
          return resp.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }
}
