import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import routeOptimalizationActions from '../../../redux/modules/routeOptimalization/routeOptimalizationActions';
import PropTypes from 'prop-types';
import { locale } from '../../../common/localization/localizationService';
import AddCustomerModal from '../AddCustomerModal';
import RouteGrid from '../RouteGrid';
import './importedRouteGrid.scss';
import DuplicateRoutesWarning from './DuplicateRoutesWarning';
import { groupByReduce } from '../../../services/listService';

class ImportedRouteGrid extends Component {
  constructor(props) {
    super(props);

    this.showAddCustomerModal = this.showAddCustomerModal.bind(this);
    this.hideAddCustomerModal = this.hideAddCustomerModal.bind(this);
    this.handleRouteAdded = this.handleRouteAdded.bind(this);
    this.handleRouteRemoved = this.handleRouteRemoved.bind(this);
  }

  state = {
    addCustomerModalVisible: false,
  };

  static getNewRowDefaultDate(importedRoutes) {
    if (!importedRoutes || importedRoutes.length === 0) {
      return null;
    }

    const date = importedRoutes[0].date;
    if (importedRoutes.every((r) => r.date.getTime() === date.getTime())) {
      return date;
    }

    return null;
  }

  showAddCustomerModal() {
    this.setState({ addCustomerModalVisible: true });
  }

  hideAddCustomerModal() {
    this.setState({ addCustomerModalVisible: false });
  }

  handleRouteAdded(customer) {
    const { routeOptimalizationActions, agreements } = this.props;
    const { agreement: agreementOption, ...rest } = customer;

    const relatedAgreement = agreements.find(
      (a) => a.agreementId === agreementOption.value
    );

    const route = {
      customerName: agreementOption.label,
      gln: agreementOption.value,
      latitude: relatedAgreement.degLat,
      longitude: relatedAgreement.degLon,
      ...rest,
    };

    routeOptimalizationActions.addImportedRoute(route);
    this.hideAddCustomerModal();
  }

  handleRouteRemoved(route) {
    const { routeOptimalizationActions } = this.props;
    routeOptimalizationActions.removeImportedRoute(
      route.gln,
      route.orderNumber
    );
  }

  getDuplicateRoutes() {
    const { importedRoutes } = this.props;
    if (!importedRoutes || importedRoutes.length === 0) {
      return null;
    }

    const groupedRoutes = groupByReduce(
      importedRoutes,
      (route) => `${route.gln}-${route.serial}`
    );

    const duplicates = [];
    for (let [key, routes] of Object.entries(groupedRoutes)) {
      if (routes.length > 1) {
        duplicates.push(key);
      }
    }

    if (duplicates.length === 0) {
      return null;
    }

    const glns = new Set();
    const serials = new Set();

    duplicates.forEach((key) => {
      const [gln, serial] = key.split('-');
      glns.add(gln);
      serials.add(serial);
    });

    return {
      glns: Array.from(glns),
      serials: Array.from(serials),
    };
  }

  render() {
    const { importedRoutes } = this.props;
    const { addCustomerModalVisible } = this.state;

    const newRowDefaultDate =
      ImportedRouteGrid.getNewRowDefaultDate(importedRoutes);
    const currentAgreements = importedRoutes.map((route) => route.gln);

    const duplicateGlns = this.getDuplicateRoutes();

    const routes = importedRoutes.map((route) => {
      if (!route.orderNumber && route.serial) {
        route.orderNumber = route.serial;
      }
      return route;
    });

    return (
      <div className="imported-route-grid">
        <div className="grid-headline-row">
          <DuplicateRoutesWarning duplicateGlns={duplicateGlns} />
          <button
            className="btn btn btn-outline-dark btn-slim add-customer-button"
            onClick={this.showAddCustomerModal}
          >
            {locale.routeOptimalizationWizard._addCustomer}
          </button>
        </div>
        <RouteGrid routes={routes} removeItem={this.handleRouteRemoved} />
        {addCustomerModalVisible && (
          <AddCustomerModal
            onClose={this.hideAddCustomerModal}
            currentAgreements={currentAgreements}
            defaultDate={newRowDefaultDate}
            onSubmit={this.handleRouteAdded}
          />
        )}
      </div>
    );
  }
}

ImportedRouteGrid.propTypes = {
  importedRoutes: PropTypes.array.isRequired,
  agreements: PropTypes.array.isRequired,
  onRouteAdded: PropTypes.func,
  routeOptimalizationActions: PropTypes.object.isRequired,
};

ImportedRouteGrid.defaultProps = {
  importedRoutes: [],
  onRouteAdded: () => {},
  routeOptimalizationActions: {},
};

function mapStateToProps(state) {
  return {
    importedRoutes: state.routeOptimalization.importedRoutes,
    agreements: state.agreement.agreements,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    routeOptimalizationActions: bindActionCreators(
      routeOptimalizationActions,
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportedRouteGrid);
