export const localeRo = {
  general: {
    _administration: 'Administrație',
    _username: 'Nume de utilizator',
    _password: 'Parolă',
    _date: 'Data',
    _time: 'Ora',
    _orderOverview: 'Prezentare generală a comenzii',
    _reports: 'Rapoarte',
    _kg: 'kg',
    _ton: 'tonă',
    _callOrder: 'Comenzi apelate',
    _serviceList: 'Liste de lucru',
    _impersonationManager: 'Setează client/utilizator',
    _userAdmin: 'Administrarea utilizatorilor',
    _routeAdmin: 'Rute',
    _backendError: 'A apărut o eroare în timpul cererii',
    _accessError: 'Nu aveți acces la această pagină',
    _vehicles: 'Mașini',
    _vehicleSettings: 'Setări administrative',
    _zoomIn: 'Mărește',
    _zoomOut: 'Micșorează',
    _warning: 'Avertisment!',
    _customerRegister: 'Registrul clienților',
    _commune: 'Municipiul',
    _logOut: 'Sign out',
    _selectLanguage: 'Selectați limba',
    _unitDesrciption: 'Tipul dispozitivului',
    _fullDateFormat: 'dd.MM.yyyy',
    _fullDateWithTime: 'dd.MM.yyyy HH:mm:ss',
    _fullMomentFormat: 'DD.MM.YYYY',
    _fullMomentWithTime: 'DD.MM.YYYY HH:mm:ss',
    _noRecords: 'Nu sunt disponibile înregistrări.',
    _dashboard: 'Dashboard',
    _decimalSeparator: ',',
    validation: {
      _isRequired: 'este necesar',
      _stringMaxCharA: 'trebuie să fie cel mult',
      _stringMaxCharB: 'caractere',
      _emailNotValid: 'is geen geldig e-mailadres!',
      _lessOrEqual: 'trebuie să fie mai mic sau egal cu',
      _greaterOrEqual: 'trebuie să fie mai mare sau egal cu',
      _laterThan: 'trebuie să fie mai târziu decât',
      _earlierThan: 'trebuie să fie mai devreme decât',
    },
  },
  login: {
    _usernameEmail: 'E-mail',
    _usernameRequired: 'Numele de utilizator este obligatoriu!',
    _passwordRequired: 'Parola este obligatorie!',
    _usernamePasswordInvalid: 'Nume de utilizator sau parolă incorecte',
    _invalidEmail: 'Numele de utilizator nu este o adresă de e-mail validă!',
    _submit: 'Conectare',
    _welcome: 'Bine ați venit la GPS Realtime WMS',
    _pressLogin: 'Apăsați Conectare pentru a accesa portalul',
    _ifAssistance: 'Dacă aveți nevoie de asistență,',
    _pleaseContact: 'vă rugăm să contactați suportul var',
  },
  tooltip: {
    _deviceId: 'Număr unitate',
    _altitude: 'Înălțime',
    _speed: 'Viteză',
    _temperature: 'Temperatură',
    _orderId: 'Nr. comandă',
    _address: 'Adresă',
    _routeName: 'Rută',
  },
  orders: {
    _update: 'Actualizat',
    _start: 'Start',
    _route: 'Rută',
    _vehicleId: 'Vehicul',
    _orderId: 'Comandă',
    _status: 'Stare',
    _notStarted: 'Neînceput',
    _now: 'Acum',
    _clearFilter: 'Șterge filtrul',
    _fraction: 'Fracțiune',
    _showFilters: 'Afișează filtrele',
    _chooseContractor: 'Alege transportatorul',
    _chooseFraction: 'Alege fracțiunea',
    _chooseStatus: 'Alegeți statutul',
    _expires: 'Expiră',
    _date: 'Dată',
    _ordinaryDate: 'Dată obișnuită',
    _earlierDateValidation:
      'Data expirării nu poate fi mai devreme decât data începerii',
    _fractions: 'Fracțiuni',
    _selectFractions: 'Selectează fracțiuni',
    _noFractions: 'Nu există fracțiuni',
    _updateOrder: 'Actualizează comanda',
    _updateSuccess: 'Comanda a fost actualizată',
    _fractionsLoading: 'Se încarcă fracțiunile',
    _notEditableWarning:
      'Odată ce ruta a început, doar data expirării poate fi modificată.',
    _contractor: 'Contractor',
    _customerGroup: 'Grup de Clienți',
    _newOrder: 'Comandă Nouă',
    _selectContractor: 'Selectează Contractor',
    _selectRoute: 'Selectează Rută',
    _selectFractions: 'Selectează Fracțiune/Tip de Deșeu',
    _selectCustomerGroup: 'Selectează Grupul de Clienți',
    _selectFrequency: 'Selectează Frecvența',
    _selectDays: 'Selectează Zi(le)',
    _days: 'Zi(le)',
    _startDate: 'Data de Început',
    _ordinaryDate: 'Introduceți Data Ordinară',
    _expireDate: 'Introduceți Data de Expirare',
    _repetationEndDate: 'Data de Sfârșit a Repetiției',
    _frequency: 'Frecvență',
    status: {
      _noDone: 'Necompletat',
      _okDone: 'Ok, completat',
      _downloaded: 'Descărcat',
      _started: 'Început',
      _backOrder: 'Comandă în așteptare',
      _localBackOrder: 'Comandă locală în așteptare',
      _partiallyStarted: 'Parțial început',
      _partiallyCompleted: 'Parțial completat',
      _rejected: 'Respins',
      _planned: 'Planificat',
      _deleted: 'Șters',
    },
  },
  orderDetails: {
    _address: 'Adresă',
    _unit: 'Unitate',
    _executed: 'Finalizat',
    _vehicleId: 'Vehicul',
    _driver: 'Șofer',
    _time: 'Timp',
    _deviation: 'Deviere',
    _orderDoesNotExist: 'Comanda nu există',
    _companyName: 'Client',
    _pictureTakenText: 'poze',
    _sequence: 'Secvență',
  },
  orderStatuses: {
    _started: 'Pornit',
    _done: 'OK, condus',
    _partiallyDone: 'Parțial finalizat',
  },
  orderDetailsDiagram: {
    _executionStatusHeader: 'Finalizat (Da / Nu)',
    _deviationsHeader: 'Deviere / Notă',
    _noDeviations: 'Deviere / Notă',
  },
  routeStopDetails: {
    _routeStopDoesNotExist: 'Punctul de oprire al rutei nu există',
    _departureTime: 'Timpul plecării',
    _arrivalTime: 'Timpul sosirii',
    _address: 'Adresă',
    _agreementId: 'ID acord',
    _unit: 'Unitate',
    _agreementLineId: 'ID linie acord',
    _executed: 'Finalizat',
    _externalOrderId: 'Nr. comandă',
    _vehicleId: 'Vehicul',
    _driver: 'Șofer',
    _orderData: 'Date comandă',
    _pictures: 'Poze',
    _deviation: 'Deviere / Notă',
    _comment: 'Comentariu',
  },
  routeAdminPage: {
    _selectRoute: 'Selectați ruta(e)',
    _refresh: 'Reîmprospătați',
    _withoutPosition: 'Fără poziție',
    _sequence: 'Secvență',
    _description: 'Descriere',
    _routeLineId: 'Punct de oprire',
    _agreementId: 'ID acord',
    _name: 'Nume',
    _estate: 'Proprietate',
    _unitName: 'Numele unității',
    _agreementLineId: 'ID linie acord',
    _physicalAmount: 'Cantitate',
    _route: 'Rută',
    _degLat: 'Latitudine',
    _degLong: 'Longitudine',
    _invalidSequence: 'Număr de secvență invalid',
    _changeSequence: 'Schimbați secvența',
    _cancelButton: 'Anulare',
    _submitButton: 'Salvați',
    _validateButton: 'Validați',
    _restoreButton: 'Anulare',
    _locationChangeTitle: 'Sunteți sigur că doriți să salvați?',
    _locationChangeContent: 'Modificările dumneavoastră vor fi acum salvate',
    _splitStoppoint: 'Împărțiți punctul de oprire',
    _splitModalPlaceholder:
      'Trageți un client aici pentru a crea un nou punct de oprire',
    _splitModalErrorMessage: 'Trebuie să creați cel puțin 2 puncte de oprire',
    _mergeModalTitle: 'Fuzionați punctele de oprire',
    _mergeModalContent: 'Alegeți punctele de oprire',
    _selectOnlyOne: 'Vă rugăm să selectați doar 1 punct de oprire',
    _selectAtLeastOne: 'Cel puțin 1 punct de oprire trebuie selectat',
    _selectAtLeastTwo: 'Cel puțin 2 puncte de oprire trebuie selectate',
    _mergeStoppoints: 'Fuzionați locația de ridicare',
    _sequenceEdit: 'Schimbați secvența',
    _savePosition: 'Salvați poziția',
    _changePosition: 'Schimbați poziția',
    _changePositionTooltip: 'Schimbați poziția punctului de oprire selectat',
    _agreementGid: 'GnrBnrFnrSnr',
    _placeId: 'ID locație de ridicare',
    _address: 'Adresă',
    _customerName: 'Nume client',
    _propertyLinesWarning: 'Unele dintre puncte pot apărea în afara hărții.',
    _propertyLinesCustom: 'Afișați doar punctele cu coordonate.',
    _propertyLinesAll: 'Afișați toate punctele.',
    _changesHeader: 'Următoarele numere de secvență sunt afectate',
    _changedFrom: 'schimbat de la',
    _changedTo: 'la',
    _notInNorwayErrorMessage:
      'Poziția pe care ați setat-o este în afara Norvegiei',
    _alreadyMerged: 'Afaceri marcate au fost deja fuzionate',
    _mergeModalTargetText: 'Selectați vizualizarea/adresa punctului de oprire',
    _mergeModalTargetDropdownText: 'Alegeți vizualizarea/adresa',
    _setPosition: 'Setați poziția',
    _setPositionTooltip: 'Setați poziția punctului de oprire selectat',
    _noRecords: 'Nu sunt disponibile înregistrări.',
    _isLoadingMessage: 'Înregistrările sunt în curs de încărcare.',
    _sequenceSuccessfullyChanged: 'Secvența a fost actualizată',
    _remove: 'Ștergeți',
    _selection: 'Selecție',
    _hideWithoutAgreement: 'Ascunde fără acord',
    _chooseRoutes: 'Alegeți rute',
  },
  reports: {
    _exportTitle: 'Exportă în fișier Excel',
    _nothingToExportTitle: 'Nu sunt date de exportat',
    _showReport: 'Afișează raportul',
    _fromDate: 'Data de început',
    _toDate: 'Data de sfârșit',
    _selectReport: 'Selectează raportul',
    _reportEmpty: 'Nu există înregistrări disponibile',
    _header: 'Rapoartele mele',
    _summary:
      'Aici am adunat toate rapoartele lor. Rapoartele pot fi comandate de la consultanții noștri. Contactați-ne prin e-mail {mailto}.',
    _export: 'Exportă',
  },
  serviceLists: {
    _noOrders: 'Niciun ordin programat.',
    _delete: 'Șterge',
    _add: 'Adaugă',
    _deleteConfirm: 'Ești sigur că vrei să ștergi această listă de lucru: ',
  },
  routeOptimalizationWizard: {
    _title: 'Creează liste de livrare',
    _uploadFileStep: 'Încarcă fișier',
    _uploadFileButton: 'Selectează fișier',
    _loadInButton: 'Încărcați',
    _analyseStep: 'Analizează',
    _analyseButton: 'Rulează analiza',
    _saveStep: 'Liste de livrare',
    _saveButton: 'Creează liste de livrare',
    _downloadTemplateButton: 'Descarcă șablonul de import',
    _importedRouteTabTitle: 'Bază de comenzi',
    _worklistsTabTitle: 'Liste de livrare',
    _unassignedStopsTabTitle: 'Comenzi neincluse',
    _noRoutesImported: 'Nicio comandă, încarcă fișierul',
    _worklistEmpty: 'Lista de livrare este goală',
    _worklistOptionText: 'Rute de livrare',
    _addCustomer: 'Rută de livrare',
    _duplicateRoutesWarning: 'Există comenzi duplicate în baza de comenzi',
    _noFileSelectedTitle: 'Niciun fișier selectat!',
    _noFileSelectedDescription:
      'Încarcă fișierul cu lista de comenzi utilizând șablonul de import:',
    _avaibleOrders: 'Comenzi disponibile',
    _loadIn: 'Încărcare în',
    _loadOrders: 'Încarcă comenzile',
    importManager: {
      _unknownParseError: 'A apărut o eroare necunoscută',
      _fileLoadResultsModalTitle: 'Rezultatele încărcării fișierului',
      _fileLoadSuccess: 'Încărcarea fișierului a fost reușită!',
      _fileLoadModalCloseButton: 'Închide',
      _agreementNotFound:
        'Clienții nu au fost găsiți pentru numărul clientului',
      _wrongFileFormat:
        'Format de fișier incorect. Descarcă șablonul de import',
      _fileEmpty: 'Fișierul nu conține date',
    },
    addCustomerModal: {
      _title: 'Adaugă client',
      _closeButton: 'Închide',
      _addButton: 'Adaugă',
      _dateLabel: 'Dată',
      _agreementLabel: 'Client',
      _descriptionLabel: 'Descriere',
      _serialLabel: 'Număr de serie',
      _noOptionsMessage: 'Niciun client găsit',
      _loadingMessage: 'Clienții sunt încărcați',
      _agreementSelector: 'Selectează client',
      _agreementSelectorPlaceholder: 'Selectează client...',
    },
    routeAnalysisModal: {
      _title: 'Analizează baza de comenzi',
      _closeButton: 'Închide',
      _cancelButton: 'Anulează',
      _analyseButton: 'Analizează',
      _selectStartAndStop: 'Selectează punctul de început și de sfârșit',
      _workTime: 'Timpul de lucru efectiv',
      _timePerStop: 'Timpul petrecut pe oprire (minute)',
      _maximumCars: 'Număr maxim de mașini în analiză',
      _startStopLabel: 'Punct de început și sfârșit',
      _workHoursLabel: 'Ore',
      _workMinutesLabel: 'Minute',
      _timePerStopLabel: 'Timp petrecut',
      _maximumCarsLabel: 'Număr maxim de vehicule',
      _noDepotOptionsMessage: 'Niciun depozit găsit',
      _depotsLoadingMessage: 'Depozitele sunt încărcate',
      _depotSelectorPlaceholder: 'Selectează depozit...',
      _analysationError: 'Eroare la analiza bazei de comenzi',
    },
    routesGrid: {
      _date: 'Dată',
      _gln: 'Nr. contract',
      _customerName: 'Nume client',
      _description: 'Descriere',
      _serial: 'Număr de serie',
      _orderNumber: 'Număr comandă',
    },
    worklistResults: {
      _sequence: 'Secvență',
      _arrivalTime: 'Timp',
      _gln: 'Client nr',
      _customerName: 'Nume client',
      _description: 'Descriere',
      _serial: 'Număr de serie',
      _unassignedWarning: 'comenzi neincluse!',
      worklistSummary: {
        _totalTime: 'Timp total',
      },
      moveWorklistItemModal: {
        _title: 'Timp total',
        _targetListLabel: 'Lista de livrare',
        _closeButton: 'Închide',
        _moveButton: 'Timp total',
      },
    },
    createWorklistsModal: {
      _title: 'Creează liste de livrare',
      _closeButton: 'Închide',
      _saveButton: 'Creează',
      _itemCount: 'Cantitate',
      _listName: 'Numele listelor',
      _saveError: 'Eroare la crearea listelor de livrare',
    },
  },
  dateRangePicker: {
    _thisWeek: 'Săptămâna aceasta',
    _lastWeek: 'Săptămâna trecută',
    _thisMonth: 'Luna aceasta',
  },
  serviceListsGrid: {
    _listName: 'Numele listelor',
    _vehicleId: 'Vehicul',
    _updated: 'Actualizat',
    _started: 'Început',
    _listDate: 'Dată',
  },
  serviceOrders: {
    _noOrders: 'Nicio comandă programată.',
    _excelExportButton: 'Excel',
    _pdfExportButton: 'PDF',
    _deleteConfirm: 'Ești sigur că vrei să ștergi această comandă: ',
  },
  serviceOrdersGrid: {
    _updated: 'Timp',
    _address: 'Adresă',
    _executed: 'Finalizat',
    _unit: 'Unitate',
    _orderItemType: 'Tip',
    _deviation: 'Abatere',
    _externalOrderId: 'GLN',
    _sequence: 'Secvență',
    _agreementCompanyName: 'Client',
    _estimatedArrivalTime: 'Planificat',
  },
  serviceOrderDetails: {
    _serviceOrders: 'Comenzi de serviciu',
  },
  serviceOrderDetailsGrid: {
    _agreementCompanyName: 'Client',
    _address: 'Adresă',
    _externalOrderId: 'GLN',
    _sequence: 'Secvență',
    _estimatedArrivalTime: 'Planificat',
    _description: 'Descriere',
    _listDescription: 'Descriere listă',
    _executed: 'Finalizat',
    _vehicleId: 'Vehicul',
    _updated: 'Timp finalizat',
    _unitName: 'Unitate',
    _orderItemType: 'Tip',
    _time: 'Timp',
  },
  containerOrders: {
    _noOrders: 'Nicio comandă planificată.',
    _cancel: 'Anulează',
    _save: 'Atribuie vehicul',
    _addVehicle: 'Adaugă vehicul',
    _addVehicleSuccess: 'Vehicul adăugat',
    _addVehicleFailure: 'Vehiculul nu a fost setat',
    _dateRange: 'Interval de date',
    _routes: 'Rute',
    _selectRoutes: 'Selectați rutele',
    _vehicles: 'Vehicule',
    _selectVehicles: 'Selectați vehiculele',
  },
  containerOrdersGrid: {
    _orderId: 'ID comandă',
    _expires: 'Expiră',
    _date: 'Dată',
    _routeName: 'Rută',
    _address: 'Adresă',
    _unitName: 'Unitate',
    _orderTypeName: 'Tip',
    _fraction: 'Fracție',
    _vehicleId: 'Vehicul',
    _route: 'Ruta comenzii',
  },
  customerSearchGrid: {
    _id: 'Id',
    _system: 'Sistem',
    _municipality: 'Municipalitate',
    _name: 'Nume',
    _address: 'Adresă',
    _zipCode: 'Cod Poștal',
    _type: 'Tip Profil',
    _unitDesrciption: 'Tip Unitate',
    _sendMessage: 'Trimite mesaj direct',
  },
  containerOrderDetails: {
    _unitName: 'Unitate',
    DetailData_Name: 'Nume',
    DetailData_Address1: 'Adresă 1',
    DetailData_Address2: 'Adresă 2',
    DetailData_PostalCode: 'Cod poștal',
    DetailData_Other: 'Altul',
    DetailData_Message: 'Comentariu',
    DetailData_OrderId: 'ID comandă',
    DetailData_Expires: 'Expiră',
    DetailData_LastDate: 'Ultima dată',
    DetailData_LastVolume: 'Volum',
    DetailData_MessageSeptic: 'Notificare',
    DetailData_Material: 'Tip material',
    DetailData_NumberOfChambers: 'Număr camere',
    DetailData_TubeLength: 'Lungime tub',
    DetailData_ExtOrderId: 'ID comandă',
    DetailData_ExtOrderNr: 'Număr comandă extern',
    DetailData_Route: 'Rută',
    DetailData_RouteName: 'Nume rută',
    DetailData_OrderDate: 'Dată comandă',
    DetailData_OrderType: 'Tip comandă',
    DetailData_OrderTypeText: 'Tip comandă',
    DetailData_PASystem: 'Sistem PA',
    DetailData_AgreementLines: 'Linii acord',
    DetailData_RoutelineId: 'ID linie rută',
    AgreementLineDetailData_PASystem: 'Sistem PA',
    AgreementLineDetailData_AgreementId: 'ID acord',
    AgreementLineDetailData_AgreementLineId: 'ID linie acord',
    AgreementLineDetailData_UnitId: 'ID unitate',
    AgreementLineDetailData_Amount: 'Cantitate',
    AgreementLineDetailData_PhysicalAmount: 'Cantitate fizică',
    AgreementLineDetailData_Message: 'Locație',
    AgreementLineDetailData_ExtOrderId: 'ID comandă',
    AgreementLineDetailData_LastChanged: 'Ultima modificare',
    AgreementLineDetailData_DegLat: 'Latitudine',
    AgreementLineDetailData_DegLon: 'Longitudine',
    AgreementLineDetailData_ContainerId: 'ID container',
    AgreementLineDetailData_Fraction: 'Fracție',
    AgreementDetailData_PASystem: 'Sistem PA',
    AgreementDetailData_AgreementId: 'ID acord',
    AgreementDetailData_Route: 'Rută',
    AgreementDetailData_CustomerId: 'ID client',
    AgreementDetailData_Name: 'Nume',
    AgreementDetailData_ContactName: 'Nume contact',
    AgreementDetailData_Address1: 'Adresă 1',
    AgreementDetailData_Address2: 'Adresă 2',
    AgreementDetailData_PostalCode: 'Cod poștal',
    AgreementDetailData_City: 'Oraș',
    AgreementDetailData_Other: 'Altul',
    AgreementDetailData_Message: 'Mesaj',
    AgreementDetailData_Type: 'Tip',
    AgreementDetailData_LastChanged: 'Ultima modificare',
  },
  impersonationManager: {
    _selectUser: 'Selectați client/utilizator',
    _noOptionsMessage: 'Niciun utilizator găsit',
    _impersonate: 'Folosire',
    _stopImpersonation: 'Opriți utilizarea',
    _selectUserPlaceholder: 'Selectați client/utilizator...',
  },
  userAdmin: {
    _noUsers: 'Niciun utilizator disponibil',
    _userName: 'E-mail',
    _system: 'Sistem',
    _contractor: 'Contractor',
    _contractorId: 'ID contractor',
    _localAdmin: 'Admin local',
    _admin: 'Admin',
    _modules: 'Module',
    _add: 'Adaugă',
    _update: 'Actualizează',
    _cancel: 'Anulează',
    _newUser: 'Adaugă utilizator nou',
    _userEditDialogTitle: 'Editează utilizator',
    _userAddDialogTitle: 'Adaugă utilizator nou',
    _password: 'Parolă',
    _passwordConfirmation: 'Confirmă parola',
    _confirmPasswordError: 'Parolele trebuie să fie identice',
    _noCustomerOptionsMessage: 'Niciun client găsit',
    _noContractorOptionsMessage: 'Niciun contractor găsit',
    _contractorsLoadingMessage: 'Contractorii sunt încărcați',
    _noModuleOptionsMessage: 'Niciun modul găsit',
    _modulesLoadingMessage: 'Modulele sunt încărcate',
    _customerSelectorPlaceholder: 'Selectați client...',
    _impersonationWarningPart1: 'Sunteți conectat ca ',
    _impersonationWarningPart2:
      ', și nu aveți drepturi pentru a folosi această pagină. Apăsați butonul de mai jos pentru a reveni la funcția de administrator.',
  },
  vehicles: {
    _noVehicles: 'Niciun vehicul disponibil',
    _vehicles: 'Niciun vehicul disponibil',
    _system: 'Sistem',
    _DTAShort: 'DTA',
    _DTALong: 'Asistent Digital pentru Camioane',
    _ajourShort: 'Ajour',
    _ajourLong: 'GPS Ajour',
    _vehicleId: 'Vehicul',
    _externalId: 'ID extern',
    _description: 'Descriere',
    _phoneNr: 'Nr SIM',
    _driver: 'Șofer',
    _password: 'Parolă',
    _repeatPassword: 'Repetă parola',
    _edit: 'Schimbă',
    _cancel: 'Anulează',
    _update: 'Actualizează',
    _submit: 'Salvează',
    _addNewVehicle: 'Adaugă vehicul nou',
    _editMessageSuccessful: 'Vehiculul a fost adăugat',
    _addMessageSuccessful: 'Vehicul adăugat',
    _editMessageFailed: 'Eroare la editarea vehiculului',
    _addMessageFailed: 'Eroare la crearea vehiculului',
    _vehicleAlreadyExistingError: 'Un vehicul cu același nume există deja',
    _validationError: 'Sunt permise doar cifre și litere',
    _contractorSelectorPlaceholder: 'Selectați contractor...',
    _noContractorFound: 'Niciun contractor disponibil',
    _vehicleLoadingMessage: 'Vehiculele sunt încărcate',
  },
  vehicleSettings: {
    _copyFromVehicleButton: 'Copiază din...',
    _copyFromVehicleDialogTitle: 'Copiază setări de la utilizator',
    _copyButton: 'Copiază',
    _copyFromVehicleError: 'Eroare la copierea setărilor utilizatorului',
    _copyFromVehicleSuccess:
      'Setările au fost copiate de la utilizatorul selectat',
    _noSettings: 'Nicio setare disponibilă',
    _parameter: 'Parametru',
    _value: 'Valoare',
    _addToMultiple: 'Adăugați mai multe vehicule?',
    _description: 'Descriere',
    _vehicle: 'Vehicul',
    _edit: 'Schimbă',
    _cancel: 'Anulează',
    _multiselectError: 'Alegeți cel puțin un vehicul',
    _update: 'Actualizează',
    _submit: 'Salvează',
    _addNewSetting: 'Adaugă setare nouă',
    _editMessageSuccessful: 'Setarea utilizatorului a fost actualizată',
    _addMessageSuccessful: 'Setarea utilizatorului a fost adăugată',
    _deleteMessageSuccessful: 'Setarea utilizatorului a fost ștearsă',
    _editMessageFailed: 'Eroare la schimbarea setărilor utilizatorului',
    _addMessageFailed: 'Eroare la crearea setării utilizatorului',
    _deleteMessageFailed: 'Eroare la ștergerea setărilor utilizatorului',
    _deleteConfirm:
      'Sunteți sigur că doriți să ștergeți această setare a utilizatorului?',
    _vehicleSelectorPlaceholder: 'Selectați vehicul...',
    _selectVehicle: 'Vehicul',
    _selectCustomer: 'Client',
    _selectContractor: 'Contractor',
    _settingTemplateSelectorPlaceholder: 'Selectați tipul de setare...',
    _noSettingTemplateOptionsMessage: 'Nicio setare găsită',
    _cannotAddNewSettingMessage:
      'Nu se pot adăuga setări. Toate setările există deja.',
    _failedToLoadSettingTempletes:
      'Nu se pot schimba sau adăuga setări. Lipsesc setările implicite.',
  },
  map: {
    _trackingLineLayer: 'Linie de urmărire',
    _trackInfo: 'Informații GPS',
    _trackInfoLine: 'Jurnal GPS',
    _trackInfoPointsAndLines: 'Jurnal de traseu',
    _streetLayer: 'Harta de bază',
    _satelliteLayer: 'Fotografie aeriană',
    _propertyLayer: 'Afișare registru cadastral',
    _wmsLayer: 'WMS',
    _nWMSLayersSelected: '{0} zonă/zones au fost selectate',
    _lastUpdated: 'Actualizat',
    _trackingLineLegend: {
      _belowSix: '< 6 ore',
      _sixToTwelve: '6-12 ore',
      _twelveToTwentyFour: '12-24 ore',
      _overTwentyFour: '> 24 ore',
      _gray: 'Necunoscut',
    },
  },
  contractors: {
    _contractors: 'Contractori',
    _name: 'Numele contractorului',
    _externalId: 'ID extern al contractorului',
    _addContractor: 'Adaugă nou contractor',
    _deleteConfirm: 'Sunteți sigur că doriți să ștergeți acest contractor: ',
    _deleteSuccess: 'Contractor șters',
    _addSuccess: 'Contractor adăugat',
    _editSuccess: 'Contractor actualizat',
  },
  deviations: {
    _deviations: 'Abateri',
    _deviation: 'Abatere',
    _deviatonExtId: 'ID extern abatere',
    _category: 'Categorie',
    _deleteConfirm: 'Sunteți sigur că doriți să ștergeți această abatere: ',
    _addNew: 'Adaugă abateri',
    _addSuccess: 'Abatere adăugată',
    _editSuccess: 'Abatere actualizată',
    _deleteSuccess: 'Abatere ștearsă',
    _selectCategory: 'Selectați categoria abaterii',
    _noCategory: 'Nicio categorie de abatere găsită',
    _deleteCategoryConfirm:
      'Sunteți sigur că doriți să ștergeți această categorie de abateri: ',
    _categoryDeleteSuccess: 'Categoria de abateri ștearsă',
    _deviationCategory: 'Categorii de abateri',
    _categoryName: 'Numele categoriei de abateri',
    _addCategorySuccess: 'Categoria de abateri adăugată',
    _editCategorySuccess: 'Categoria de abateri actualizată',
    _addNewCategory: 'Adăugați nouă categorie de abateri',
  },
  customerRegister: {
    message: {
      _directMessageToCustomer: 'Mesaj direct către client',
      _address: 'Adresă',
      _sender: 'Expeditor',
      _expires: 'Expiră',
      _vehicle: 'Vehicul',
      _selectVehicle: 'Selectează vehicul',
      _title: 'Titlu',
      _message: 'Mesaj',
      _send: 'Trimite',
      _messageSent: 'Mesaj trimis',
      _noVehicle: 'Niciun vehicul',
    },
    grid: {
      _infoText:
        'Aici puteți căuta clientul și trimite un mesaj direct despre client către vehicul.',
    },
  },
  newOrder: {
    days: {
      _monday: 'Luni',
      _tuesday: 'Marți',
      _wednesday: 'Miercuri',
      _thursday: 'Joi',
      _friday: 'Vineri',
      _saturday: 'Sâmbătă',
      _sunday: 'Duminică',
    },
    frequency: {
      _oneTime: 'O singură apariție',
      _weekly: 'Săptămânal',
      _weeks: 'La fiecare # săptămână',
    },
  },
  directMessages: {
    _messages: 'Mesaje',
    _address: 'Adresă',
    _sender: 'Expeditor',
    _expires: 'Expiră',
    _vehicle: 'Vehicul',
    _title: 'Titlu',
    _message: 'Mesaj',
    _date: 'Data',
    _closeButton: 'Închide',
    _sent: 'Trimis',
    _received: 'Procesat',
    _read: 'Primit',
    _status: 'Status',
    _newMessage: 'Mesaj nou',
    _fromDate: 'De la',
    _toDate: 'Până la',
    _vehicles: 'Vehicule',
    _chooseVehicles: 'Alegeți vehicul(e)',
    _statuses: 'Statusuri',
    _chooseStatuses: 'Alegeți status(uri)',
  },
  depots: {
    _deleteConfirm: 'Sigur doriți să ștergeți acest depozit?',
    _deleteSuccess: 'Depozit șters',
    _depots: 'Depozite',
    _newDepot: 'Depozit nou',
    _id: 'Id',
    _customerId: 'Id client',
    _description: 'Descriere',
    _latitude: 'Latitudine',
    _longitude: 'Longitudine',
    _depotCreated: 'Depozit creat',
    _depotUpdated: 'Depozit actualizat',
    _createNew: 'Creează un depozit nou',
    _update: 'Actualizează depozitul',
    _submit: 'Salvează',
    _cancelButton: 'Anulează',
  },
  orderOverview: {
    _hideFilter: 'Ascunde filtrul',
    _showFilter: 'Afișează filtrul',
    _filter: 'Filtru',
    _order: 'Comandă',
    _date: 'Data',
    _ordinaryDate: 'Data obișnuită',
    _expires: 'Expiră',
    _route: 'Rută',
    _fractions: 'Fracții',
    _contractor: 'Contractant',
    _status: 'Status',
    _dateFilterInvalid: 'Filtrul de dată este invalid',
    _from: 'De la',
    _to: 'La',
    _contractors: 'Contractori',
    _orderNumber: 'Număr comandă',
    _routes: 'Rute',
    _chooseRoutes: 'Alegeți ruta/rutele',
  },
  fractions: {
    _deleteConfirm: 'Sigur doriți să ștergeți această fracție?',
    _deleteSuccess: 'Fracție ștearsă',
    _newFraction: 'Fracție nouă',
    _fraction: 'Fracție',
    _fractions: 'Fracții',
    _picture: 'Imagine',
    _fractionCreated: 'Fracție creată',
    _fractionUpdated: 'Fracție actualizată',
    _fractionName: 'Numele fracției',
    _updateFraction: 'Actualizați fracția',
    _submit: 'Salvați',
    _cancelButton: 'Anulați',
    _selectImage: 'Selectați o imagine',
  },
  units: {
    _units: 'Unități',
    _unitUpadated: 'Unitate actualizată',
    _unitName: 'Nume unitate',
    _commune: 'Comună',
    _fractions: 'Fracții',
    _selectFractions: 'Selectați fracții',
    _cancel: 'Anulați',
    _save: 'Salvați',
    _manageFractions: 'Gestionați fracții',
    _shortName: 'Litru',
    _freeBasedProduct: 'Produs bazat pe taxă',
    _distance: 'Distanță',
    _container: 'Recipient',
  },
};
