import ImpersonationManager from './ImpersonationManager';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import userActions from '../../redux/modules/users/userActions';
import * as authCreators from '../../redux/modules/auth/authCreators';
import { createLoadingSelector } from '../../redux/modules/loading/loadingSelectors';
import userConstants from '../../redux/modules/users/userConstants';
import customerConstants from '../../redux/modules/customer/customerConstants';
import customerActions from '../../redux/modules/customer/customerActions';
import * as vehicleTrackingActions from '../../redux/modules/vehicleTracking/vehicleTrackingActions';
import { vehicleTrackingConstants } from '../../redux/modules/vehicleTracking/vehicleTrackingConstants';
import * as settingsCreators from '../../redux/modules/settings/settingsCreators';

const isUsersLoading = createLoadingSelector(
  userConstants.GET_USERS,
  customerConstants.GET_CUSTOMERS,
);

const isVehiclesLoading = createLoadingSelector(
  vehicleTrackingConstants.GET_VEHICLES,
);

function mapStateToProps(state) {
  return {
    users: state.users.users,
    impersonatedUser: state.authentication.impersonatedUser,
    impersonatedCustomerId: state.authentication.impersonatedCustomerId,
    isUsersLoading: isUsersLoading(state),
    customers: state.customer.customers,
    isVehiclesLoading: isVehiclesLoading(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    authActions: bindActionCreators(authCreators, dispatch),
    customerActions: bindActionCreators(customerActions, dispatch),
    vehicleTrackingActions: bindActionCreators(vehicleTrackingActions, dispatch),
    settingsActions: bindActionCreators(settingsCreators, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImpersonationManager);
