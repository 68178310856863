import { Component, ReactNode } from 'react';
import { IContractor, IUser } from '../../models';
import PaneHeadline from '../shared/paneHeadline';
import { faHandshake } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { isNil } from 'lodash';
import './ContractorAdmin.scss';
import { locale } from '../../common/localization/localizationService';
import ContractorsGrid from './ContractorsGrid';
import ContractorDialog from './ContractorDialog';
import ContractorsService from '../../services/ContractorsService';
import { Toastr } from '../../utils/Toastr';

export interface IContractorAdminProps {
  getContractors: (
    customerId: number,
    ignoreCache?: boolean
  ) => Promise<Record<string, any>>;
  authentication: IUser;
}

export interface IContractorAdminState {
  selectedCustomerId: number;
  externalContractorId: number;
  contractorName: string;
  contractorsList: Array<IContractor>;
  contractorDialog: {
    show: boolean;
    contractor: IContractor;
    contractors: IContractor[];
    customerId: number;
  };
}

export class ContractorAdmin extends Component<
  IContractorAdminProps,
  IContractorAdminState
> {
  public readonly state: IContractorAdminState = {
    selectedCustomerId: this.props.authentication.impersonatedCustomerId ?? this.props.authentication.customerId,
    externalContractorId: null,
    contractorDialog: {
      contractor: null,
      contractors: null,
      customerId: null,
      show: false,
    },
  } as IContractorAdminState;

  public componentDidMount(): void {
    this.initComponent();
  }

  private initComponent = async (): Promise<void> => {
    await this.loadContractors(this.state.selectedCustomerId);
  };
  
  private loadContractors = async (
    customerId: number,
    ignoreCache = false
  ): Promise<void> => {
    if (
      !isNil(this.props.authentication.contractorId) &&
      !this.props.authentication.isAdmin &&
      !this.props.authentication.isLocalAdmin
    )
      return new Promise((resolve) => resolve(null));
    const contractorsReq = await this.props.getContractors(
      customerId,
      ignoreCache
    );
    const contractors: Array<IContractor> = contractorsReq.contractors
      ? contractorsReq.contractors.sort((a: IContractor, b: IContractor) =>
          a.contractorName.localeCompare(b.contractorName)
        )
      : [];

    await new Promise((resolve) => {
      this.setState(
        {
          ...this.state,
          contractorsList: contractors,
        },
        () => resolve(null)
      );
    });
  };

  private addNewContractor = (): void => {
    this.setState({
      ...this.state,
      contractorDialog: {
        contractor: null,
        contractors: this.state.contractorsList,
        customerId: this.state.selectedCustomerId,
        show: true,
      },
    });
  };

  private editContracor(contractor: IContractor): void {
    this.setState({
      ...this.state,
      contractorDialog: {
        contractor: contractor,
        contractors: this.state.contractorsList,
        customerId: this.state.selectedCustomerId,
        show: true,
      },
    });
  }

  private hideContractorDialog(rerender = false): void {
    this.setState({
      ...this.state,
      contractorDialog: {
        contractor: null,
        contractors: null,
        customerId: null,
        show: false,
      },
    });
    if (rerender) {
      this.loadContractors(this.state.selectedCustomerId, true);
    }
  }

  private async deleteContractor(contractor: IContractor): Promise<void> {
    if (
      confirm(locale.contractors._deleteConfirm + contractor.contractorName)
    ) {
      try {
        await ContractorsService.deleteContractor(
          contractor.customerId,
          contractor.contractorId
        );
        Toastr.success(locale.contractors._deleteSuccess);
      } catch (error) {
        Toastr.error(error);
      } finally {
        this.loadContractors(this.state.selectedCustomerId, true);
      }
    }
  }

  public render(): ReactNode {
    return (
      <div className="contractors">        
        <>
          <div className="row">
            <PaneHeadline
              titleText={locale.contractors._contractors}
              titleIcon={faHandshake}
              className="col-sm-6"
            />
            <div className="col-sm-6 text-right">
              <button
                className="btn btn-outline-dark"
                onClick={() => this.addNewContractor()}
              >
                {locale.contractors._addContractor}
              </button>
            </div>
          </div>

          <div className="d-flex">
            {this.state.contractorsList && (
              <ContractorsGrid
                data={this.state.contractorsList}
                editContractor={this.editContracor.bind(this)}
                deleteContractor={this.deleteContractor.bind(this)}
                className="mb-3"
              />
            )}
          </div>
        </>
        {this.state.contractorDialog.show && (
          <ContractorDialog
            contractor={this.state.contractorDialog.contractor}
            contractors={this.state.contractorDialog.contractors}
            customerId={this.state.contractorDialog.customerId}
            show={this.state.contractorDialog.show}
            onHide={this.hideContractorDialog.bind(this)}
          />
        )}
      </div>
    );
  }
}

export default ContractorAdmin;
