import { IUnitsState } from './unitsState';
import { All as Actions } from './unitsActions';
import { UnitsConstants } from './unitsConstants';

const initialState: IUnitsState = {
  units: [],
  errorMessage: null,
  isLoading: false,
};

export function unit(state = initialState, action: Actions): IUnitsState {
  switch (action.type) {
    case UnitsConstants.GET_UNITS_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UnitsConstants.GET_UNITS_SUCCESS: {
      return {
        ...state,
        units: action.units,
        isLoading: false,
      };
    }
    case UnitsConstants.GET_UNITS_FAILED: {
      return {
        ...state,
        errorMessage: action.error.message,
        isLoading: false,
      };
    }
    default:
      return state;
  }
}
