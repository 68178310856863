import { bindActionCreators, Dispatch } from 'redux';
import { IUser } from '../../models';
import { IRootState } from '../../redux/state';
import { connect } from 'react-redux';
import { Fractions } from './Fractions';
import {
  loadFractionPictures,
  loadFractions,
} from '../../redux/modules/fractions/fractionsCreators';

function mapStateToProps(state: IRootState) {
  return {
    authentication: state.authentication as unknown as IUser,
    impersonatedUser: state.authentication.impersonatedUser,
    fractions: state.fraction.fractions,
    loading: state.fraction.isLoading,
    fractionPictures: state.fraction.pictures,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getFractions: bindActionCreators(loadFractions, dispatch),
    fetchFractionPictures: bindActionCreators(loadFractionPictures, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Fractions);
