import { bindActionCreators, Dispatch } from 'redux';
import { IUser } from '../../models';
import { loadDeviationCategories } from '../../redux/modules/deviaton/deviatonCreators';
import { IRootState } from '../../redux/state';
import DeviationCategories from './deviationCategories';
import { connect } from 'react-redux';

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getDeviationCategories: loadDeviationCategories,
    },
    dispatch
  );
}

function mapStateToProps(state: IRootState) {
  return {
    authentication: state.authentication as unknown as IUser,
    deviationCategoryList: state.deviation.deviationCategoryList,
  };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(
  mapStateToProps,  
  mapDispatchToProps
)(DeviationCategories);
