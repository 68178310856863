/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { locale } from '../../common/localization/localizationService';
import ContractorAdmin from '../contractorAdmin';
import Vehicles from '../vehicles';
import Deviations from '../deviations';
import DeviationCategories from '../deviationCategories';
import { isNil } from 'lodash';
import { IUser } from '../../models';
import UserAdmin from '../userAdmin';
import EventBus from '../../common/EventBus';
import Depots from '../depots';
import Fractions from '../fractions';
import Units from '../units';
import { History } from 'history';

export interface IAdministrationPageProps {
  authentication: IUser;
  history: History;
}

const AdministrationPage: React.FC<IAdministrationPageProps> = ({
  // eslint-disable-next-line react/prop-types
  authentication,
  history,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    EventBus.emit('fullScreenChange', true);

    return () => {
      EventBus.emit('fullScreenChange', false);
    };
  }, []);
  return (
    <div>
      <TabStrip selected={activeTab} onSelect={(e) => setActiveTab(e.selected)}>
        <TabStripTab title={locale.general._vehicles}>
          {activeTab === 0 && <Vehicles history={history} />}
        </TabStripTab>
        {(authentication.isAdmin || isNil(authentication.contractorId)) && (
          <TabStripTab title={locale.contractors._contractors}>
            {activeTab === 1 && <ContractorAdmin />}
          </TabStripTab>
        )}
        <TabStripTab title={locale.deviations._deviations}>
          {activeTab ===
            (authentication.isAdmin || isNil(authentication.contractorId)
              ? 2
              : 1) && <Deviations />}
        </TabStripTab>
        <TabStripTab title={locale.deviations._deviationCategory}>
          {activeTab ===
            (authentication.isAdmin || isNil(authentication.contractorId)
              ? 3
              : 2) && <DeviationCategories />}
        </TabStripTab>
        <TabStripTab title={locale.depots._depots}>
          {activeTab ===
            (authentication.isAdmin || isNil(authentication.contractorId)
              ? 4
              : 3) && <Depots />}
        </TabStripTab>
        <TabStripTab title={locale.fractions._fractions}>
          {activeTab ===
            (authentication.isAdmin || isNil(authentication.contractorId)
              ? 5
              : 4) && <Fractions />}
        </TabStripTab>
        <TabStripTab title={locale.units._units}>
          {activeTab ===
            (authentication.isAdmin || isNil(authentication.contractorId)
              ? 6
              : 5) && <Units />}
        </TabStripTab>
        {(authentication.isAdmin || isNil(authentication.contractorId)) && (
          <TabStripTab title={locale.general._userAdmin}>
            {activeTab === 7 && <UserAdmin />}
          </TabStripTab>
        )}
      </TabStrip>
    </div>
  );
};

export default AdministrationPage;
