import React, { Component, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { History } from 'history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faFile,
  faWrench,
  faPhone,
  faUser,
  faListOl,
  faUserFriends,
  faEnvelope,
  faBusinessTime,
} from '@fortawesome/free-solid-svg-icons';
import { Navbar } from 'react-bootstrap';
import routes from '../../common/routes';
import { ImpersonationMarker } from './ImpersonationMarker';
import { IAuthState } from '../../redux/modules/auth';
import { withAuth0, WithAuth0Props } from '@auth0/auth0-react';

import headerLogo from '../../images/Fieldata_logo_hvit.png';
import './navbar.scss';
import EventBus from '../../common/EventBus';
import { locale } from '../../common/localization/localizationService';

export interface INavBarProps extends WithAuth0Props {
  authentication: IAuthState;
  history: History;
}

class NavBar extends Component<INavBarProps> {
  private openMenu = () => {
    EventBus.emit('onProfileMenu', { isOpen: true });
  };

  public render(): ReactNode {
    const { name, token, isAdmin, isLocalAdmin, impersonatedUser } =
      this.props.authentication;
    if (!token) {
      this.props.history.push('/login');
    }

    return (
      <Navbar
        expand="lg"
        variant="dark"
        id="main-navbar"
        className="text-white custom-navbar-height"
      >
        {name && (
          <>
            <Navbar.Brand>
              <img src={headerLogo} className="header-logo" />
            </Navbar.Brand>
            <NavLink
              to={routes.dashboard.base}
              isActive={(_, location) =>
                new RegExp(routes.dashboard.pattern).test(location.pathname)
              }
              className="nav-btn btn btn-outline-light"
            >
              <FontAwesomeIcon icon={faTachometerAlt} />
              <span>{locale.general._dashboard}</span>
            </NavLink>
            <NavLink
              to={routes.orderOveriew}
              isActive={(_, location) =>
                new RegExp(routes.orderOveriew).test(location.pathname)
              }
              className="nav-btn btn btn-outline-light"
            >
              <FontAwesomeIcon icon={faBusinessTime} />
              <span>{locale.general._orderOverview}</span>
            </NavLink>
            <NavLink
              to={routes.callordersPath}
              exact
              isActive={(_, location) =>
                new RegExp(routes.callordersPattern).test(location.pathname)
              }
              className="nav-btn btn btn-outline-light"
            >
              <FontAwesomeIcon icon={faPhone} />
              <span>{locale.general._callOrder}</span>
            </NavLink>
            <NavLink
              to={routes.serviceListsPath}
              exact
              isActive={(_, location) =>
                new RegExp(routes.serviceListsPattern).test(location.pathname)
              }
              className="nav-btn btn btn-outline-light"
            >
              <FontAwesomeIcon icon={faWrench} />
              <span>{locale.general._serviceList}</span>
            </NavLink>
            {(isAdmin || isLocalAdmin) && (
              <NavLink
                to={routes.routeAdminPagePath}
                isActive={(_, location) =>
                  new RegExp(routes.routeAdminPagePattern).test(
                    location.pathname
                  )
                }
                className="nav-btn btn btn-outline-light"
              >
                <FontAwesomeIcon icon={faListOl} />
                <span>{locale.general._routeAdmin}</span>
              </NavLink>
            )}
            <NavLink
              to={routes.reportsPath}
              isActive={(_, location) =>
                new RegExp(routes.reportsPattern).test(location.pathname)
              }
              className="nav-btn btn btn-outline-light"
            >
              <FontAwesomeIcon icon={faFile} />
              <span>{locale.general._reports}</span>
            </NavLink>
            <NavLink
              to={routes.customerSearch}
              isActive={(_, location) =>
                new RegExp(routes.customerSearch).test(location.pathname)
              }
              className="nav-btn btn btn-outline-light"
            >
              <FontAwesomeIcon icon={faUserFriends} />
              <span>{locale.general._customerRegister}</span>
            </NavLink>
            <NavLink
              to={routes.directMessages}
              isActive={(_, location) =>
                new RegExp(routes.directMessages).test(location.pathname)
              }
              className="nav-btn btn btn-outline-light"
            >
              <FontAwesomeIcon icon={faEnvelope} />
              <span>{locale.directMessages._messages}</span>
            </NavLink>
            <div className="ml-auto">
              <span className="mr-2 text-white user-name-text">
                <ImpersonationMarker impersonatedUser={impersonatedUser} />
                {name}
              </span>
              <button
                className="btn btn-light profile-button"
                type="button"
                onClick={this.openMenu}
              >
                <FontAwesomeIcon icon={faUser} />
              </button>
            </div>
          </>
        )}
        {!name && (
          <button
            className="btn btn-light ml-auto profile-button"
            type="button"
            onClick={this.openMenu}
          >
            <FontAwesomeIcon icon={faUser} />
          </button>
        )}
      </Navbar>
    );
  }
}

export default withAuth0(NavBar);
