import React, { Component, ReactNode } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import { State as DataState } from '@progress/kendo-data-query';

import {
  IRoute,
  IRoutePoi,
  IRowData,
  RouteEditMode,
  IZoomableObject,
} from '../../models';
import { locale } from '../../common/localization/localizationService';
import { RouteAdminGrid } from './RouteAdminGrid';
import { RouteAdminSelect } from './RouteAdminSelect';

import './RouteAdminPage.scss';
import { AsyncAction, SyncAction } from '../../utils';
import { Spinner2 } from '../shared/spinner2';
import { SpinnerPosition } from '../../models/Spinner.model';
import { selectedRegion } from '../../appConfig';
import { MapUtility } from '../map/MapUtility';
import { FilterOption } from '../orderOverview/OrderOverview';

export interface IRouteAdminProps {
  routes: Array<IRoute>;
  pois: Array<IRoutePoi>;
  markedRouteSequenceIds: Array<string>;
  selectedRoutes: Array<IRoute>;
  mode: RouteEditMode;
  rowData: Record<string, IRowData>;
  gridState: DataState;
  loadRoutes: (ignoreCache?: boolean) => AsyncAction;
  loadPois: (ids: Array<string>, ignoreCache?: boolean) => AsyncAction;
  setGridState: (state: DataState) => SyncAction;
  markRouteSequences: (ids: Array<string>) => SyncAction;
  toggleRouteCollapse: (ids: Array<IRowData>) => SyncAction;
  setZoomableObjects: (obj: IZoomableObject) => SyncAction;
  isLoading: boolean;
}

export interface IRouteAdminState {
  isRegionFilteringActive: boolean;
  isAgrementFilteringActive: boolean;
}

export class RouteAdmin extends Component<IRouteAdminProps> {
  public readonly state: IRouteAdminState = {
    isRegionFilteringActive: false,
    isAgrementFilteringActive: false,
  };
  public componentDidMount(): void {
    this.props.loadRoutes();
  }

  public onRouteSelect(routes: Array<FilterOption>): void {
    const ids = routes.map((a: FilterOption) => a.value as string);
    this.props.loadPois(ids);
  }

  public refreshItems(): void {
    this.props.loadRoutes(true);
    const ids = this.props.selectedRoutes.map((a: IRoute) => a.routeNumbder);
    this.props.loadPois(ids, true);
  }

  public changeRegionFilteringState(): void {
    this.setState((prevState: Readonly<IRouteAdminState>) => {
      return {
        ...prevState,
        isRegionFilteringActive: !prevState.isRegionFilteringActive,
      };
    });
  }

  public hideRouteStopsWithourAgreement(): void {
    this.setState((prevState: Readonly<IRouteAdminState>) => {
      return {
        ...prevState,
        isAgrementFilteringActive: !prevState.isAgrementFilteringActive,
      };
    });
  }

  public filterPois(): Array<IRoutePoi> {
    let filteredPois = this.props.pois;
    if (this.state.isRegionFilteringActive) {
      filteredPois = filteredPois.filter(
        (poi) => !MapUtility.isInRegion(poi.degLat, poi.degLong, selectedRegion)
      );
      if (this.state.isAgrementFilteringActive) {
        filteredPois = filteredPois.filter((poi) => {
          poi.agreementId;
        });
      }
    }
    return filteredPois;
  }

  public render(): ReactNode {
    return (
      <div className="route-admin">
        <div className="row">
          <div className="col-4">
            <h2>
              <FontAwesomeIcon icon={faListOl} />
              {locale.general._routeAdmin}
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <RouteAdminSelect
              disabled={this.props.mode !== RouteEditMode.Default}
              onChange={(routes) => this.onRouteSelect(routes)}
              defaultValue={this.props.selectedRoutes}
              data={this.props.routes}
              refreshItems={() => this.refreshItems()}
              changeRegionFilteringState={() =>
                this.changeRegionFilteringState()
              }
              hideRouteStopsWithoutAgreement={() =>
                this.hideRouteStopsWithourAgreement()
              }
              buttonClass={
                this.state.isRegionFilteringActive
                  ? 'region-filter-btn-active'
                  : ''
              }
              hideButtonClass={
                this.state.isAgrementFilteringActive
                  ? 'agreement-filter-btn-active'
                  : ''
              }
            />
            <div style={{ position: 'relative' }}>
              <Spinner2
                position={SpinnerPosition.TopCenter}
                show={this.props.isLoading}
              ></Spinner2>
              <RouteAdminGrid
                pois={
                  this.state.isRegionFilteringActive
                    ? this.props.pois.filter(
                        (poi) =>
                          !MapUtility.isInRegion(
                            poi.degLat,
                            poi.degLong,
                            selectedRegion
                          )
                      )
                    : this.props.pois
                }
                markedRouteSequenceIds={this.props.markedRouteSequenceIds}
                rowData={this.props.rowData}
                gridState={this.props.gridState}
                mode={this.props.mode}
                markRouteSequences={(ids) => this.props.markRouteSequences(ids)}
                toggleRouteCollapse={(ids) =>
                  this.props.toggleRouteCollapse(ids)
                }
                setGridState={(ids) => this.props.setGridState(ids)}
                setZoomableObjects={(obj) => this.props.setZoomableObjects(obj)}
                changeRegionFilteringState={() =>
                  this.changeRegionFilteringState()
                }
                isRegionFilteringActive={this.state.isRegionFilteringActive}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
