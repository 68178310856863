import { Dispatch } from 'redux';
import { IUnit } from '../../../models';
import * as Actions from './unitsActions';
import { UnitsConstants } from './unitsConstants';
import UnitsService from '../../../services/unitsService';

export function createGetUnitRequest(): Actions.GetUnitsList {
  return {
    type: UnitsConstants.GET_UNITS_REQUEST,
  };
}
export function createGetUnitSuccess(
  units: Array<IUnit>
): Actions.GetUnitList.Success {
  return {
    type: UnitsConstants.GET_UNITS_SUCCESS,
    units,
  };
}
export function createGetUnitFailure(
  error: Error
): Actions.GetUnitList.Failure {
  return {
    type: UnitsConstants.GET_UNITS_FAILED,
    error,
  };
}

export function loadUnits(
  ignoreCache = false
): (dispatch: Dispatch<Actions.All>) => Promise<void> {
  return async function (dispatch: Dispatch<Actions.All>): Promise<void> {
    dispatch(createGetUnitRequest());
    try {
      const result: Array<IUnit> = await UnitsService.getUnits(ignoreCache);
      dispatch(createGetUnitSuccess(result));
    } catch (error) {
      dispatch(createGetUnitFailure(error));
    }
  };
}
