import wasteManagementClient from './wasteManagementClient';
import { wasteManagementUrls } from '../appConfig';

export default class DepotsService {
  static getDepots(ignoreCache = false) {
    return wasteManagementClient
      .get(wasteManagementUrls.depots, {
        cache: { ignoreCache },
      })
      .then((response) => {
        if (response.data && response.status === 200) {
          return response.data;
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  }

  static async createNewDepot(newDepot, ignoreCache = false) {
    try {
      const response = await wasteManagementClient.post(
        wasteManagementUrls.depots,
        newDepot,
        { cache: { ignoreCache } }
      );
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  static async updateDepot(updateDepot, ignoreCache = false) {
    try {
      const response = await wasteManagementClient.put(
        wasteManagementUrls.depots,
        updateDepot,
        { cache: { ignoreCache } }
      );
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  static async deleteDepot(depotId, customerId, ignoreCache = false) {
    try {
      const response = await wasteManagementClient.delete(
        wasteManagementUrls.depots +
          `/depotId/${depotId}/customerId/${customerId}`,
        { cache: { ignoreCache } }
      );
      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      throw new Error(error);
    }
  }
}
