import React from 'react';
import PropTypes from 'prop-types';
import { getWorklistGeneratedName } from '../WorklistUtility';
import { indexOf } from 'lodash';

function WorklistSelector({
  worklistsCount,
  selectedWorklist,
  onChange,
  skip,
  name,
}) {
  const worklistOptions = [];
  for (let i = 0; i < worklistsCount; ++i) {
    if (skip.includes(i)) continue;

    worklistOptions.push((
      <option value={i} key={i}>
        {getWorklistGeneratedName(i).split(" - ")[1]}
      </option>
    ));
  }

  return (
    <div className="work-list-selector">
      <select
        value={selectedWorklist}
        onChange={(e) => onChange(Number(e.target.value))}
        className="form-control"
        name={name}
      >
        {worklistOptions}
      </select>
    </div>
  )
}

WorklistSelector.propTypes = {
  worklistsCount: PropTypes.number.isRequired,
  selectedWorklist: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  skip: PropTypes.array,
}

WorklistSelector.defaultProps = {
  worklistsCount: 0,
  selectedWorklist: 0,
  onChange: () => { },
  skip: [],
}

export default WorklistSelector;
