export const localeSe = {
  general: {
    _administration: 'Administration',
    _username: 'Användarnamn',
    _password: 'Lösenord',
    _date: 'Datum',
    _time: 'Tid',
    _orderOverview: 'Orderöversikt',
    _reports: 'Rapporter',
    _kg: 'kg',
    _ton: 'ton',
    _callOrder: 'Anropsorder',
    _serviceList: 'Arbetslistor',
    _impersonationManager: 'Ställ in kund/användare',
    _userAdmin: 'Användaradministration',
    _routeAdmin: 'Rutter',
    _backendError: 'Ett fel uppstod vid förfrågan',
    _accessError: 'Du har inte tillgång till denna sida',
    _vehicles: 'Fordon',
    _vehicleSettings: 'Administrativa Inställningar',
    _zoomIn: 'Zooma in',
    _zoomOut: 'Zooma ut',
    _warning: 'Varning!',
    _customerRegister: 'Sök i kundregister',
    _commune: 'Kommun',
    _logOut: 'Logga ut',
    _selectLanguage: 'Välj språk',
    _unitDesrciption: 'Enhetstyp',
    _fullDateFormat: 'dd.MM.yyyy',
    _fullDateWithTime: 'dd.MM.yyyy HH:mm:ss',
    _fullMomentFormat: 'DD.MM.YYYY',
    _fullMomentWithTime: 'DD.MM.YYYY HH:mm:ss',
    _noRecords: 'Inga poster tillgängliga.',
    _dashboard: 'Dashboard',
    _decimalSeparator: ',',
    validation: {
      _isRequired: 'är obligatoriskt',
      _stringMaxCharA: 'måste vara högst',
      _stringMaxCharB: 'tecken',
      _emailNotValid: 'är inte en giltig e-postadress!',
      _lessOrEqual: 'måste vara mindre än eller lika med',
      _greaterOrEqual: 'måste vara större än eller lika med',
      _laterThan: 'måste vara senare än',
      _earlierThan: 'måste vara tidigare än',
    },
  },
  login: {
    _usernameEmail: 'E-post',
    _usernameRequired: 'Användarnamn är obligatoriskt!',
    _passwordRequired: 'Lösenord är obligatoriskt!',
    _usernamePasswordInvalid: 'Fel användarnamn eller lösenord',
    _invalidEmail: 'Användarnamnet är inte en giltig e-postadress!',
    _submit: 'Logga in',
    _welcome: 'Välkommen till GPS Realtime WMS',
    _pressLogin: 'Tryck på Logga in för att logga in på portalen',
    _ifAssistance: 'Vid behov av hjälp,',
    _pleaseContact: 'kontakta gärna vår support',
  },
  tooltip: {
    _deviceId: 'Enhetsnummer',
    _altitude: 'Höjd',
    _speed: 'Hastighet',
    _temperature: 'Temperatur',
    _orderId: 'Ordernummer',
    _address: 'Adress',
    _routeName: 'Rutt',
  },
  orders: {
    _update: 'Uppdaterad',
    _start: 'Start',
    _route: 'Rutt',
    _vehicleId: 'Fordon',
    _orderId: 'Beställning',
    _status: 'Status',
    _notStarted: 'Inte påbörjad',
    _now: 'Nu',
    _clearFilter: 'Rensa filter',
    _fraction: 'Fraktion',
    _showFilters: 'Visa filter',
    _chooseContractor: 'Välj transportör',
    _chooseFraction: 'Välj fraktion',
    _chooseStatus: 'Välj status',
    _expires: 'Utgår',
    _date: 'Datum',
    _ordinaryDate: 'Ordinärt datum',
    _earlierDateValidation: 'Utgångsdatum kan inte vara tidigare än startdatum',
    _fractions: 'Fraktioner',
    _selectFractions: 'Välj fraktioner',
    _noFractions: 'Inga fraktioner',
    _updateOrder: 'Uppdatera beställning',
    _updateSuccess: 'Beställningen är uppdaterad',
    _fractionsLoading: 'Fraktioner laddas',
    _notEditableWarning:
      'När rutten har startat, kan endast utgångsdatumet ändras.',
    _contractor: 'Entreprenör',
    _customerGroup: 'Kundgrupp',
    _newOrder: 'Ny Order',
    _selectContractor: 'Välj entreprenör',
    _selectRoute: 'Välj rutt',
    _selectFractions: 'Välj fraktion/avfallstyp',
    _selectCustomerGroup: 'Välj kundgrupp',
    _selectFrequency: 'Välj frekvens',
    _selectDays: 'Välj dag(ar)',
    _days: 'Dag(ar)',
    _startDate: 'Startdatum',
    _ordinaryDate: 'Ange ordinarie datum',
    _expireDate: 'Ange utgångsdatum',
    _repetationEndDate: 'Slutdatum för upprepning',
    _frequency: 'Frekvens',
    status: {
      _noDone: 'Ej slutförd',
      _okDone: 'Ok, slutförd',
      _downloaded: 'Nedladdad',
      _started: 'Startad',
      _backOrder: 'Restorder',
      _localBackOrder: 'Lokal restorder',
      _partiallyStarted: 'Delvis startad',
      _partiallyCompleted: 'Delvis slutförd',
      _rejected: 'Avvisad',
      _planned: 'Planerad',
      _deleted: 'Raderad',
    },
  },
  orderDetails: {
    _address: 'Adress',
    _unit: 'Enhet',
    _executed: 'Utförd',
    _vehicleId: 'Fordon',
    _driver: 'Förare',
    _time: 'Tid',
    _deviation: 'Avvikelse',
    _orderDoesNotExist: 'Ordern existerar inte',
    _companyName: 'Kund',
    _pictureTakenText: 'bilder',
    _sequence: 'Sekvens',
  },
  orderStatuses: {
    _started: 'Startad',
    _done: 'OK, kört',
    _partiallyDone: 'Delvis slutförd',
  },
  orderDetailsDiagram: {
    _executionStatusHeader: 'Utförd (Ja / Nej)',
    _deviationsHeader: 'Avvikelser / Kommentarer',
    _noDeviations: 'Inga avvikelser / kommentarer',
  },
  routeStopDetails: {
    _routeStopDoesNotExist: 'Rutstoppet finns inte',
    _departureTime: 'Avgångstid',
    _arrivalTime: 'Ankomsttid',
    _address: 'Adress',
    _agreementId: 'AvtalsNr',
    _unit: 'Enhet',
    _agreementLineId: 'BehNr',
    _executed: 'Utfört',
    _externalOrderId: 'OrderNr',
    _vehicleId: 'Fordon',
    _driver: 'Förare',
    _orderData: 'Orderdata',
    _pictures: 'Bilder',
    _deviation: 'Avvikelse / Kommentar',
    _comment: 'Kommentar',
  },
  routeAdminPage: {
    _selectRoute: 'Välj rutt(er)',
    _refresh: 'Uppdatera',
    _withoutPosition: 'Utan position',
    _sequence: 'Sekvens',
    _description: 'Beskrivning',
    _routeLineId: 'Stoppunkt',
    _agreementId: 'Avtals-id',
    _name: 'Namn',
    _estate: 'Fastighet',
    _unitName: 'Produkt',
    _agreementLineId: 'Avtalslinje-id',
    _physicalAmount: 'Antal',
    _route: 'Rutt',
    _degLat: 'Breddgrad',
    _degLong: 'Längdgrad',
    _invalidSequence: 'Ogiltigt sekvensnummer',
    _changeSequence: 'Ändra sekvens',
    _cancelButton: 'Avbryt',
    _submitButton: 'Spara',
    _validateButton: 'Validera',
    _restoreButton: 'Återställ',
    _locationChangeTitle: 'Är du säker på att du vill spara?',
    _locationChangeContent: 'Dina ändringar kommer nu att sparas',
    _splitStoppoint: 'Dela stoppunkt',
    _splitModalPlaceholder: 'Dra en kund hit för att skapa en ny stoppunkt',
    _splitModalErrorMessage: 'Du måste skapa minst 2 stoppunkter',
    _mergeModalTitle: 'Slå ihop stoppunkterna',
    _mergeModalContent: 'Valda stoppunkter',
    _selectOnlyOne: 'Välj endast 1 stoppunkt',
    _selectAtLeastOne: 'Minst 1 stoppunkt måste väljas',
    _selectAtLeastTwo: 'Minst 2 stoppunkter måste väljas',
    _mergeStoppoints: 'Slå ihop hållplatser',
    _sequenceEdit: 'Ändra sekvens',
    _savePosition: 'Spara position',
    _changePosition: 'Ändra position',
    _changePositionTooltip: 'Ändra position för vald stoppunkt',
    _agreementGid: 'GnrBnrFnrSnr',
    _placeId: 'HämtningsplatsID',
    _address: 'Adress',
    _customerName: 'Kundnamn',
    _propertyLinesWarning: 'Vissa punkter kan visas utanför kartan.',
    _propertyLinesCustom: 'Visa endast punkter med koordinater.',
    _propertyLinesAll: 'Visa alla punkter.',
    _changesHeader: 'Följande sekvensnummer påverkas',
    _changedFrom: 'ändrad från',
    _changedTo: 'till',
    _notInNorwayErrorMessage: 'Den valda positionen är utanför Norge',
    _alreadyMerged: 'Valda avtal är redan sammanslagna',
    _mergeModalTargetText: 'Välj stoppunktens visning/adress',
    _mergeModalTargetDropdownText: 'Välj vilken visning/adress',
    _setPosition: 'Ange position',
    _setPositionTooltip: 'Ange position för vald stoppunkt',
    _noRecords: 'Inga poster tillgängliga.',
    _isLoadingMessage: 'Poster laddas.',
    _sequenceSuccessfullyChanged: 'Sekvensen har uppdaterats',
    _remove: 'Ta bort',
    _selection: 'val',
    _hideWithoutAgreement: 'Dölja utan överenskommelse',
    _chooseRoutes: 'Välj rutter',
  },
  reports: {
    _exportTitle: 'Exportera till Excel-fil',
    _nothingToExportTitle: 'Inga data att exportera',
    _showReport: 'Visa rapport',
    _fromDate: 'Från datum',
    _toDate: 'Till datum',
    _selectReport: 'Välj rapport',
    _reportEmpty: 'Ingen tillgänglig post',
    _header: 'Mina rapporter',
    _summary:
      'Här har vi samlat alla dina rapporter. Rapporten kan beställas från våra konsulter. Kontakta oss via e-post {mailto}.',
    _export: 'Exportera',
  },
  serviceLists: {
    _noOrders: 'Inga planerade beställningar.',
    _delete: 'Radera',
    _add: 'Lägg till',
    _deleteConfirm: 'Är du säker på att du vill radera denna arbetslista: ',
  },
  routeOptimalizationWizard: {
    _title: 'Skapa körlistor',
    _uploadFileStep: 'Ladda upp fil',
    _uploadFileButton: 'Välj fil',
    _loadInButton: 'Ladda in',
    _analyseStep: 'Analysera',
    _analyseButton: 'Kör analys',
    _saveStep: 'Körlistor',
    _saveButton: 'Skapa körlistor',
    _downloadTemplateButton: 'Ladda ner importmall',
    _importedRouteTabTitle: 'Ordergrund',
    _worklistsTabTitle: 'Körlistor',
    _unassignedStopsTabTitle: 'Ordrar ej inkluderade',
    _noRoutesImported: 'Inga ordrar, ladda upp fil',
    _worklistEmpty: 'Körlistan är tom',
    _worklistOptionText: 'Körrutt',
    _addCustomer: 'Lägg till kund',
    _duplicateRoutesWarning: 'Det finns dubbletter i ordergrunden',
    _noFileSelectedTitle: 'Ingen fil vald!',
    _noFileSelectedDescription:
      'Ladda upp ordergrundsfilen med följande importmall:',
    _avaibleOrders: 'Tillgängliga beställningar',
    _loadIn: 'Ladda in',
    _loadOrders: 'Ladda beställningar',
    importManager: {
      _unknownParseError: 'Okänt fel inträffade',
      _fileLoadResultsModalTitle: 'Resultat från filuppladdning',
      _fileLoadSuccess: 'Filuppladdning lyckades!',
      _fileLoadModalCloseButton: 'Stäng',
      _agreementNotFound: 'Kund inte hittad för kundnummer',
      _wrongFileFormat: 'Felaktigt filformat. Ladda ner importmallen',
      _fileEmpty: 'Filen innehåller inga data',
    },
    addCustomerModal: {
      _title: 'Lägg till kund',
      _closeButton: 'Stäng',
      _addButton: 'Lägg till',
      _dateLabel: 'Datum',
      _agreementLabel: 'Kund',
      _descriptionLabel: 'Beskrivning',
      _serialLabel: 'Serienummer',
      _noOptionsMessage: 'Inga kunder hittades',
      _loadingMessage: 'Kunder laddas',
      _agreementSelector: 'Välj kund',
      _agreementSelectorPlaceholder: 'Välj kund...',
    },
    routeAnalysisModal: {
      _title: 'Analysera ordergrund',
      _closeButton: 'Stäng',
      _cancelButton: 'Avbryt',
      _analyseButton: 'Analysera',
      _selectStartAndStop: 'Välj start- och stoppunkt',
      _workTime: 'Effektiv arbetstid per dag',
      _timePerStop: 'Tid per stopp (minuter)',
      _maximumCars: 'Max antal bilar i analysen',
      _startStopLabel: 'Start och stoppunkt',
      _workHoursLabel: 'Timmar',
      _workMinutesLabel: 'Minuter',
      _timePerStopLabel: 'Tid per stopp',
      _maximumCarsLabel: 'Max antal bilar',
      _noDepotOptionsMessage: 'Inga depåer hittades',
      _depotsLoadingMessage: 'Depåer laddas',
      _depotSelectorPlaceholder: 'Välj depå...',
      _analysationError: 'Fel vid analys av ordergrunden',
    },
    routesGrid: {
      _date: 'Datum',
      _gln: 'Avtal nr',
      _customerName: 'Kundnamn',
      _description: 'Beskrivning',
      _serial: 'Serienummer',
      _orderNumber: 'Ordernummer',
    },
    worklistResults: {
      _sequence: 'Sekvens',
      _arrivalTime: 'Tid',
      _gln: 'Kundnr',
      _customerName: 'Kundnamn',
      _description: 'Beskrivning',
      _serial: 'Serienummer',
      _unassignedWarning: 'Ordrar ej inkluderade!',
      worklistSummary: {
        _totalTime: 'Total tid',
      },
      moveWorklistItemModal: {
        _title: 'Flytta till',
        _targetListLabel: 'Körlista',
        _closeButton: 'Stäng',
        _moveButton: 'Flytta till',
      },
    },
    createWorklistsModal: {
      _title: 'Skapa körlistor',
      _closeButton: 'Stäng',
      _saveButton: 'Skapa',
      _itemCount: 'Antal',
      _listName: 'Listnamn',
      _saveError: 'Fel vid skapande av körlistor',
    },
  },
  dateRangePicker: {
    _thisWeek: 'Denna vecka',
    _lastWeek: 'Förra veckan',
    _thisMonth: 'Denna månad',
  },
  serviceListsGrid: {
    _listName: 'Listnamn',
    _vehicleId: 'Bil',
    _updated: 'Uppdaterad',
    _started: 'Start',
    _listDate: 'Datum',
  },
  serviceOrders: {
    _noOrders: 'Inga planerade beställningar.',
    _excelExportButton: 'Excel',
    _pdfExportButton: 'PDF',
    _deleteConfirm: 'Är du säker på att du vill radera denna order: ',
  },
  serviceOrdersGrid: {
    _updated: 'Tid',
    _address: 'Adress',
    _executed: 'Utfört',
    _unit: 'Enhet',
    _orderItemType: 'Typ',
    _deviation: 'Avvik',
    _externalOrderId: 'GLN',
    _sequence: 'Sekvens',
    _agreementCompanyName: 'Kund',
    _estimatedArrivalTime: 'Planerad',
  },
  serviceOrderDetails: {
    _serviceOrders: 'Arbetsordrar',
  },
  serviceOrderDetailsGrid: {
    _agreementCompanyName: 'Kund',
    _address: 'Adress',
    _externalOrderId: 'GLN',
    _sequence: 'Sekvens',
    _estimatedArrivalTime: 'Planerad',
    _description: 'Beskrivning',
    _listDescription: 'Listbeskrivning',
    _executed: 'Utfört',
    _vehicleId: 'Fordon',
    _updated: 'Utförd tidpunkt',
    _unitName: 'Enhet',
    _orderItemType: 'Typ',
    _time: 'Tid',
  },
  containerOrders: {
    _noOrders: 'Inga beställningar planerade.',
    _cancel: 'Avbryt',
    _save: 'Tilldela fordon',
    _addVehicle: 'Lägg till fordon',
    _addVehicleSuccess: 'Fordon tillagt',
    _addVehicleFailure: 'Fordon har inte blivit satt',
    _dateRange: 'Datumintervall',
    _routes: 'Rutter',
    _selectRoutes: 'Välj rutter',
    _vehicles: 'Fordon',
    _selectVehicles: 'Välj fordon',
  },
  containerOrdersGrid: {
    _orderId: 'Order ID',
    _expires: 'Går ut',
    _date: 'Datum',
    _routeName: 'Rutt',
    _address: 'Adress',
    _unitName: 'Enhet',
    _orderTypeName: 'Typ',
    _fraction: 'Fraktion',
    _vehicleId: 'Fordon',
    _route: 'Beställningsrutt',
  },
  customerSearchGrid: {
    _id: 'Id',
    _system: 'System',
    _municipality: 'Kommun',
    _name: 'Namn',
    _address: 'Adress',
    _zipCode: 'Postnummer',
    _type: 'Profiltyp',
    _unitDesrciption: 'Enhetstyp',
    _sendMessage: 'Skicka direktmeddelande',
  },
  containerOrderDetails: {
    _unitName: 'Enhet',
    DetailData_Name: 'Namn',
    DetailData_Address1: 'Adress 1',
    DetailData_Address2: 'Adress 2',
    DetailData_PostalCode: 'Postnummer',
    DetailData_Other: 'Annat',
    DetailData_Message: 'Kommentar',
    DetailData_OrderId: 'Beställnings-ID',
    DetailData_Expires: 'Utgår',
    DetailData_LastDate: 'Sista datum',
    DetailData_LastVolume: 'Volym',
    DetailData_MessageSeptic: 'Anmärkning',
    DetailData_Material: 'Materialtyp',
    DetailData_NumberOfChambers: 'Antal kammare',
    DetailData_TubeLength: 'Slanglängd',
    DetailData_ExtOrderId: 'Beställnings-ID',
    DetailData_ExtOrderNr: 'Extern beställningsnummer',
    DetailData_Route: 'Rutt',
    DetailData_RouteName: 'Rutt namn',
    DetailData_OrderDate: 'Beställningsdatum',
    DetailData_OrderType: 'Beställningstyp',
    DetailData_OrderTypeText: 'Beställningstyp',
    DetailData_PASystem: 'PASystem',
    DetailData_AgreementLines: 'Avtalsrader',
    DetailData_RoutelineId: 'Ruttlinje-ID',
    AgreementLineDetailData_PASystem: 'PASystem',
    AgreementLineDetailData_AgreementId: 'Avtals-ID',
    AgreementLineDetailData_AgreementLineId: 'Avtalsrad-ID',
    AgreementLineDetailData_UnitId: 'Enhet-ID',
    AgreementLineDetailData_Amount: 'Belopp',
    AgreementLineDetailData_PhysicalAmount: 'Fysiskt belopp',
    AgreementLineDetailData_Message: 'Plats',
    AgreementLineDetailData_ExtOrderId: 'Beställnings-ID',
    AgreementLineDetailData_LastChanged: 'Senast ändrad',
    AgreementLineDetailData_DegLat: 'Breddgrad',
    AgreementLineDetailData_DegLon: 'Längdgrad',
    AgreementLineDetailData_ContainerId: 'Behållare-ID',
    AgreementLineDetailData_Fraction: 'Fraktion',
    AgreementDetailData_PASystem: 'PASystem',
    AgreementDetailData_AgreementId: 'Avtals-ID',
    AgreementDetailData_Route: 'Rutt',
    AgreementDetailData_CustomerId: 'Kund-ID',
    AgreementDetailData_Name: 'Namn',
    AgreementDetailData_ContactName: 'Kontaktperson',
    AgreementDetailData_Address1: 'Adress 1',
    AgreementDetailData_Address2: 'Adress 2',
    AgreementDetailData_PostalCode: 'Postnummer',
    AgreementDetailData_City: 'Stad',
    AgreementDetailData_Other: 'Annat',
    AgreementDetailData_Message: 'Kommentar',
    AgreementDetailData_Type: 'Typ',
    AgreementDetailData_LastChanged: 'Senast ändrad',
  },
  impersonationManager: {
    _selectUser: 'Välj kund/användare',
    _noOptionsMessage: 'Inga användare hittades',
    _impersonate: 'Använd',
    _stopImpersonation: 'Sluta använda',
    _selectUserPlaceholder: 'Välj kund/användare...',
  },
  userAdmin: {
    _noUsers: 'Inga användare tillgängliga',
    _userName: 'E-post',
    _system: 'System',
    _contractor: 'Entreprenör',
    _contractorId: 'Transportör',
    _localAdmin: 'Lokal administratör',
    _admin: 'Administratör',
    _modules: 'Moduler',
    _add: 'Lägg till',
    _update: 'Uppdatera',
    _cancel: 'Avbryt',
    _newUser: 'Lägg till ny användare',
    _userEditDialogTitle: 'Redigera användare',
    _userAddDialogTitle: 'Lägg till ny användare',
    _password: 'Lösenord',
    _passwordConfirmation: 'Bekräfta lösenord',
    _confirmPasswordError: 'Lösenorden måste matcha',
    _noCustomerOptionsMessage: 'Inga kunder hittades',
    _noContractorOptionsMessage: 'Inga entreprenörer hittades',
    _contractorsLoadingMessage: 'Entreprenörer laddas in',
    _noModuleOptionsMessage: 'Inga moduler hittades',
    _modulesLoadingMessage: 'Moduler laddas in',
    _customerSelectorPlaceholder: 'Välj kund...',
    _impersonationWarningPart1: 'Du är inloggad som ',
    _impersonationWarningPart2:
      ', och har inte behörighet att använda denna sida. Klicka på knappen nedan för att återgå till administratörsfunktionen.',
  },
  vehicles: {
    _noVehicles: 'Inga fordon tillgängliga',
    _vehicles: 'Fordon',
    _system: 'System',
    _DTAShort: 'DTA',
    _DTALong: 'Digital Truck Assistant',
    _ajourShort: 'Ajour',
    _ajourLong: 'GPS Ajour',
    _vehicleId: 'Fordon',
    _externalId: 'Extern Id',
    _description: 'Beskrivning',
    _phoneNr: 'SIM Nr',
    _driver: 'Förare',
    _password: 'Lösenord',
    _repeatPassword: 'Upprepa lösenord',
    _edit: 'Ändra',
    _cancel: 'Avbryt',
    _update: 'Uppdatera',
    _submit: 'Spara',
    _addNewVehicle: 'Lägg till nytt fordon',
    _editMessageSuccessful: 'Fordon uppdaterat',
    _addMessageSuccessful: 'Fordon tillagt',
    _editMessageFailed: 'Misslyckades med att uppdatera fordon',
    _addMessageFailed: 'Misslyckades med att lägga till fordon',
    _vehicleAlreadyExistingError: 'Ett fordon med samma namn finns redan',
    _validationError: 'Endast siffror och bokstäver är tillåtna',
    _contractorSelectorPlaceholder: 'Välj entreprenör...',
    _noContractorFound: 'Inga entreprenörer tillgängliga',
    _vehicleLoadingMessage: 'Fordon laddas',
  },
  vehicleSettings: {
    _copyFromVehicleButton: 'Kopiera från...',
    _copyFromVehicleDialogTitle: 'Kopiera inställningar från användare',
    _copyButton: 'Kopiera',
    _copyFromVehicleError: 'Fel vid kopiering av användarinställningar',
    _copyFromVehicleSuccess:
      'Inställningarna har kopierats från vald användare',
    _noSettings: 'Inga inställningar tillgängliga',
    _parameter: 'Parameter',
    _value: 'Värde',
    _addToMultiple: 'Lägg till flera fordon?',
    _description: 'Beskrivning',
    _vehicle: 'Fordon',
    _edit: 'Ändra',
    _cancel: 'Avbryt',
    _multiselectError: 'Välj minst ett fordon',
    _update: 'Uppdatera',
    _submit: 'Spara',
    _addNewSetting: 'Lägg till ny inställning',
    _editMessageSuccessful: 'Användarinställning uppdaterad',
    _addMessageSuccessful: 'Användarinställning tillagd',
    _deleteMessageSuccessful: 'Användarinställning raderad',
    _editMessageFailed: 'Fel vid ändring av användarinställning',
    _addMessageFailed: 'Fel vid tillägg av användarinställning',
    _deleteMessageFailed: 'Fel vid borttagning av användarinställning',
    _deleteConfirm:
      'Är du säker på att du vill ta bort denna användarinställning',
    _vehicleSelectorPlaceholder: 'Välj fordon...',
    _selectVehicle: 'Fordon',
    _selectCustomer: 'Kund',
    _selectContractor: 'Entreprenör',
    _settingTemplateSelectorPlaceholder: 'Välj inställningstyp...',
    _noSettingTemplateOptionsMessage: 'Inga inställningar hittades',
    _cannotAddNewSettingMessage:
      'Kan inte lägga till inställningar. Alla inställningar finns redan.',
    _failedToLoadSettingTempletes:
      'Kan inte ändra eller lägga till inställningar. Standardinställningar saknas.',
  },
  map: {
    _trackingLineLayer: 'Spårningslinje',
    _trackInfo: 'GPS-info',
    _trackInfoLine: 'GPS Spårlogg',
    _trackInfoPointsAndLines: 'Spårlogg',
    _streetLayer: 'Bas-Karta',
    _satelliteLayer: 'Flygfoto',
    _propertyLayer: 'Fastighetsvy',
    _wmsLayer: 'WMS',
    _nWMSLayersSelected: '{0} Område(n) valda',
    _lastUpdated: 'Uppdaterad',
    _trackingLineLegend: {
      _belowSix: '< 6 timmar',
      _sixToTwelve: '6-12 timmar',
      _twelveToTwentyFour: '12-24 timmar',
      _overTwentyFour: '> 24 timmar',
      _gray: 'Okänd',
    },
  },
  contractors: {
    _contractors: 'Entreprenörer',
    _name: 'Entreprenörens namn',
    _externalId: 'Extern entreprenörs-id',
    _addContractor: 'Lägg till ny entreprenör',
    _deleteConfirm: 'Är du säker på att du vill ta bort denna entreprenör: ',
    _deleteSuccess: 'Entreprenör borttagen',
    _addSuccess: 'Entreprenör tillagd',
    _editSuccess: 'Entreprenör uppdaterad',
  },
  deviations: {
    _deviations: 'Avvikelser',
    _deviation: 'Avvikelse',
    _deviatonExtId: 'Avvikelse extern Id',
    _category: 'Kategori',
    _deleteConfirm: 'Är du säker på att du vill ta bort denna avvikelse: ',
    _addNew: 'Lägg till avvikelse',
    _addSuccess: 'Avvikelse tillagd',
    _editSuccess: 'Avvikelse uppdaterad',
    _deleteSuccess: 'Avvikelse borttagen',
    _selectCategory: 'Välj avvikelsekategori',
    _noCategory: 'Ingen avvikelsekategori hittades',
    _deleteCategoryConfirm:
      'Är du säker på att du vill ta bort denna avvikelsekategori: ',
    _categoryDeleteSuccess: 'Avvikelsekategori borttagen',
    _deviationCategory: 'Avvikelsekategorier',
    _categoryName: 'Namn på avvikelsekategori',
    _addCategorySuccess: 'Avvikelsekategori tillagd',
    _editCategorySuccess: 'Avvikelsekategori uppdaterad',
    _addNewCategory: 'Lägg till ny avvikelsekategori',
  },
  customerRegister: {
    message: {
      _directMessageToCustomer: 'Direktmeddelande till kund',
      _address: 'Adress',
      _sender: 'Avsändare',
      _expires: 'Utgår',
      _vehicle: 'Fordon',
      _selectVehicle: 'Välj fordon',
      _title: 'Titel',
      _message: 'Meddelande',
      _send: 'Skicka',
      _messageSent: 'Meddelande skickat',
      _noVehicle: 'Inget fordon',
    },
    grid: {
      _infoText:
        'Här kan du söka upp kunden och skicka ett direktmeddelande om kunden till fordonet.',
    },
  },
  newOrder: {
    days: {
      _monday: 'Måndag',
      _tuesday: 'Tisdag',
      _wednesday: 'Onsdag',
      _thursday: 'Torsdag',
      _friday: 'Fredag',
      _saturday: 'Lördag',
      _sunday: 'Söndag',
    },
    frequency: {
      _oneTime: 'En förekomst',
      _weekly: 'Veckovis',
      _weeks: 'Varje # vecka',
    },
  },
  directMessages: {
    _messages: 'Meddelanden',
    _address: 'Adress',
    _sender: 'Avsändare',
    _expires: 'Utgår',
    _vehicle: 'Fordon',
    _title: 'Titel',
    _message: 'Meddelande',
    _date: 'Datum',
    _closeButton: 'Stäng',
    _sent: 'Skickat',
    _received: 'Bearbetad',
    _read: 'Mottagen',
    _status: 'Status',
    _newMessage: 'Nytt meddelande',
    _fromDate: 'Från',
    _toDate: 'Till',
    _vehicles: 'Fordon',
    _chooseVehicles: 'Välj fordon',
    _statuses: 'Statusar',
    _chooseStatuses: 'Välj status(ar)',
  },
  depots: {
    _deleteConfirm: 'Är du säker på att du vill ta bort detta depå?',
    _deleteSuccess: 'Depå borttagen',
    _depots: 'Depåer',
    _newDepot: 'Ny depå',
    _id: 'Id',
    _customerId: 'Kund-id',
    _description: 'Beskrivning',
    _latitude: 'Latitud',
    _longitude: 'Longitud',
    _depotCreated: 'Depå skapad',
    _depotUpdated: 'Depå uppdaterad',
    _createNew: 'Skapa ny depå',
    _update: 'Uppdatera depå',
    _submit: 'Spara',
    _cancelButton: 'Avbryt',
  },
  orderOverview: {
    _hideFilter: 'Dölj filter',
    _showFilter: 'Visa filter',
    _filter: 'Filter',
    _order: 'Beställning',
    _date: 'Datum',
    _ordinaryDate: 'Vanligt datum',
    _expires: 'Utgår',
    _route: 'Rutt',
    _fractions: 'Fraktioner',
    _contractor: 'Entreprenör',
    _status: 'Status',
    _dateFilterInvalid: 'Datumfiltret är ogiltigt',
    _from: 'Från',
    _to: 'Till',
    _contractors: 'Entreprenörer',
    _orderNumber: 'Ordernummer',
    _routes: 'Rutter',
    _chooseRoutes: 'Välj rutt(er)',
  },
  fractions: {
    _deleteConfirm: 'Är du säker på att du vill ta bort denna fraktion?',
    _deleteSuccess: 'Fraktion borttagen',
    _newFraction: 'Ny fraktion',
    _fraction: 'Fraktion',
    _fractions: 'Fraktioner',
    _picture: 'Bild',
    _fractionCreated: 'Fraktion skapad',
    _fractionUpdated: 'Fraktion uppdaterad',
    _fractionName: 'Fraktionsnamn',
    _updateFraction: 'Uppdatera fraktion',
    _submit: 'Spara',
    _cancelButton: 'Avbryt',
    _selectImage: 'Välj en bild',
  },
  units: {
    _units: 'Enheter',
    _unitUpadated: 'Enhet uppdaterad',
    _unitName: 'Enhetsnamn',
    _commune: 'Kommun',
    _fractions: 'Fraktioner',
    _selectFractions: 'Välj fraktioner',
    _cancel: 'Avbryt',
    _save: 'Spara',
    _manageFractions: 'Hantera fraktioner',
    _shortName: 'Liter',
    _freeBasedProduct: 'Avgiftsbaserad produkt',
    _distance: 'Avstånd',
    _container: 'Behållare',
  },
};
