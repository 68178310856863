import React from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import ErrorMessage from './ErrorMessage';
import { DatePicker as KendoDatePicker } from '@progress/kendo-react-dateinputs';
import { property } from 'lodash';

export default function DatePicker({
  name,
  label,
  labelCol,
  format,
  popupClass,
  disabled = false,
  className = '',
  isLabelTop,
  col
}) {
  const { getFieldProps, touched, errors } = useFormikContext();

  return (
    <Form.Group as= { col ? Col : Row}>
      {isLabelTop ? (
        <Col sm={12}>
          <Form.Label>{label}</Form.Label>
        </Col>
      ) : (
        <Form.Label column sm={labelCol}>
          {label}
        </Form.Label>
      )}
      <Col sm={12 - labelCol}>
        <KendoDatePicker
          {...getFieldProps(name)}
          format={format}
          className={className + ' date-picker'}
          popupSettings={{ popupClass }}
          valid={!touched[name] || !errors[name]}
          disabled={disabled}
        />
        <ErrorMessage name={name} />
      </Col>
    </Form.Group>
  );
}

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  labelCol: PropTypes.number,
  format: PropTypes.string,
  popupClass: PropTypes.string,
  isLabelTop: PropTypes.bool,
  col: PropTypes.bool
};

DatePicker.defaultProps = {
  label: '',
  name: '',
  labelCol: 5,
  format: 'dd.MM.yyyy',
  popupClass: 'date-picker-popup',
  isLabelTop: false,
  col: false
};
