const containerOrderConstants = {
  GET_CONTAINERORDER: 'GET_CONTAINERORDER',
  GET_CONTAINERORDER_SUCCESS: 'GET_CONTAINERORDER_SUCCESS',
  GET_CONTAINERORDER_FAILURE: 'GET_CONTAINERORDER_FAILURE',

  SET_CONTAINERORDER_DATE_RANGE_FILTER: 'SET_CONTAINERORDER_DATE_RANGE_FILTER',

  SET_SELECTED_CONTAINERORDER: 'SET_SELECTED_CONTAINERORDER',

  SET_CONTAINER_ORDER_FILTER: 'SET_CONTAINER_ORDER_FILTER',
};

export default containerOrderConstants;
