export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function groupByReduce(array, keyGetter) {
  return array.reduce((acc, item) => {
    const key = keyGetter(item);
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
}

/**
 * Selects the minimum or maximum of a list of given dates.
 * @param {array} list List of dates or date-like values.
 * @param {function} minMax An aggregate function applied to the provided dates.
 * @returns {Date} The value of the aggregate function applied to the date list.
 */
export function selectDate(list, minMax) {
  const dates = list.filter((date) => !!date).map((date) => new Date(date));

  if (dates.length > 0) {
    return new Date(minMax.apply(null, dates));
  }

  return null;
}
