import containerOrderConstants from './containerOrderConstants';

const initialState = {
  containerOrders: [],
  selectedOrder: null,
  containerOrderFilter: {
    interval: 'thisWeek',
    routeIds: [],
    vehicles: [],
  },
};

export default function containerOrder(state = initialState, action) {
  switch (action.type) {
    case containerOrderConstants.GET_CONTAINERORDER_SUCCESS:
      return {
        ...state,
        containerOrders: action.containerOrders,
        error: null,
      };
    case containerOrderConstants.GET_CONTAINERORDER_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case containerOrderConstants.SET_SELECTED_CONTAINERORDER:
      return {
        ...state,
        selectedOrderId: action.containerOrderId,
      };
    case containerOrderConstants.SET_CONTAINER_ORDER_FILTER: {
      return {
        ...state,
        containerOrderFilter: action.filter,
      };
    }
    default:
      return state;
  }
}
