import { IAuthState } from './authState';
import { Actions } from './authActions';
import { AuthConstants } from './authConstants';

const initialState: IAuthState = {
  isLoading: false,
  isAdmin: false,
  isLocalAdmin: false,
  token: null,
  name: null,
  customerId: null,
  contractorId: null,
  errorMessage: '',
};

export function authentication(
  state: IAuthState = initialState,
  action: Actions
): IAuthState {
  switch (action.type) {
    case AuthConstants.USERS_LOGIN_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case AuthConstants.USERS_LOGIN_SUCCESS: {
      return {
        ...state,
        name: action.payload.name,
        customerId: action.payload.customerId,
        contractorId: action.payload.contractorId,
        token: action.payload.token,
        isAdmin: isAuth0Admin(action.payload.auth0Roles),
        isLocalAdmin: action.payload.isLocalAdmin,
        isLoading: false,
      };
    }
    case AuthConstants.USERS_LOGIN_FAILURE: {
      return {
        ...state,
        errorMessage: action.error.message,
        isLoading: false,
      };
    }
    case AuthConstants.USERS_LOGOUT_SUCCESS: {
      return initialState;
    }
    case AuthConstants.USERS_LOGOUT_FAILURE: {
      return initialState;
    }
    case AuthConstants.SET_IMPERSONATED_USER: {
      return {
        ...state,
        impersonatedUser: action.payload?.selectedUser,
        impersonatedCustomerId: action.payload?.selectedCustomerId,
      };
    }
    case AuthConstants.SET_ACCESS_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

function isAuth0Admin(auth0Admin: string): boolean {
  return auth0Admin.includes('Administrator');
}
