/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { localeEn } from './localization_en';
import { localeNo } from './localization_no';
import { localeRo } from './localization_ro';
import { localeSe } from './localization_se';
import { localeHr } from './localization_hr';
import { localeNl } from './localization_nl';
import { localeDe } from './localization_de';

import nbMessages from '../../locales/no.json';
import enMessages from '../../locales/en.json';
import deMessages from '../../locales/de.json';
import hrMessages from '../../locales/hr.json';
import nlMessages from '../../locales/nl.json';
import roMessages from '../../locales/ro.json';
import seMessages from '../../locales/se.json';
import { loadMessages } from '@progress/kendo-react-intl';

/* eslint-disable @typescript-eslint/explicit-member-accessibility */
const defaultLocale = 'nb';

export interface LocaleObject {
  userName: string;
  locale: string;
}
export default class LocalizationService {
  static setLocale(locale: string, userName: string): void {
    const locales = this.getLocaleArray();
    if (locales.length) {
      const userIndex = locales.findIndex((e) => e.userName === userName);
      if (locales[userIndex]) {
        locales[userIndex].locale = locale;
      } else {
        locales.push({ locale, userName });
      }
      const lastLangIndex = locales.findIndex((e) => e.userName === 'lastLang');
      locales[lastLangIndex].locale = locale;
      localStorage.setItem('locale', JSON.stringify(locales));
    } else {
      locales.push({ userName: 'lastLang', locale });
      locales.push({ userName, locale });
      localStorage.setItem('locale', JSON.stringify(locales));
    }
  }

  static getLocale() {
    const userName = localStorage.getItem('userName') || 'lastLang';
    const locale = this.getCountryCode(userName);
    switch (locale) {
      case 'nb':
        loadMessages(nbMessages, 'nb');
        return localeNo;
      case 'ro':
        loadMessages(roMessages, 'ro');
        return localeRo;
      case 'en':
        loadMessages(enMessages, 'en');
        return localeEn;
      case 'se':
        loadMessages(seMessages, 'se');
        return localeSe;
      case 'hr':
        loadMessages(hrMessages, 'hr');
        return localeHr;
      case 'nl':
        loadMessages(nlMessages, 'nl');
        return localeNl;
      case 'de':
        loadMessages(deMessages, 'de');
        return localeDe;
      default:
        console.warn(
          `Locale setting is not found in configuration. Defaulting to Norwegian Bokmal.`
        );
        loadMessages(nbMessages, 'nb');
        return localeNo;
    }
  }

  static getCountryCode(userName: string): string {
    const locales = this.getLocaleArray();
    if (locales.length) {
      const userIndex = locales.findIndex((e) => e.userName === userName);
      if (locales[userIndex]) {
        return locales[userIndex].locale;
      }
    } else {
      return defaultLocale;
    }
  }

  static isLanguageChanged(userName: string): boolean {
    const locales = this.getLocaleArray();
    if (locales.length) {
      const userIndex = locales.findIndex((e) => e.userName === userName);
      const lastLangIndex = locales.findIndex((e) => e.userName === 'lastLang');
      if (
        userIndex > 0 &&
        locales[userIndex].locale !== locales[lastLangIndex].locale
      ) {
        return true;
      }
    }
    return false;
  }

  private static getLocaleArray(): Array<LocaleObject> {
    const locales = localStorage.getItem('locale');
    if (locales) {
      const localesArray: Array<LocaleObject> = JSON.parse(locales);
      return localesArray;
    } else {
      return [];
    }
  }
}

export const locale = LocalizationService.getLocale();
