import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './refreshButton.scss';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';

function RefreshButton({ onClick, TagName = 'button', className, children }) {
  const [active, setActive] = useState(false);
  const buttonClass = classNames(
    'btn',
    'btn-outline-dark',
    'refresh-button',
    className,
    { 'refresh-spin-active': active }
  );

  return (
    <TagName
      className={buttonClass}
      onClick={() => {
        setActive(true);
        onClick();
      }}
      onAnimationEnd={() => setActive(false)}
    >
      <FontAwesomeIcon icon={faSyncAlt} />
      {children}
    </TagName>
  );
}

RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  TagName: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.object,
};

export default RefreshButton;
