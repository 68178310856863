import React, { Component, ReactNode } from 'react';
import { IFraction } from '../../models/Fraction.model';
import CommandCell from '../userAdmin/CommandCell';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { process } from '@progress/kendo-data-query';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import { locale } from '../../common/localization/localizationService';
import { pictureStorageUrl } from '../../appConfig';

export interface IFractionGridProps {
  data: Array<IFraction>;
  className?: string;
  editFraction: (dataItem: IFraction) => void;
}

export interface IFractionGridState {
  dataState: Record<string, any>;
}

export class FractionGrid extends Component<
  IFractionGridProps,
  IFractionGridState
> {
  public readonly state: IFractionGridState = {
    dataState: {
      sort: [
        {
          dir: 'asc',
          field: 'fractionId',
        },
      ],
      take: 26,
      skip: 0,
    },
  };

  public dataStateChange(event: Record<string, any>): void {
    this.setState({ ...this.state, dataState: event.data });
  }

  private showFractionPicture(props: any) {
    return (
      <td className="picture-cell">
        <img
          className="fraction-picture"
          src={`${pictureStorageUrl}${props.dataItem.url}`}
        />
      </td>
    );
  }

  private EditCell(props: any) {
    return (
      <CommandCell {...props} edit={this.props.editFraction} icon={faEdit} />
    );
  }

  public render(): ReactNode {
    const { className, data } = this.props;
    const result = process(data, this.state.dataState);
    return (
      <>
        <Grid
          {...this.state.dataState}
          className={className + ' fractions-grid'}
          data={result}
          selectedField="selected"
          pageable
          resizable
          pageSize={26}
          onDataStateChange={this.dataStateChange.bind(this)}
        >
          <GridNoRecords>{locale.general._noRecords}</GridNoRecords>
          <GridColumn field="name" title={locale.fractions._fraction} />
          <GridColumn
            field="url"
            title={locale.fractions._picture}
            width={50}
            cell={this.showFractionPicture.bind(this)}
          />
          <GridColumn
            cell={this.EditCell.bind(this)}
            width={26}
            sortable={false}
          />
        </Grid>
      </>
    );
  }
}
